import React, { useCallback, useEffect, useState } from 'react';

import ReactFacebookPixel from 'react-facebook-pixel';
import {
  IoArrowBack,
  IoArrowDownCircle,
  IoArrowUpCircle,
} from 'react-icons/io5';
import { useHistory } from 'react-router-dom';

import {
  ImageAppleStore,
  ImageGooglePlayStore,
  ImageStenioGarcia,
} from '@assets/images';
import {
  ButtonDefault,
  ButtonMain,
  ButtonOutline,
  CardAppPlatforms,
  HeaderNavigationPublic,
  LoadingButton,
  MenuSideBarPublic,
  Paragraph,
  SubtitleMain,
  SubtitleSecondary,
  TitleSecondary,
} from '@components/index';
import {
  ConfigBase,
  ConfigIntegrations,
  ConfigRoutes,
  ConfigStorage,
  ConfigStyles,
  ConfigValues,
} from '@config/index';
import Product from '@models/Product';
import { apiRebox, newContractStorageService } from '@services/index';

import {
  AreaClientLink,
  Buttons,
  Container,
  Content,
  Options,
  OptionsAppPlatform,
  OptionsGroup,
  Sections,
  SectionsImage,
} from './styles';

const Thanks: React.FC = () => {
  /**
   * PIXEL DO FACEBOOK
   */
  ReactFacebookPixel.init(
    ConfigIntegrations.facebook.pixels.salesMonitoring.pixelId,
    ConfigIntegrations.facebook.pixels.salesMonitoring.advancedMatching,
    ConfigIntegrations.facebook.pixels.salesMonitoring.options,
  );
  ReactFacebookPixel.pageView();
  ReactFacebookPixel.track(
    ConfigIntegrations.facebook.pixels.salesMonitoring.track.event,
    ConfigIntegrations.facebook.pixels.salesMonitoring.track.value,
  );

  /**
   * PIXEL DO GOOGLE ANALYTICS
   */
  /* ReactGoogleAnalyticsPixel.initialize(
    ConfigIntegrations.googleAnalytics.pixels.salesMonitoring.gaTrackingID,
    ConfigIntegrations.googleAnalytics.pixels.salesMonitoring.options,
  ); */

  const { push } = useHistory();

  const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(false);
  const [menuPlatformIsOpen, setMenuPlatformIsOpen] = useState<boolean>(false);
  const [paramsAuth, setParamsAuth] = useState({
    sessionId: '',
    sessionToken: '',
  });
  const [loadingFirstLogin, setLoadingFirstLogin] = useState<boolean>(false);
  const [isTrackerPlan, setIsTrackerPlan] = useState<boolean>(false);

  const [isSaleLandingPage, setIsSaleLandingPage] = useState<boolean>(false);

  const handleOpenSidebar = useCallback(() => {
    setSidebarIsOpen(!sidebarIsOpen);
  }, [sidebarIsOpen]);

  const handleNewPurchase = useCallback(() => {
    if (isSaleLandingPage) {
      push(ConfigRoutes.rebox.defaults.source);
    } else {
      window.location.replace('https://planos.rebox.com.br/rastreador');
    }
    newContractStorageService.cleanMany('all');
  }, []);

  const handleLoginFirst = async () => {
    const customer = newContractStorageService.getCustomer();
    try {
      setLoadingFirstLogin(prevState => !prevState);
      const { data: response } = await apiRebox.post('/sessions', {
        email: customer.query,
        password: '123456',
      });
      setParamsAuth({
        sessionId: response.data.id,
        sessionToken: response.data.token,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingFirstLogin(prevState => !prevState);
    }
  };

  const setMenuPlatformIsClose = (menuPlatformIsClose: boolean) => {
    setMenuPlatformIsOpen(menuPlatformIsClose);
  };

  useEffect(() => {
    const products: Product[] = newContractStorageService.getCart(true);
    const coupon = newContractStorageService.getDiscountCoupons();
    const env = sessionStorage.getItem('@Rebox:User:environment');
    const savedIsTrackerPlan = localStorage.getItem('is_tracker_plan');

    setIsTrackerPlan(savedIsTrackerPlan === 'true');

    setIsSaleLandingPage(env === 'LANDING_PAGE');

    window?.dataLayer.push({ ecommerce: null });
    window?.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        value: products.reduce((sum, prod) => sum + prod.promotional_price, 0),
        currency: 'BRL',
        coupon: coupon.code,
        items: [
          ...products.map(prod => ({
            item_name: prod.name,
            item_id: prod.id,
            price: prod.current_price,
            item_brand: 'REBOX',
            item_category: prod.category,
            item_variant: prod.type,
            quantity: 1,
          })),
        ],
      },
    });

    sessionStorage.removeItem(ConfigStorage.REBOX_SALE_NEW_CART_COPY);
    handleLoginFirst();
  }, []);

  return (
    <Container sidebarIsOpen={sidebarIsOpen}>
      <MenuSideBarPublic
        handleOpenSidebar={handleOpenSidebar}
        sidebarIsOpen={sidebarIsOpen}
      />

      <HeaderNavigationPublic handleOpenSidebar={handleOpenSidebar} />

      <Content>
        <TitleSecondary textAlign="start">Checkout</TitleSecondary>
        <Options>
          <OptionsGroup>
            <ButtonDefault
              iconLeft={IoArrowBack}
              // eslint-disable-next-line no-return-assign
              onClick={handleNewPurchase}
              tabIndex={1}
            >
              Ver planos
            </ButtonDefault>
          </OptionsGroup>
        </Options>

        <Sections>
          <SectionsImage src={ImageStenioGarcia} />
          <SubtitleMain>Deu tudo certo!</SubtitleMain>
          <SubtitleSecondary nameColor="black" style={{ margin: '0.5vh 0' }}>
            Sua compra foi realizada com sucesso.
          </SubtitleSecondary>
          <Paragraph
            nameColor="black"
            fontSize={ConfigStyles.rebox.fonts.size.paragraph.big}
            fontWeight={500}
          >
            Enviamos todas as instruções para o seu email.
          </Paragraph>
          <Paragraph
            nameColor="black"
            fontSize={ConfigStyles.rebox.fonts.size.paragraph.big}
            fontWeight={500}
          >
            Instale nosso aplicativo para <strong>monitorar seu veículo</strong>{' '}
            e <strong>pedir assistência 24h.</strong>
          </Paragraph>

          {/* <Paragraph
            nameColor="black"
            fontSize={ConfigStyles.rebox.fonts.size.paragraph.big}
            fontWeight={500}
          >
            Ah, lembrando que seu contrato será ativado assim que recebermos a
            confirmação do pagamento da 1ª cobrança. Só após o prazo de carência
            é iniciado.
          </Paragraph> */}
          <Buttons>
            <ButtonMain
              className="buttonMenuPlatforms"
              onClick={() => setMenuPlatformIsOpen(prevState => !prevState)}
            >
              Baixe nosso aplicativo agora!
            </ButtonMain>
            <ButtonOutline
              // onClick={handleNewPurchase}
              // eslint-disable-next-line no-return-assign
              onClick={() =>
                (window.location.href =
                  'https://planos.rebox.com.br/rastreador')
              }
            >
              Nova compra
            </ButtonOutline>
            {/* <ButtonOutline
          onClick={handleSellToTheSameCustomer}
          style={{ maxWidth: '300px' }}
        >
          Vender para mesmo cliente
        </ButtonOutline> */}

            <AreaClientLink
              to={{
                pathname: `${ConfigBase.rebox.externalLinks.customer}/painel${
                  paramsAuth.sessionId === null
                    ? ''
                    : `?s=${paramsAuth.sessionId}&t=${paramsAuth.sessionToken}`
                }`,
              }}
              target="_blank"
              loadingFirstAuth={loadingFirstLogin}
            >
              <ButtonOutline loading={loadingFirstLogin}>
                Acessar área do cliente
              </ButtonOutline>
            </AreaClientLink>
          </Buttons>
          <OptionsAppPlatform menuPlatformsIsOpen={menuPlatformIsOpen}>
            <CardAppPlatforms.SubTitleMainMobileOrDesktop className="titleDesktopMain" />
            <CardAppPlatforms.CardAppPlatform
              setMenuPlatformIsClose={setMenuPlatformIsClose}
              menuPlatformIsClose={menuPlatformIsOpen}
            >
              <CardAppPlatforms.AppPlatform
                linkApp={
                  isTrackerPlan
                    ? ConfigBase.rebox.appStore.TraccarApp
                    : ConfigBase.rebox.appStore.App
                }
                namePlatform="IOS"
                LogoSvg={ImageAppleStore}
              />
              <CardAppPlatforms.AppPlatform
                linkApp={
                  isTrackerPlan
                    ? ConfigBase.rebox.playStore.TraccarApp
                    : ConfigBase.rebox.playStore.App
                }
                namePlatform="Android"
                LogoSvg={ImageGooglePlayStore}
              />
            </CardAppPlatforms.CardAppPlatform>
          </OptionsAppPlatform>
        </Sections>
      </Content>
    </Container>
  );
};

export default Thanks;
