import React, { useCallback, useEffect, useState, useRef } from 'react';

import { BsCartX } from 'react-icons/bs';
import { FaWhatsapp } from 'react-icons/fa';
import { IoArrowBack } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';

import {
  HeaderNavigationPublic,
  MenuSideBarPublic,
  HeaderWizardNewSale,
  ButtonDefault,
  TitleMain,
  TitleSecondary,
  ButtonMain,
  ButtonOutline,
} from '@components/index';

// import StepConclusion from './StepConclusion';
import { ConfigRoutes } from '@config/index';
import {
  newContractStorageService,
  sessionStorageService,
} from '@services/index';
import { toastify } from '@utils/notifiers';

import StepCustomer from './StepCustomer';
import StepFinished from './StepFinished';
import StepPayment from './StepPayment';
// import StepProduct from './StepProduct';
import StepVehicle from './StepVehicle';

import { Container, Content, Options, OptionsGroup, Sections } from './styles';

const Checkout: React.FC = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { push } = useHistory();

  const [calculatedPriceForFlex, setCalculatedPriceForFlex] = useState<number>(
    0,
  );
  const [carAge, setCarAge] = useState<number>(0);
  const [
    calculatedPriceForTracker,
    setCalculatedPriceForTracker,
  ] = useState<number>(0);

  const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [stepHistory, setStepHistory] = useState<number>(1);

  const [paramsAuth, setParamsAuth] = useState<string>('');
  const [isSaleLandingPage, setIsSaleLandingPage] = useState<boolean>(false);

  const changeStep = (desiredStep: number) => {
    setStep(desiredStep);
  };
  const handleOpenSidebar = useCallback(() => {
    setSidebarIsOpen(!sidebarIsOpen);
  }, [sidebarIsOpen]);

  const handleAuthLogin = useCallback(() => {
    const sessionId = sessionStorageService.getId();
    const sessionToken = sessionStorageService.getToken();
    setParamsAuth(
      sessionId && sessionToken ? `?s=${sessionId}&t=${sessionToken}` : '',
    );
  }, []);

  const handleChangeSteps = useCallback(
    (desiredStep: number, willChangeHistory?: boolean) => {
      setStep(desiredStep);
      if (stepHistory < desiredStep || willChangeHistory)
        setStepHistory(desiredStep);
    },
    [stepHistory],
  );

  const handleNewPurchase = useCallback(() => {
    if (isSaleLandingPage) {
      push(ConfigRoutes.rebox.defaults.source);
    } else {
      window.location.replace('https://planos.rebox.com.br/rastreador');
    }
    newContractStorageService.cleanMany('all');
  }, []);

  const handleToAbandon = useCallback(() => {
    newContractStorageService.cleanMany('all');
    toastify('A compra foi encerrada. Seu carrinho está vazio.', 'success');
    window.location.href = 'https://planos.rebox.com.br/rastreador';
    // push(ConfigRoutes.rebox.publics.landingPage.path);
  }, []);

  useEffect(() => {
    const env = sessionStorage.getItem('@Rebox:User:environment');
    setIsSaleLandingPage(env === 'LANDING_PAGE');
  }, []);

  useEffect(() => {
    contentRef.current?.scrollIntoView();
  }, [step]);

  const handleCalculatePrice = (e: any) => {
    if (e.target.value.length === 4) {
      const currentYear = new Date().getFullYear();
      const year = parseInt(e.target.value, 10);
      const yearDiff = currentYear - year;
      setCarAge(yearDiff);

      if (yearDiff <= 15) {
        setCalculatedPriceForFlex(54.9);
        setCalculatedPriceForTracker(64.9);
      } else {
        setCalculatedPriceForFlex(64.9);
        setCalculatedPriceForTracker(74.9);
      }
    }
  };

  return (
    <Container sidebarIsOpen={sidebarIsOpen}>
      <MenuSideBarPublic
        handleOpenSidebar={handleOpenSidebar}
        sidebarIsOpen={sidebarIsOpen}
        paramsAuth={paramsAuth}
      />

      <HeaderNavigationPublic
        handleOpenSidebar={handleOpenSidebar}
        paramsAuth={paramsAuth}
      />

      <Content ref={contentRef}>
        <TitleSecondary textAlign="start">Checkout</TitleSecondary>
        <Options>
          <OptionsGroup>
            <ButtonDefault
              iconLeft={IoArrowBack}
              onClick={() => {
                if (
                  sessionStorage.getItem('@Rebox:User:environment') ===
                  'MARKETING'
                ) {
                  window.location.href =
                    'https://planos.rebox.com.br/rastreador';
                } else {
                  push(ConfigRoutes.rebox.publics.landingPage.path);
                }
              }}
              tabIndex={1}
            >
              {step === 1 ? 'Ver planos' : 'Voltar'}
            </ButtonDefault>
          </OptionsGroup>
          {step !== 4 && (
            <OptionsGroup>
              <ButtonDefault
                iconLeft={FaWhatsapp}
                onClick={() => {
                  window.location.href =
                    'https://api.whatsapp.com/send?phone=551140033132&text=Olá!%20Pode%20me%20ajudar?';
                  // if (
                  //   sessionStorage.getItem('@Rebox:User:environment') ===
                  //   'MARKETING'
                  // ) {
                  //   window.location.href =
                  //     'https://planos.rebox.com.br/rastreador';
                  // } else {
                  //   push(ConfigRoutes.rebox.publics.landingPage.path);
                  // }
                }}
              >
                Atendimento
              </ButtonDefault>
            </OptionsGroup>
          )}
        </Options>
        <HeaderWizardNewSale
          stepActive={step}
          stepHistory={stepHistory}
          changeStep={changeStep}
        />

        <Sections>
          {step === 1 && (
            <StepCustomer
              myStep={1}
              currentStep={step}
              changeStep={handleChangeSteps}
              handleAuthLogin={handleAuthLogin}
            />
          )}

          {step === 2 && (
            <StepVehicle
              myStep={2}
              currentStep={step}
              changeStep={handleChangeSteps}
              calculatedPriceForFlex={calculatedPriceForFlex}
              calculatedPriceForTracker={calculatedPriceForTracker}
              handleCalculatePrice={handleCalculatePrice}
            />
          )}

          {step === 3 && (
            <StepPayment
              myStep={3}
              currentStep={step}
              changeStep={handleChangeSteps}
              calculatedPriceForFlex={calculatedPriceForFlex}
              calculatedPriceForTracker={calculatedPriceForTracker}
              carAge={carAge}
            />
          )}

          {/* {step === 4 && (
            <StepFinished
              myStep={4}
              currentStep={step}
              changeStep={handleChangeSteps}
            />
          )} */}
        </Sections>
      </Content>
    </Container>
  );
};

export default Checkout;
