import { useState } from 'react';

import axios from 'axios';
import { FaLock, FaLockOpen } from 'react-icons/fa';

import { ConfigStyles } from '@config/index';
import Vehicle from '@models/Vehicle';
import { toastify } from '@utils/notifiers';

import { Modal } from '..';

import {
  Button,
  Container,
  Divisor,
  Header,
  List,
  Title,
  Wrapper,
} from './Styles';

interface Props {
  vehicles: Vehicle[];
  show: boolean;
  onClose: () => void;
}

const TrackerModal: React.FC<Props> = ({ show, vehicles, onClose }) => {
  const handleBlockCar = async (deviceId: string | undefined) => {
    try {
      await axios({
        method: 'POST',
        url: 'http://rebox-tracker.com.br:8082/api/commands/send',
        data: {
          deviceId,
          description: 'Bloqueio de veiculo em caso de furto',
          type: 'engineStop',
          attributes: {},
        },
      });
      toastify('Veículo bloqueado com sucesso.', 'success');
    } catch (error: any) {
      toastify('Houve um erro ao bloquear o veículo', 'error');
    }
  };

  return (
    <Modal show={show} onClose={onClose} width="550px" height="75vh">
      <Container>
        <Wrapper>
          <Title>Bloqueio Veicular</Title>
          <Header>Veículos</Header>

          {vehicles.map(vehicle => (
            <>
              <Container>
                <ul
                  style={{
                    width: '100%',
                    color: 'black',
                  }}
                >
                  <List
                    style={{
                      listStyle: 'none',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                    }}
                  >
                    {vehicle.model.toUpperCase()}
                    {' | '}
                    {vehicle.license_plate.toUpperCase()}

                    <Button
                      style={{
                        backgroundColor:
                          vehicle?.gateway_tracker_device_id !== undefined
                            ? ConfigStyles.rebox.colors.red.main
                            : ConfigStyles.rebox.colors.gray.opacity.average,
                        pointerEvents:
                          vehicle?.gateway_tracker_device_id !== undefined
                            ? 'auto'
                            : 'none',
                        opacity:
                          vehicle?.gateway_tracker_device_id !== undefined
                            ? 1
                            : 0.5,
                      }}
                      onClick={() =>
                        handleBlockCar(vehicle?.gateway_tracker_device_id)
                      }
                    >
                      Bloquear
                      <FaLock
                        size={18}
                        style={{
                          cursor: 'pointer',
                          marginLeft: '10px',
                          color: 'white',
                        }}
                      />
                    </Button>
                  </List>
                  <Divisor />
                </ul>
              </Container>
            </>
          ))}
        </Wrapper>
      </Container>
    </Modal>
  );
};

export default TrackerModal;
