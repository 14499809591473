// ./src/components/forms/FormVehicleNew/styles.ts
import { Form } from '@unform/web';
import Modal from 'react-modal';
import styled, { css } from 'styled-components';

interface IButtonsFilterPlans {
  isButtonActive: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white.main};
  text-align: center;
  margin: 3vh 0;
  width: 100%;
  padding: 2vh 4vw 6vh;
  border-radius: 10px;
`;

export const FormVehicle = styled(Form)`
  width: 100%;
`;

export const Sections = styled.div`
  width: 100%;
`;

export const SectionsGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10vw;
  padding: 3vh 0;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;

export const SectionsItem = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    padding: 2vh 0;
  }
`;

export const ProductModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white.main};
  border-radius: 10px;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.1);
  border: 0.5px solid ${({ theme }) => theme.colors.black.opacity.low};
  height: 420px;
  max-width: 60vw;
  margin: 10vh auto 0;
  position: relative;

  @media (max-width: 539.9px) {
    width: 90%;
    padding: 6vh 6vw;
  }
`;

export const SectionsPlansOptions = styled.div`
  padding: 4vh 8vw;
  @media (min-width: 768px) {
    padding: 4vh 16vw;
  }
`;

export const SectionsPlansOptionsGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 90%;
  gap: 10px;
  @media (max-width: 539.9px) {
    height: 100%;
    grid-template-columns: 1fr;
    overflow-y: scroll;
  }
`;

export const SectionsPlansOptionsItem = styled.button<IButtonsFilterPlans>`
  padding: 1.5vh 2vw;
  border-bottom: 3px solid ${({ theme }) => theme.colors.blue.opacity.veryLow};
  font-weight: 500;
  ${props =>
    props.isButtonActive &&
    css`
      border-bottom: 3px solid ${({ theme }) => theme.colors.blue.main};
    `}
  :hover {
    background-color: ${({ theme }) => theme.colors.blue.opacity.veryLow};
  }
  @media (max-width: 539.9px) {
    padding-top: 4vh;
    :hover {
      background-color: none;
    }
    ${props =>
      props.isButtonActive &&
      css`
        p {
          color: ${({ theme }) => theme.colors.blue.main};
        }
      `}
  }
`;

export const SectionsPlansOptionsItemGroup = styled.div`
  > svg {
    width: 40px;
    max-height: 40px;
  }
`;

export const SliderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 32px auto;
  position: relative;
  .swiper-container {
    position: unset;
    width: 1400px;
    height: fit-content;
    @media (min-width: 1000px) {
      margin: 0 100px;
    }
  }
  .swiper-slide {
    width: 300px;
    height: 100%;
  }
`;
