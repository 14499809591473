// ./src/components/inputs/InputCheckBox/index.tsx
import React, { InputHTMLAttributes } from 'react';

import { FormControlLabel } from '@material-ui/core';

import { Container, Label, RadioInput } from './styles';

interface OptionsInterface {
  label: string;
  value: string;
}

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  onChange(e?: any): void;
  options: OptionsInterface[];
  row: boolean;
}

const InputRadio: React.FC<IProps> = ({ onChange, row, options, ...rest }) => {
  return (
    <Container {...rest}>
      <Label row={row} onChange={onChange} defaultValue={rest.defaultValue}>
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            name={option.value}
            control={<RadioInput color="primary" size="small" />}
            label={option.label}
          />
        ))}
      </Label>
    </Container>
  );
};

export default InputRadio;
