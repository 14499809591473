import * as Yup from 'yup';

export const schemaServices = Yup.object()
  .shape({
    reboque_car: Yup.object().shape({
      text: Yup.string(),
      value: Yup.boolean(),
    }),
    reboque_moto: Yup.object().shape({
      text: Yup.string(),
      value: Yup.boolean(),
    }),
    reboque_suv: Yup.object().shape({
      text: Yup.string(),
      value: Yup.boolean(),
    }),
    drypan: Yup.object().shape({
      text: Yup.string(),
      value: Yup.boolean(),
    }),
    keychain: Yup.object().shape({
      text: Yup.string(),
      value: Yup.boolean(),
    }),
    battery_recharge: Yup.object().shape({
      text: Yup.string(),
      value: Yup.boolean(),
    }),
    tire_change: Yup.object().shape({
      text: Yup.string(),
      value: Yup.boolean(),
    }),
  })
  .test(
    'Service Test',
    'Verificando se algum serviço está marcado...',
    services => {
      if (
        services?.battery_recharge?.value ||
        services?.drypan?.value ||
        services?.keychain?.value ||
        services?.tire_change?.value ||
        services?.reboque_car?.value ||
        services?.reboque_moto?.value ||
        services?.reboque_suv?.value
      ) {
        return true;
      }

      return new Yup.ValidationError(
        'Por favor, selecione ao menos um serviço.',
        null,
        'Serviço não selecionado.',
      );
    },
  );

export const schemaHours = Yup.object()
  .shape({
    util_day: Yup.string(),
    saturday: Yup.string(),
    sunday: Yup.string(),
  })
  .test(
    'HourType Test',
    'Verificando se algum tipo de horário foi selecionado...',
    hours => {
      if (
        hours?.saturday !== '' ||
        hours.sunday !== '' ||
        hours.util_day !== ''
      ) {
        return true;
      }

      return new Yup.ValidationError(
        'Por favor, informe os horários de funcionamento.',
        null,
        'Horários não definidos',
      );
    },
  );

export const schemaSelectedHourType = Yup.string().required(
  'Por favor, informe um tipo de horário',
);
