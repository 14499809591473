// ./src/pages/privates/Dashboard/index.tsx
import React, { useState } from 'react';

import {
  HeaderNavigationPrivate,
  MenuSideBarPrivate,
  Paragraph,
  SubtitleSecondary,
} from '@components/index';

import BlinkingIndicator from './Default/BlinkingIndicator';

import { Container, ContainerGroup, Content, Options } from './styles';

const Dashboard: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [firstLoading, setFirstLoading] = useState<boolean>(true);

  return (
    <Container>
      <HeaderNavigationPrivate />
      <ContainerGroup>
        <MenuSideBarPrivate />
        <Content loading={firstLoading}>
          <BlinkingIndicator show={loading} />
          <SubtitleSecondary textAlign="start">Dashboard</SubtitleSecondary>
          <Options>
            <Paragraph fontSize={16} fontWeight={600} nameColor="black">
              Em breve
            </Paragraph>
          </Options>
        </Content>
      </ContainerGroup>
    </Container>
  );
};

export default Dashboard;
