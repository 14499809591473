import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useField } from '@unform/core';
import { getDay } from 'date-fns/esm';
import ptBR from 'date-fns/locale/pt-BR';
import DatePicker, { ReactDatePicker, registerLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { Container } from './styles';

registerLocale('pt-BR', ptBR);

interface IProps {
  handleSelectedDate: (date: Date | null) => void;
  name: string;
  value: any;
}

export const InputDate: React.FC<IProps> = ({
  handleSelectedDate,
  name,
  value,
}) => {
  const inputRef = useRef<ReactDatePicker>(null); // HTMLInputElement - vai dar ao inputRef as propriedades de um input
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [startDate, setStartDate] = useState<Date | null>(null);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    // Verifica se o inputRef tem um valor/value. Se tiver preenchido = true. Se tiver vazio = false. !! Tranforma o value em booleano.
    setIsFilled(!!inputRef.current?.props.value); // inputRef pega o valor direto do Input. document.querySelector('input') e etc.
  }, []);

  const nextTwoMonths = new Date(
    new Date().setMonth(new Date().getMonth() + 2),
  );

  const nextDay = new Date(new Date().setDate(new Date().getDate() + 2));

  const isWeekday = (date: Date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container isFilled={isFilled} isFocused={isFocused} aria-readonly="true">
      <DatePicker
        showIcon
        ref={inputRef}
        name={name}
        value={value}
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        customInput={<input inputMode="none" />}
        filterDate={isWeekday}
        placeholderText="00/00/0000"
        minDate={nextDay}
        maxDate={nextTwoMonths}
        wrapperClassName="datePicker"
        selected={startDate}
        locale="pt-BR"
        required
        dateFormat="dd/MM/yyyy"
        onChange={date => {
          handleSelectedDate(date);
          setStartDate(date);
        }}
      />
    </Container>
  );
};
