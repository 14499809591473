import styled from 'styled-components';

export const Description = styled.div`
  padding-bottom: 0.2rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 21px;
  text-align: center;
  &:not(:last-of-type) {
    border-bottom: 1px solid rgba(221, 221, 221, 0.63);
  }
`;
