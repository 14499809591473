/* eslint-disable react/no-array-index-key */

import { useEffect, useState } from 'react';

import User from '@models/User';
import Vehicle from '@models/Vehicle';
import { apiRebox } from '@services/index';
import { formatCellphone } from '@utils/formatters';
import formatCpf from '@utils/formatters/formatCpf';
import { toastify } from '@utils/notifiers';
import {
  validatorCpf,
  validatorEmail,
  validatorPhone,
} from '@utils/validators';

import { Modal } from '..';

import TrackerModal from '../trackerModal/trackerModal';
import {
  Button,
  Container,
  InputText,
  Title,
  Wrapper,
  WrapperTitle,
} from './Styles';

interface Props {
  show: boolean;
  onClose: () => void;
}

const NavbarModal: React.FC<Props> = ({ show, onClose }) => {
  const [loginData, setLoginData] = useState({
    login: '',
    password: '',
  });

  const [vehicles, setVehicles] = useState<Vehicle[]>([]);

  const [showTrackerModal, setShowTrackerModal] = useState<boolean>(false);

  const handleShowTrackerModal = (): void => {
    setShowTrackerModal(true);
  };

  const handleCloseTrackerModal = (): void => {
    setShowTrackerModal(false);
    onClose();
  };

  const handleLoginData = (event: any) => {
    const { name, value } = event.target;
    setLoginData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getVehiclesFromUser = async (id: string | undefined) => {
    try {
      const {
        data: { data: response },
      } = await apiRebox.get(`/users/vehicles?users_id=${id}`);
      setVehicles(
        response.filter((car: Vehicle) => car.classification !== 'MOTO'),
      );
      handleShowTrackerModal();
    } catch (error: any) {
      if (error?.response) {
        toastify('Sinto muito, não foi possível buscar os veículos', 'error');
      }
    }
  };

  const handleLogin = async () => {
    try {
      const {
        data: {
          data: { user: userResponse },
        },
      } = await apiRebox.post('/sessions', {
        email: loginData.login.trim().toLowerCase(),
        password: loginData.password.trim(),
      });
      getVehiclesFromUser(userResponse.id);
    } catch (error: any) {
      toastify('Sinto muito, dados inválidos.', 'error');
      if (error?.response) {
        toastify(error?.response.message, 'error');
      }
    }
  };

  return (
    <>
      <Modal show={show} onClose={onClose} width="550px" height="75vh">
        <Container>
          <Title>Acesse sua conta</Title>
          <Wrapper>
            <WrapperTitle>
              Insira seus dados para realizar o bloqueio do seu carro
            </WrapperTitle>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                marginTop: '1rem',
              }}
            >
              <InputText
                name="login"
                onChange={handleLoginData}
                placeholder="CPF, E-mail ou Celular"
              />
              <InputText
                name="password"
                onChange={handleLoginData}
                placeholder="Senha"
                type="password"
              />
              <Button onClick={handleLogin}>Entrar</Button>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            ></div>
          </Wrapper>
        </Container>
      </Modal>
      <TrackerModal
        show={showTrackerModal}
        onClose={handleCloseTrackerModal}
        vehicles={vehicles}
      />
    </>
  );
};

export default NavbarModal;
