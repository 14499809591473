import React, { useCallback, useState } from 'react';

import {
  HeaderNavigationPublic,
  FooterPublic,
  MenuSideBarPublic,
  Paragraph,
} from '@components/index';

import { Container, Content, Title, Subtitle, Text, Table, H1 } from './styles';

const ClientManual: React.FC = () => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const handleOpenSidebar = useCallback(() => {
    setSidebarIsOpen(!sidebarIsOpen);
  }, [sidebarIsOpen]);

  return (
    <Container sidebarIsOpen={sidebarIsOpen}>
      <MenuSideBarPublic
        handleOpenSidebar={handleOpenSidebar}
        sidebarIsOpen={sidebarIsOpen}
      />

      <HeaderNavigationPublic handleOpenSidebar={handleOpenSidebar} />

      <Content>
        <H1>TERMOS DE USO</H1>
        <Title>
          Neste manual, você encontra todas as informações necessárias para o
          uso da Assistência Auto 24h da REBOX.
        </Title>
        <p>
          Nossa rede de parceiros está 100% dedicada e disponível 24h por dia,
          durante 7 dias por semana, para atender no momento em que você mais
          precisar. Contamos com a maior rede de empresas de assistências do
          país, dedicada a oferecer o melhor serviço pra você.
        </p>
        <h4>Em caso de emergência, fale com a Rebox!</h4>
        <p>
          Nossa equipe está sempre disponível através dos nossos canais: <br />
          <strong>Para pedir sua assistência 24h nos chame no WhatsApp:</strong>
        </p>
        <h3>
          WhatsApp: (11) 4003-3132 <br />
          <b>Aplicativo da Rebox: Baixe em nosso site</b> <br />
          Área do cliente: rebox.com.br/login
        </h3>
        <p>
          <Text>
            <strong>DICA:</strong>
          </Text>{' '}
          <u>
            Para agilizar o atendimento, no momento do acionamento, tenha em
            mãos o número do seu <strong>CPF, PLACA</strong> do veículo
            associado e os endereços para remoção (
            <strong>RETIRA e ENTREGA</strong>).
          </u>
        </p>
        <Subtitle>CONDIÇÕES GERAIS</Subtitle>
        <Paragraph
          nameColor="black"
          fontSize={20}
          textAlign="start"
          fontWeight={500}
        >
          <strong>1. Definições e Termos</strong>
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong style={{ marginRight: 10 }}>
            1.1 O PLANO ANUAL DE ASSISTÊNCIA AUTO 24h da REBOX
          </strong>
          É um conjunto de serviços oferecido exclusivamente a motos, veículos
          de passeio e picapes com peso até 1.8 toneladas e até 30 anos
          fabricação. As principais vantagens da{' '}
          <strong>ASSISTÊNCIA AUTO 24h da REBOX</strong> são a tranquilidade e
          segurança de poder contar com um serviço de situações emergenciais,
          tais como pane mecânica e elétrica, acidente acidente ou esquecimento
          das chaves no interior do veículo ou porta travada, pneu furado,
          bateria descarregada. Os serviços de Assistência são prestados por{' '}
          <strong>
            REBOX SOLUÇÕES INTEGRADAS LTDA, inscrita no CNPJ 37.667.543/0001-77.
          </strong>
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong style={{ marginRight: 10 }}>1.2 USUÁRIO:</strong>
          Entende-se por usuário, a pessoa física que estiver conduzindo o
          veículo no momento do evento.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong style={{ marginRight: 10 }}>1.3 VEÍCULO:</strong>
          Veículo particular devidamente cadastrado. Não estão incluídos
          veículos que tenham peso superior a 1.800 kg, limitado a 30 anos de
          fabricação.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong style={{ marginRight: 10 }}>1.4 OCORRÊNCIA:</strong>É um fato
          danoso e imprevisível, produzido no veículo, tais como acidente, pane
          seca ou qualquer problema mecânico, que provoque sua imobilização e
          necessite de acionamento de serviços.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong style={{ marginRight: 10 }}>1.5 PANE:</strong>
          Defeito de origem mecânica ou elétrica, que impeça a locomoção do
          veículo por seus próprios meios, incluindo os casos de falta de
          combustível, troca de pneus e perda ou quebra de chaves.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong style={{ marginRight: 10 }}>1.6 VIGÊNCIA:</strong>É prazo em
          que o associado pode solicitar os serviços, através dos canais da{' '}
          <strong>REBOX</strong>
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong style={{ marginRight: 10 }}>1.7 ACOMPANHANTES:</strong>
          São considerados acompanhantes os demais ocupantes do veículo, desde
          que afetados por acidente, incêndio, pane, roubo ou furto do veículo,
          respeitando sua capacidade legal.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong style={{ marginRight: 10 }}>1.8 DOMICÍLIO DO USUÁRIO:</strong>
          É o município de domicílio do usuário que consta no cadastro.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong style={{ marginRight: 10 }}>1.9 FRANQUIA:</strong>É o critério
          de limitação ou exclusão do direito ao serviço de assistência a ser
          prestado, estabelecido em função de:{' '}
          <ul>
            <li>
              A distância, em trajeto terrestre normal e viável, entre local
              local onde ocorreu o evento previsto ou pane e o local de
              domicílio do usuário; ou
            </li>
            <li>
              A distância em trajeto normal e viável entre o local onde ocorreu
              o evento previsto ou pane e o destino de jornada indicado pelo
              usuário.
            </li>
          </ul>
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong style={{ marginRight: 10 }}>
            1.10 NÚMERO DE UTILIZAÇÃO:
          </strong>
          Diz respeito ao limite de utilização dos serviços descritos no plano
          de assistência adquirido pelo associado.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong style={{ marginRight: 10 }}>
            1.11 CARÊNCIA PARA UTILIZAÇÃO DO PLANO:
          </strong>
          Período mínimo em que o associado deve aguardar para solicitar os
          serviços do seu plano.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong style={{ marginRight: 10 }}>1.12 EXCLUSÕES:</strong>
          São os serviços e situações que não são contemplados pelo plano de
          assistência da <strong>REBOX.</strong>
        </Paragraph>

        <Paragraph
          nameColor="black"
          fontSize={20}
          textAlign="start"
          fontWeight={500}
        >
          <strong>2. CONDIÇÕES GERAIS</strong>
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong style={{ marginRight: 10 }}>2.1</strong>
          Os serviços oferecidos neste contrato são de assistência emergencial e
          não se configuram como um seguro. Os serviços, em questão, somente
          serão prestados em situações emergenciais por ano, que se caracterizem
          como um dos eventos previstos neste manual, sendo vedado o serviço de
          conveniência.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong style={{ marginRight: 10 }}>2.2</strong>
          Por não se confundirem com um seguro, os serviços de assistência auto
          24h têm regras próprias e coberturas limitadas.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong style={{ marginRight: 10 }}>2.3</strong>
          Todos os serviços de assistência automotiva, previstos neste contrato,
          devem ser previamente solicitados para a Central de Assistência 24h da
          Rebox, através dos canais disponíveis acima, a fim de que esta
          autorize e/ou organize a prestação dos mesmos.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong style={{ marginRight: 10 }}>2.4</strong> Os serviços
          organizados sem autorização prévia ou participação da{' '}
          <strong>Central de Assistência 24h da Rebox</strong> não serão
          reembolsados ao usuário ou a quem tiver feito qualquer pagamento
          pagamento em nome deste.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong style={{ marginRight: 10 }}>2.5</strong> Apesar dos serviços
          descritos neste contrato contrato contrato serem de caráter
          emergencial, a prestação dos mesmos feita de acordo com a
          infraestrutura, regulamentos, legislação e costumes do país/local do
          evento, localização e horário, natureza e urgência do atendimento
          necessário e requerido.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong style={{ marginRight: 10 }}>2.6</strong>
          Devido ao caráter emergencial dos serviços prestados ao usuário,{' '}
          <strong>a Central de Assistência 24h</strong>{' '}
          <Text>
            está desobrigada a prestar qualquer atendimento aos veículos que já
            se encontrem em uma oficina
          </Text>{' '}
          ou que coloque em risco a integridade física do prestador dos serviços
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong style={{ marginRight: 10 }}>2.7</strong>O usuário deverá
          sempre providenciar previamente a remoção de eventual carga que
          prejudique ou impeça o reboque, principalmente os veículos utilitários
          de carga, como as picapes.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong style={{ marginRight: 10 }}>2.8</strong>A{' '}
          <strong>REBOX</strong> estará desobrigada da prestação de serviços nos
          casos alheios à vontade, tais como enchentes, greves, convulsões
          sociais, áreas de risco, atos de vandalismo, interdições de rodovias
          e/ou de outras vias de acesso, casos de fortuitos ou de força maior.{' '}
          <br />
          São dados essenciais ao atendimento: nome, número do CPF do cliente e
          identificação do veículo, sem prejuízo de outros que sejam
          considerados necessários conforme a natureza do caso. A{' '}
          <strong>Rebox</strong> se resguarda o direito de solicitar todo e
          qualquer documento que entender pertinente para a prestação dos
          serviços acima mencionados.{' '}
          <strong>
            A negativa no fornecimento de qualquer um dos documentos ou
            esclarecimento por parte do beneficiário, implicará na desobrigação
            da REBOX em prestar os serviços.
          </strong>{' '}
          Os serviços somente serão prestados na presença do assistido ou de um
          representante legalmente capaz, munido do número do{' '}
          <strong>CPF</strong>, chaves do veículo e documentos do veículo.
        </Paragraph>

        <Paragraph
          nameColor="black"
          fontSize={20}
          textAlign="start"
          fontWeight={500}
        >
          <strong style={{ marginRight: 10 }}>3. ÂMBITO GEOGRÁFICO: </strong>
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          Esta assistência se aplica única e exclusivamente a veículos no
          território brasileiro.
        </Paragraph>

        <Paragraph
          nameColor="black"
          fontSize={20}
          textAlign="start"
          fontWeight={500}
        >
          <strong style={{ marginRight: 10 }}>
            4. SERVIÇOS PARA O VEÍCULO ASSISTIDO
          </strong>
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong>4.1 RESUMO DA COBERTURA</strong>
          <br /> No final desse manual, segue a tabela de COBERTURA DE CADA
          PLANO DE ASSISTÊNCIA da REBOX.
          <br />
          <strong>Importante:</strong>{' '}
          <Text>
            {' '}
            A somatória de utilizações dos serviços, descritos na anexo, diz
            anexo, diz respeito ao limite de utilizações por ano de plano de
            plano de assistência. Isso quer dizer que, para cada ano de
            vigência, a partir da data da contratação do plano, o assistido
            poderá utilizar o número total dos serviços listados na tabela em
            anexo, podendo solicitar o mesmo serviço diversas vezes ou serviços
            variados, desde que não ultrapasse o limite de utilização
            estabelecido para o plano escolhido. Essses acionamentos não são
            cumulativos para a próxima renovação. Caso o plano seja renovado, 12
            (doze) meses após a contratação, se renovará as utilizações do plano
            apenas com o número específico de cada plano, sem acrescimo de
            acionamentos do ano anterior. A renovação se configura como um novo
            plano e com as mesmas regras estabelecidas, no que diz respeito aos
            prazos e carências de um novo plano.
          </Text>
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong>4.2 SOCORRO MECÂNICO </strong> <br />
          Na hipótese de pane que impossibilite a locomoção própria do veículo,
          será providenciado o guincho para realizar a remoção do veículo até o
          endereço informado pelo cliente, desde que dentro do raio de km máximo
          contratado. Qualquer despesa relativa à reposição de peças danificadas
          ou mão de obra para a execução, serão de responsabilidade do usuário.
          apenas realiza o serviço de remoção.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong>4.3 SOCORRO MECÂNICO </strong> <br />O veículo será rebocado
          até a oficina ou concessionária mais próxima, indicada pelo usuário, e
          localizada até o raio máximo contratado.
          <br />
          <Text>
            {' '}
            <strong>IMPORTANTE:</strong>
          </Text>{' '}
          Para o cálculo de raio, entende-se a distância percorrida pelo serviço
          de reboque, contada do local em veículo se encontra, até o destino
          escolhido pelo cliente.
          <br />
          <Text>NOTAS IMPORTANTES:</Text> <br />
          <strong>1)</strong> Caso haja despesas com pedágio (ida e volta) para
          o prestador serão pagas pelo Cliente, diretamente ao prestador;
          <br /> <strong>2)</strong> Caso haja roda travada e seja necessário a
          utilização de <strong>PATINS</strong>, para guinchar o veículo, esse
          custo será por conta do cliente;
          <br />
          <strong>3)</strong> Caso exceda o KM contratado, o cliente pagará o
          R$8,00 para cada km excedente diretamente ao prestador.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong>4.4 TROCA DE PNEUS </strong> <br />
          Na hipótese de danos aos pneus, a <strong>REBOX</strong>{' '}
          disponibilizará um profissional para solução do problema, através da
          troca do pneu por um estepe do cliente. Caso não seja possível a
          realização do serviço, a veículo será rebocado até um borracheiro mais
          próximo.
          <br />O cliente só poderá rebocar seu veículo até a sua residência,
          caso não haja borracharias em funcionamento.
          <br />
          As despesas para o conserto ou compra de pneu, câmara, aro,
          borracheiro, caso seja necessário, entre outras, ocorrerão por conta
          do usuário.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong>4.5 PANE SECA </strong> <br />
          Na hipótese da impossibilidade de locomoção do automóvel por falta de
          combustível, a <strong>REBOX</strong> providenciará o reboque até o
          posto de posto de posto de posto de posto de posto de abastecimento
          próximo, para que o que o que o usuário possa abastecê-lo. A
          <strong> REBOX</strong> arcará reboque, ficando a cargo do usuário, os
          gastos com o combustível.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong>4.6 CHAVEIRO PARA AUTO </strong> <br />
          Na hipótese de perda, esquecimento da chave no interior do veículo ou
          quebra na ignição, fechadura ou na tranca de direção, havendo
          necessidade, a <strong>REBOX</strong> providenciará o envio de um
          prestador de serviços para{' '}
          <strong>
            <u>abertura do veículo</u>
          </strong>
          , sem arrombamento e sem danos. danos. danos. <br />A responsabilidade
          da
          <strong> REBOX</strong> restringe-se custo desta mão de obra apenas
          para{' '}
          <strong>
            <u>abertura de porta</u>
          </strong>
          , não cabendo conserto, compra de peças ou fabricação de cópias de
          chaves. Quando não for possível resolver o problema com envio do
          chaveiro ou na indisponibilidade deste profissional, rebocamos o
          veículo para um local, a escolha do usuário, dentro do município onde
          se verificou a ocorrência, respeitando a distância de 60km, nesse
          caso.
          <br /> O serviço, aqui previsto, será prestado tão somente nos casos
          em que o veículo se encontre em cidades com prestadores cadastrados, e
          desde que se utilizem chaves e fechaduras convencionais, ou seja, que
          possibilitem a abertura por chaveiro, sem necessidade de utilização de
          equipamentos especiais, códigos eletrônicos, etc. Nestes casos, o
          veículo será rebocado.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong>4.7 CARGA NA BATERIA (Auxílio de partida) </strong> <br />
          Caso o veículo não funcione, por falta de energia na bateria, a{' '}
          <strong>REBOX</strong> providenciará mão de obra para efetuar carga na
          bateria no local.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong>4.8 HOSPEDAGEM </strong> <br />
          Caso a ocorrência aconteça a mais de 250km de distância da residência
          do associado e o reparo no veiculo demore mais de 48h, será
          providenciada uma ajuda de custos para a hospedagem (diárias) em
          hotel, até a conclusão dos serviços de reparos do veículo. <br />
          <Text>IMPORTANTE:</Text>
          <br />
          Este serviço é oferecido apenas para o cliente e limitado ao número de
          utilizações da vigência do plano, com limite de custos de acordo com
          cada plano. Esse benefício só será disponibilizado caso o veículo
          tenha sido rebocado pela <strong>REBOX</strong>.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong>4.7 CARGA NA BATERIA (Auxílio de partida) </strong> <br />
          Caso o veículo não funcione, por falta de energia na bateria, a{' '}
          <strong>REBOX</strong> providenciará mão de obra para efetuar carga na
          bateria no local.
        </Paragraph>

        <Paragraph
          nameColor="black"
          fontSize={20}
          textAlign="start"
          fontWeight={500}
        >
          <strong>5. UTILIZAÇÃO DOS SERVIÇOS E PRAZOS</strong> <br />
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          O <strong>PLANO DE ASSISTÊNCIA AUTO 24h da REBOX</strong> será
          automaticamente ativado após a confirmação do pagamento da primeira
          mensalidade e tem duração de um ano (12 meses), a partir dessa data.
          Os serviços de assistências 24 horas terão uma carência de{' '}
          <Text>03 (três) úteis</Text>, contados a partir da confirmação do
          pagamento da primeira mensalidade. Em caso de
          <Text>inadimplência</Text> o cliente terá que cumprir uma{' '}
          <Text>CARÊNCIA</Text>, de acordo com o número de dias de atraso:{' '}
          <br />• <Text>De 03 (três) a 5 (cinco)</Text> dias de de{' '}
          <Text>CARÊNCIA de 03 (três) dias úteis;</Text>
          <br /> • <Text>De 06 (seis) a 15 (quinze)</Text> dias de{' '}
          <Text>CARÊNCIA de 07 (sete) dias úteis;</Text>
          <br /> • <Text>De 16 (dezesseis) a 30 (trinta)</Text> dias de atraso:{' '}
          <Text>CARÊNCIA de 15 (quinze) dias úteis;</Text>
          <br /> •<Text> Acima de 30 (trinta)</Text> dias de atraso:{' '}
          <Text>CARÊNCIA de 30 (trinta) dias;</Text>
          <br /> •{' '}
          <Text>
            Carência de 30 dias: Quando o cliente tem a solicitação de primeiro
            primeiro acionamento de residência negada.
          </Text>
        </Paragraph>

        <Paragraph
          nameColor="black"
          fontSize={20}
          textAlign="start"
          fontWeight={500}
        >
          <strong>6. SUBSTITUIÇÃO DO VEÍCULO DO CLIENTE</strong> <br />
        </Paragraph>
        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          A qualquer momento durante a vigência do{' '}
          <strong>PLANO DE ASSISTÊNCIA AUTO 24h 24h da REBOX</strong>, desde que
          tenha utilizado apenas 1 (um) acionamento, o cliente poderá substituir
          o veículo 1 (uma) vez, durante a vigência do plano, bastando para isso
          entrar em contato com a Central de Atendimento, através de um dos
          nossos canais. O veículo substituto deverá se submeter às mesmas
          regras previstas nesse contrato e obedecer o prazo de carência de{' '}
          <Text>05 (cinco) dias úteis</Text> para a utilização dos serviços.
        </Paragraph>

        <Paragraph
          nameColor="black"
          fontSize={20}
          textAlign="start"
          fontWeight={500}
        >
          <strong>7. SOBRE O CANCELAMENTO</strong> <br />
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong>7.1 DO PLANO </strong> <br />O plano de assistência auto 24h
          não tem <Text>FIDELIDADE</Text> e pode ser
          <Text> CANCELADO</Text> a qualquer momento, desde que o cliente não
          solicite nenhuma assistência.
          <br /> Caso solicite, pelo menos uma assistência, gera uma fidelidade
          de 12 meses, contada a partir da data da contratação do plano. O
          processo de cancelamento, na hipótese de não utilização de alguma
          assistência, será realizado por meio da Central de Atendimento da
          <strong> REBOX</strong>, através de qualquer um dos nossos canais,
          mediante apresentação do número do CPF. <br />A <strong>REBOX</strong>{' '}
          reserva o direito de cancelar automaticamente o plano do Cliente ou
          negar a prestação de serviço quando este causar ou provocar
          intencionalmente um fato que dê origem à necessidade de prestação de
          qualquer um dos serviços aqui descritos; quando o Cliente omitir ou
          fornecer intencionalmente informações falsas ou quando a solicitação
          estiver em desarcodo com as regras estabelecidas nesse contrato.
          <br /> <Text>IMPORTANTE:</Text> Caso o Cliente o Cliente solicite uma
          assistência que esteja dentro das vendações dispostas no
          <strong> Item 9.1</strong> deste contrato, a <strong>REBOX</strong> se
          reserva no direito de cancelar o plano do Cliente e efetuar o
          ressarcimento do valor pago no primeiro ou último pagamento, sem
          prejuízo para o Cliente.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong>7.2 DE AGENDAMENTOS </strong> <br />
          Em caso de agendamento de um serviço para uma data posterior, o
          cliente poderá cancelar o agendamento até 2 (duas) horas antes do
          horário estabelecido para a execução do serviço, sem prejuízo ao
          número de utilizações de que tem direito.
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong>7.3 DE ASSISTÊNCIA NO DIA </strong> <br />
          <Text>IMPORTANTE:</Text>{' '}
          <u>
            O cliente, que solicitar um serviço de assistência, terá 10 (dez)
            minutos para cancelar o seu pedido sem prejuízo à franquia
            adquirida. Caso peça o cancelamento após o tempo estabelecido, esse
            serviço será contabilizado como executado e descontado de sua
            franquia.
          </u>
        </Paragraph>

        <Paragraph
          nameColor="black"
          fontSize={20}
          textAlign="start"
          fontWeight={500}
        >
          <strong>8. DA MUDANÇA DE PLANO</strong> <br />
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          O cliente poderá, a qualquer momento, solicitar a alteração de plano,
          desde que tenha, no máximo, 1 (uma) utilização de assistência. Caso
          tenha mais de 1 (uma), deverá cumprir a vigência do plano atual e, na
          renovação deste, solicitar a mudança.
        </Paragraph>

        <Paragraph
          nameColor="black"
          fontSize={20}
          textAlign="start"
          fontWeight={500}
        >
          <strong>9. DA VEDAÇÃO DE ASSISTÊNCIA PARA TITULAR DO CONTRATO</strong>{' '}
          <br />
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          É vedado o acionamento para um contrato (placa), cujo titular do
          contrato tenha débitos em aberto com a <strong>Rebox</strong>,
          referentes a outros contratos que já tenham utilizado pelo menos 1
          (um) acionanento. Só será permitido o acionamento após a regularização
          dos pagamentos em aberto em nome do titular
        </Paragraph>

        <Paragraph
          nameColor="black"
          fontSize={20}
          textAlign="start"
          fontWeight={500}
        >
          <strong>10. DO REAJUSTEDO VALOR</strong> <br />
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          O plano será reajustado anualmente, em seu aniversário, com base no
          IGP-M e nos índices do segmento de assistência veicular.
        </Paragraph>

        <Paragraph
          nameColor="black"
          fontSize={20}
          textAlign="start"
          fontWeight={500}
        >
          <strong>11. DA INADIMPLÊNCIA</strong> <br />
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          Caso o utilize uma das assistência e esteja inadimplente com a Rebox
          por mais de 30 dias, a Rebox se reserva no direito de negativar o CPF
          do titular do contrato, até a regularização da(s) pendência(s)
          financeiro(s)
        </Paragraph>

        <Paragraph
          nameColor="black"
          fontSize={20}
          textAlign="start"
          fontWeight={500}
        >
          <strong>12.EXCLUSÕES GERAIS</strong> <br />
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong>12.1 NÃO ESTÃO COBERTOS POR ESTA ASSISTÊNCIA: </strong> <br />
        </Paragraph>
        <ul>
          {' '}
          <li>Veículo do tipo Rebaixado;</li>{' '}
          <li>
            Remoções (reboques) intermunicipais até o segundo acionamento de
            cada plano;
          </li>
          <li>
            {' '}
            Primeiro acionamento para veículo que estejam em residências,
            oficinas,depósitos de ógãos governamentais ou associados;
          </li>{' '}
          <li>
            {' '}
            Primeiro acionamento da rua que reside associado(a), num prazo menor
            que 30 dias desde a contratação do plano;
          </li>{' '}
          <li>Acionamentos dentro do período de carência;</li>
          <li>
            Serviços providenciados diretamente pelo usuário ou terceiros, sem
            prévio contato com Central de Atendimento da <strong>REBOX</strong>;
          </li>{' '}
          <li>Ocorrências fora dos âmbitos definidos;</li>
          <li> Mão de obra para reparação do veículo;</li>
          <li>
            Eventos ocorridos com veículos com peso superior a 1.8 toneladas;
          </li>
          <li>
            Modelos de caminhonetes grandes, caminhões, suv (sport utility
            utility vehicle) grandes e pickups grandes, além de motos superiores
            350cc  Mão de obra para reparação do veículo (exceto nos casos de
            reparo no local, quando de acordo com os termos da tabela de
            serviços);
          </li>
          <li>Consertos de pneus;</li>
          <li>Substituição de peças defeituosas no veículo;</li>
          <li>
            Fornecimento de qualquer material destinado à reparação do veículo;
          </li>
          <li>Remoção de veículos carregados, sejam leves ou utilitários;</li>
          <li>Remoção para veículos que estejam funcionando;</li>
          <li>
            {' '}
            Remoção para veículos não cadastrados – Essa infração ocasiona o
            cancelamento do plano;
          </li>{' '}
          <li>Fornecimento de combustível;</li>
          <li>Veículos blindados;</li>
          <li>Pagamento de diárias em depósitos de carros;</li>
          <li>Serviços de assistência para terceiros;</li>
          <li>
            Serviços que impliquem o rompimento de lacres quando o veículo
            estiver na garantia de fábrica;
          </li>
          <li>
            Resgate de veículos capotados; veículos em trilhas ou caminhos de
            difícil impedidos ou não abertos ao tráfego ou de areias fofas,
            fofas, movediças, ribanceiras, canteiros ou atoleiros;
          </li>{' '}
          <li>
            Remoção de veículos de garagens estacionamentos em subsolo ou
            elevados, em relação ao nível da rua/via;
          </li>{' '}
          <li>
            Eventos que ocorram em situação de guerra, manifestações populares,
            atos de terrorismo e sabotagem, greves, enchentes, fortes chuvas,
            interdições de rodovias e/ou outras vias de acesso, áreas de risco,
            detenções por parte de autoridade por delito não derivado de
            acidente de transito e restrições à livre circulação, casos
            fortuitos e de força maior;
          </li>{' '}
          <li>
            Assistências em que o usuário oculte informações necessárias para a
            prestação do serviço ou descaracterização proposital de um fato
            ocorrido;
          </li>
          <li>
            Automóveis que estiverem sendo utilizadas para prática de atividades
            off-road, trilhas, passeios em dunas, ou locais de difícil
            impossível impossível acesso para o veículo de reboque, como
            montanhas, praias, encostas, ou outro local que não possua via
            pública de acesso.
          </li>
        </ul>

        <Paragraph
          nameColor="black"
          fontSize={20}
          textAlign="start"
          fontWeight={500}
        >
          <strong>13. FORUM</strong> <br />
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          A <strong>REBOX</strong> estabelece que o foro competente para dirimir
          eventuais questões oriundas destas Condições Gerais será sempre o do
          domicílio do Cliente. <br />
          <strong>
            CONTRATANTE declara estar de acordo com as cláusulas constantes no
            mesmo.{' '}
          </strong>
        </Paragraph>

        <Paragraph
          nameColor="black"
          fontSize={20}
          textAlign="center"
          fontWeight={500}
        >
          <strong>COBERTURAS E CONDIÇÕES DE USO</strong> <br />
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          Abaixo, disponibilizamos todos os planos existentes na{' '}
          <strong>Rebox</strong> e suas respectivas franquias. Veja qual plano
          corresponde o seu contrato e conheça as
          <strong> COBERTURAS e CONDIÇÕES. </strong>
          Qualquer dúvida, entre em contato com um dos nossos canais através dos
          contatos abaixo:
        </Paragraph>

        <Paragraph
          nameColor="black"
          fontSize={20}
          textAlign="center"
          fontWeight={500}
        >
          <strong>RESUMO DAS COBERTURAS</strong> <br />
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <u>
            <strong>1. PLANO PARA CARRO DE PASSEIO</strong>
          </u>
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong>1.1 PLANO REBOX AUTO (Valor: 24,90 por mês)</strong>
        </Paragraph>

        <Table>
          <thead>
            <tr>
              <th>RESUMO DA COBERTURA</th>
            </tr>{' '}
          </thead>
          <tbody>
            <tr>
              <td>
                {' '}
                <b>1. Carências</b>{' '}
              </td>
            </tr>
            <tr>
              <td>
                {' '}
                <b>1.1 Carência para ativação:</b> 03 (três) dias úteis, após
                confirmação de pagamento{' '}
              </td>
            </tr>
            <tr>
              <td>
                <b>1.2 Carência para inadimplência:</b> Ver cláusula 5 no manual
                do cliente.
              </td>
            </tr>
            <tr>
              <td>
                <b>2. Vigência do contrato:</b> 12 meses (anual)
              </td>
            </tr>
            <tr>
              <td>
                <b>3. Número máximo de utilização no ano</b>
              </td>
              <td>
                <b>Até 04 (quatro) utilizações</b>
              </td>
            </tr>
            <tr>
              <td>
                <b>4. Máximo de utilização no mês</b>
              </td>
              <td>
                <b>
                  Até 02 (duas) utilizações no mês (Intervalo de 15 dias de um
                  acionamento para outro)
                </b>
              </td>
            </tr>
            <tr>
              <td>
                <b>5. Cobertura</b>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <b>5.1 Guincho ou recolha, após pane</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.2 Troca de pneus</b>
              </td>
              <td>Até 60km</td>
            </tr>
            <tr>
              <td>
                <b>5.3 Pane seca</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.4 Chaveiro auto (abertura)</b>
              </td>
              <td>Até 150,00R$</td>
            </tr>
            <tr>
              <td>
                <b>5.5 Carga na bateria</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.6 Hospedagem </b>
              </td>
              <td>Até R$100,00</td>
            </tr>
          </tbody>
        </Table>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <br />
          <strong>1.2 PLANO REBOX AUTO SMART (Valor: R$29,90 por mês)</strong>
        </Paragraph>
        <Table>
          <thead>
            <tr>
              <th>RESUMO DA COBERTURA</th>
            </tr>{' '}
          </thead>
          <tbody>
            <tr>
              <td>
                {' '}
                <b>1. Carências</b>{' '}
              </td>
            </tr>
            <tr>
              <td>
                {' '}
                <b>1.1 Carência para ativação:</b> 03 (três) dias úteis, após
                confirmação de pagamento{' '}
              </td>
            </tr>
            <tr>
              <td>
                {' '}
                <b>1.2 Carência para inadimplência:</b> Ver cláusula 5 no manual
                do cliente.
              </td>
            </tr>
            <tr>
              <td>
                {' '}
                <b>2. Vigência do contrato:</b> 12 meses (anual)
              </td>
            </tr>
            <tr>
              <td>
                <b>3. Número máximo de utilização no ano</b>
              </td>
              <td>
                <b>Até 06 (seis) utilizações</b>
              </td>
            </tr>
            <tr>
              <td>
                <b>4. Máximo de utilização no mês</b>
              </td>
              <td>
                <b>
                  Até 02 (duas) utilizações no mês (Intervalo de 15 dias de um
                  acionamento para outro)
                </b>
              </td>
            </tr>
            <tr>
              <td>
                <b>5. Cobertura</b>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <b>5.1 Guincho ou recolha, após pane</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.2 Troca de pneus</b>
              </td>
              <td>Até 60km</td>
            </tr>
            <tr>
              <td>
                <b>5.3 Pane seca</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.4 Chaveiro auto (abertura)</b>
              </td>
              <td>Até 150,00R$</td>
            </tr>
            <tr>
              <td>
                <b>5.5 Carga na bateria</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.6 Hospedagem </b>
              </td>
              <td>Até R$100,00</td>
            </tr>
          </tbody>
        </Table>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <br />
          <strong>1.3 PLANO REBOX AUTO+ (Valor: R$39,90 por mês)</strong>
        </Paragraph>
        <Table>
          <thead>
            <tr>
              <th>RESUMO DA COBERTURA</th>
            </tr>{' '}
          </thead>
          <tbody>
            <tr>
              <td>
                {' '}
                <b>1. Carências</b>{' '}
              </td>
            </tr>
            <tr>
              <td>
                {' '}
                <b>1.1 Carência para ativação:</b> 03 (três) dias úteis, após
                confirmação de pagamento{' '}
              </td>
            </tr>
            <tr>
              <td>
                {' '}
                <b>1.2 Carência para inadimplência:</b> Ver cláusula 5 no manual
                do cliente
              </td>
            </tr>
            <tr>
              <td>
                {' '}
                <b>2. Vigência do contrato:</b> 12 meses (anual)
              </td>
            </tr>
            <tr>
              <td>
                <b>3. Número máximo de utilização no ano</b>
              </td>
              <td>
                <b>Até 06 (seis) utilizações</b>
              </td>
            </tr>
            <tr>
              <td>
                <b>4. Máximo de utilização no mês</b>
              </td>
              <td>
                <b>
                  Até 02 (duas) utilizações no mês (Intervalo de 15 dias de um
                  acionamento para outro)
                </b>
              </td>
            </tr>
            <tr>
              <td>
                <b>5. Cobertura</b>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <b>5.1 Guincho ou recolha, após pane</b>
              </td>
              <td>Até 200km</td>
            </tr>
            <tr>
              <td>
                <b>5.2 Troca de pneus</b>
              </td>
              <td>Até 60km</td>
            </tr>
            <tr>
              <td>
                <b>5.3 Pane seca</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.4 Chaveiro auto (abertura)</b>
              </td>
              <td>Até 150,00R$</td>
            </tr>
            <tr>
              <td>
                <b>5.5 Carga na bateria</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.6 Hospedagem </b>
              </td>
              <td>Até R$100,00</td>
            </tr>
          </tbody>
        </Table>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <br />
          <strong>1.4 PLANO REBOX LIBERTY (Valor: R$39,90 por mês)</strong>
        </Paragraph>
        <Table>
          <thead>
            <tr>
              <th>RESUMO DA COBERTURA</th>
            </tr>{' '}
          </thead>
          <tbody>
            <tr>
              <td>
                {' '}
                <b>1. Carências</b>{' '}
              </td>
            </tr>
            <tr>
              <td>
                {' '}
                <b>1.1 Carência para ativação:</b> 03 (três) dias úteis, após
                confirmação de pagamento{' '}
              </td>
            </tr>
            <tr>
              <td>
                {' '}
                <b>1.2 Carência para inadimplência:</b> Ver cláusula 5 no manual
                do cliente.
              </td>
            </tr>
            <tr>
              <td>
                {' '}
                <b>2. Vigência do contrato:</b> 12 meses (anual)
              </td>
            </tr>
            <tr>
              <td>
                <b>3. Número máximo de utilização no ano</b>
              </td>
              <td>
                <b>Até 12 (doze) utilizações</b>
              </td>
            </tr>
            <tr>
              <td>
                <b>4. Máximo de utilização no mês</b>
              </td>
              <td>
                <b>
                  Até 01 (uma) utilização no mês (Intervalo de 15 dias de um
                  acionamento para outro)
                </b>
              </td>
            </tr>
            <tr>
              <td>
                <b>5. Cobertura</b>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <b>5.1 Guincho ou recolha, após pane</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.2 Troca de pneus</b>
              </td>
              <td>Até 60km</td>
            </tr>
            <tr>
              <td>
                <b>5.3 Pane seca</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.4 Chaveiro auto (abertura)</b>
              </td>
              <td>Até 150,00R$</td>
            </tr>
            <tr>
              <td>
                <b>5.5 Carga na bateria</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.6 Hospedagem </b>
              </td>
              <td>Até R$100,00</td>
            </tr>
          </tbody>
        </Table>
        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <br />
          <strong>
            <u>2. PLANO PARA MOTO (Até 350CC)</u>
          </strong>
          <br />
          <strong>2.1 PLANO REBOX MOTO (Valor: R$24,90 por mês)</strong>
        </Paragraph>
        <Table>
          <thead>
            <tr>
              <th>RESUMO DA COBERTURA</th>
            </tr>{' '}
          </thead>
          <tbody>
            <tr>
              <td>
                {' '}
                <b>1. Carências</b>{' '}
              </td>
            </tr>
            <tr>
              <td>
                {' '}
                <b>1.1 Carência para ativação:</b> : 03 (três) dias úteis, após
                confirmação de pagamento{' '}
              </td>
            </tr>
            <tr>
              <td>
                {' '}
                <b>1.2 Carência para inadimplência:</b> Ver cláusula 5 no manual
                do cliente.
              </td>
            </tr>
            <tr>
              <td>
                {' '}
                <b>2. Vigência do contrato:</b> 12 meses (anual)
              </td>
            </tr>
            <tr>
              <td>
                <b>3. Número máximo de utilização no ano</b>
              </td>
              <td>
                <b>Até 4 (quatro) utilizações</b>
              </td>
            </tr>
            <tr>
              <td>
                <b>4. Máximo de utilização no mês</b>
              </td>
              <td>
                <b>
                  Até 02 (duas) utilizações no mês (Intervalo de 15 dias de um
                  acionamento para outro)
                </b>
              </td>
            </tr>
            <tr>
              <td>
                <b>5. Cobertura</b>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <b>5.1 Guincho ou recolha, após pane</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.3 Pane seca</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.5 Carga na bateria</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.6 Hospedagem </b>
              </td>
              <td>Até R$100,00</td>
            </tr>
          </tbody>
        </Table>
        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <br />

          <strong>2.2 PLANO REBOX MOTO SMART (Valor: R$29,90 por mês)</strong>
        </Paragraph>
        <Table>
          <thead>
            <tr>
              <th>RESUMO DA COBERTURA</th>
            </tr>{' '}
          </thead>
          <tbody>
            <tr>
              <td>
                {' '}
                <b>1. Carências</b>{' '}
              </td>
            </tr>
            <tr>
              <td>
                {' '}
                <b>1.1 Carência para ativação:</b> : 03 (três) dias úteis, após
                confirmação de pagamento{' '}
              </td>
            </tr>
            <tr>
              <td>
                {' '}
                <b>1.2 Carência para inadimplência:</b> Ver cláusula 5 no manual
                do cliente.
              </td>
            </tr>
            <tr>
              <td>
                {' '}
                <b>2. Vigência do contrato:</b> 12 meses (anual)
              </td>
            </tr>
            <tr>
              <td>
                <b>3. Número máximo de utilização no ano</b>
              </td>
              <td>
                <b>Até 6 (seis) utilizações</b>
              </td>
            </tr>
            <tr>
              <td>
                <b>4. Máximo de utilização no mês</b>
              </td>
              <td>
                <b>
                  Até 02 (duas) utilizações no mês (Intervalo de 15 dias de um
                  acionamento para outro)
                </b>
              </td>
            </tr>
            <tr>
              <td>
                <b>5. Cobertura</b>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <b>5.1 Guincho ou recolha, após pane</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.3 Pane seca</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.5 Carga na bateria</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.6 Hospedagem </b>
              </td>
              <td>Até R$100,00</td>
            </tr>
          </tbody>
        </Table>
        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <br />

          <strong>2.3 PLANO REBOX MOTO LIBERTY (Valor: R$39,90 por mês)</strong>
        </Paragraph>
        <Table>
          <thead>
            <tr>
              <th>RESUMO DA COBERTURA</th>
            </tr>{' '}
          </thead>
          <tbody>
            <tr>
              <td>
                {' '}
                <b>1. Carências</b>{' '}
              </td>
            </tr>
            <tr>
              <td>
                {' '}
                <b>1.1 Carência para ativação:</b> : 03 (três) dias úteis, após
                confirmação de pagamento{' '}
              </td>
            </tr>
            <tr>
              <td>
                {' '}
                <b>1.2 Carência para inadimplência:</b> Ver cláusula 5 no manual
                do cliente
              </td>
            </tr>
            <tr>
              <td>
                {' '}
                <b>2. Vigência do contrato:</b> 12 meses (anual)
              </td>
            </tr>
            <tr>
              <td>
                <b>3. Número máximo de utilização no ano</b>
              </td>
              <td>
                <b>Até 12 (doze) utilizações</b>
              </td>
            </tr>
            <tr>
              <td>
                <b>4. Máximo de utilização no mês</b>
              </td>
              <td>
                <b>
                  Até 01 (uma) utilização no mês (Intervalo de 15 dias de um
                  acionamento para outro)
                </b>
              </td>
            </tr>
            <tr>
              <td>
                <b>5. Cobertura</b>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <b>5.1 Guincho ou recolha, após pane</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.3 Pane seca</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.5 Carga na bateria</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.6 Hospedagem </b>
              </td>
              <td>Até R$100,00</td>
            </tr>
          </tbody>
        </Table>
        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <br />
          <u>
            <strong>3. PLANO PARA UTILITÁRIOS</strong>
          </u>
        </Paragraph>

        <Paragraph nameColor="black" fontSize={16} textAlign="start">
          <strong>3.1 PLANO REBOX UTILITY (Valor: R$29,90 por mês)</strong>
        </Paragraph>

        <Table>
          <thead>
            <tr>
              <th>RESUMO DA COBERTURA</th>
            </tr>{' '}
          </thead>
          <tbody>
            <tr>
              <td>
                {' '}
                <b>1. Carências</b>{' '}
              </td>
            </tr>
            <tr>
              <td>
                {' '}
                <b>1.1 Carência para ativação:</b> 03 (três) dias úteis, após
                confirmação de pagamento{' '}
              </td>
            </tr>
            <tr>
              <td>
                <b>1.2 Carência para inadimplência:</b> Ver cláusula 5 no manual
                do cliente
              </td>
            </tr>
            <tr>
              <td>
                <b>2. Vigência do contrato:</b> 12 meses (anual)
              </td>
            </tr>
            <tr>
              <td>
                <b>3. Número máximo de utilização no ano</b>
              </td>
              <td>
                <b>Até 04 (quatro) utilizações</b>
              </td>
            </tr>
            <tr>
              <td>
                <b>4. Máximo de utilização no mês</b>
              </td>
              <td>
                <b>
                  Até 02 (duas) utilizações no mês (Intervalo de 15 dias de um
                  acionamento para outro)
                </b>
              </td>
            </tr>
            <tr>
              <td>
                <b>5. Cobertura</b>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <b>5.1 Guincho ou recolha, após pane</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.2 Troca de pneus</b>
              </td>
              <td>Até R$150,00</td>
            </tr>
            <tr>
              <td>
                <b>5.3 Pane seca</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.4 Chaveiro auto (abertura)</b>
              </td>
              <td>Até R$150,00</td>
            </tr>
            <tr>
              <td>
                <b>5.5 Carga na bateria</b>
              </td>
              <td>Até 100km</td>
            </tr>
            <tr>
              <td>
                <b>5.6 Hospedagem </b>
              </td>
              <td>Até R$100,00</td>
            </tr>
          </tbody>
        </Table>
      </Content>
      <FooterPublic />
    </Container>
  );
};

export default ClientManual;
