import React, { useState } from 'react';

import { IoClose } from 'react-icons/io5';

import {
  SubtitleSecondary,
  Paragraph,
  InputCheckBox,
  InputText,
  InputMask,
  InputSelect,
  ButtonMain,
  ButtonDefault,
  InputRadio,
} from '@components/index';
import { apiRebox } from '@services/index';
import { toastify } from '@utils/notifiers';

import {
  communicationChannelsData,
  filterData,
  selectFilterData,
  sendFileData,
  uploadFileData,
  userTypeData,
} from './data';
import { validateFilterType, validateTextarea } from './utils';

import {
  Container,
  DividingLine,
  FormNotice,
  InputContainer,
  Sections,
  SectionsGroup,
  SectionsItem,
  SendFileContainer,
  TextArea,
  UserContainer,
} from './styles';

const FormNotification: React.FC = () => {
  const [userType, setUserType] = useState('client');
  const [filters, setFilters] = useState({
    ALL: false,
    WAITING: false,
    IN_DAY: false,
    OVERDUE: false,
    STOP: false,
    LEAD: false,
  });

  const [communicationChannel, setCommunicationChannel] = useState({
    whatsapp: true,
    email: false,
    push: false,
  });

  const [filterType, setFilterType] = useState('');
  const [filterTypeValue, setFilterTypeValue] = useState('');

  const [sendFile, setSendFile] = useState('SEND_BEFORE');

  const [uploadFile, setUploadFile] = useState('no');

  const [isLoading, setIsLoading] = useState(false);

  const [file, setFile] = useState<FileList>();

  const [fileLength, setFileLength] = useState<any>(0);

  const [sendSubject, setSendSubject] = useState('');

  const [pushTitle, setPushTitle] = useState('');

  const [sendMessage, setSendMessage] = useState('');

  const [spanColorState, setSpanColorState] = useState('lime');

  const handleUserType = (event: React.FormEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value },
    } = event;
    setUserType(value);
  };

  const handleFilter = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, checked } = event.currentTarget;
    if (name === 'ALL' && checked) {
      setFilters({
        ALL: true,
        WAITING: false,
        IN_DAY: false,
        OVERDUE: false,
        STOP: false,
        LEAD: false,
      });
    } else {
      setFilters({
        ...filters,
        ALL: false,
        [name]: checked,
      });
    }
  };

  const handleCommunication = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, checked } = event.currentTarget;
    setCommunicationChannel({
      ...communicationChannel,
      [name]: checked,
    });
  };

  const handleSendFile = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSendFile(value);
  };

  const handleUploadFile = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setUploadFile(value);
  };

  const handleFilterType = (event: React.FormEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    setFilterType(value);
  };

  const handleFilterTypeValue = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setFilterTypeValue(value);
  };

  const handleSetFile = (event: React.FormEvent<HTMLInputElement>) => {
    // const newFile = event.currentTarget.files?.[0];
    const newFiles = event.currentTarget.files;
    const newFilesLength = event.currentTarget.files?.length;

    setFileLength(newFilesLength);
    if (newFiles) {
      setFile(newFiles);
    }
  };

  const handleSendMessage = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    handleSelectTextAreaSpanColor();
    setSendMessage(value);
  };

  const handleSendSubject = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSendSubject(value);
  };

  const handlePushTitle = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setPushTitle(value);
  };

  const handleSelectTextAreaSpanColor = (): void => {
    const messageLength = sendMessage.length;
    const condition = 65000 - messageLength;

    if (condition > 42900) {
      setSpanColorState('lime');
    } else if (condition > 21450) {
      setSpanColorState('yellow');
    } else {
      setSpanColorState('red');
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      validateFilterType(filterType, filterTypeValue);
      validateTextarea(sendMessage);
      const notificationFormData = new FormData();
      const keys = Object.entries(filters);

      const selectedFilter = keys.reduce((acc, curr) => {
        let placeholder = acc;
        if (curr[1] && acc) placeholder = `${acc},${curr[0]}`;
        else if (curr[1] && !acc) placeholder += curr[0];
        return placeholder;
      }, '');

      const notificationData = {
        status: selectedFilter,
        method: sendFile,
        file_uploaded: uploadFile,
        channels: communicationChannel,
        user_filter_type: filterTypeValue,
        message: sendMessage,
        subject: sendSubject,
        pushTitle,
      };

      if (file) {
        for (let index = 0; index < file.length; index++) {
          notificationFormData.append('attachment_file', file[index]);
        }
      }
      notificationFormData.append('data', JSON.stringify(notificationData));

      const {
        data: {
          header: { total },
        },
      } = await apiRebox.post(
        `/notifications/communicate?role=${userType}`,
        notificationFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      toastify(`${total} usuários foram comunicados com sucesso!`, 'success');
    } catch (err: any) {
      toastify(err.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Container>
        <SubtitleSecondary textAlign="start" nameColor="black" fontSize={18}>
          Gestor de notificações
        </SubtitleSecondary>
        <Paragraph
          nameColor="black"
          textAlign="start"
          opacity={0.5}
          fontSize={15}
          style={{ marginBottom: '2vh' }}
        >
          Aqui notificamos todos os clientes segundo os critérios definidos
          abaixo.
        </Paragraph>

        <DividingLine />
        <FormNotice onSubmit={handleSubmit}>
          <Sections>
            <SectionsGroup>
              <SectionsItem>
                <SubtitleSecondary
                  textAlign="start"
                  nameColor="black"
                  fontSize={14}
                >
                  Tipo de usuário
                </SubtitleSecondary>
                <UserContainer>
                  <InputRadio
                    defaultValue={userType}
                    onChange={handleUserType}
                    options={userTypeData}
                    row={true}
                  />
                </UserContainer>
              </SectionsItem>
              <SectionsItem>
                <SubtitleSecondary
                  textAlign="start"
                  nameColor="black"
                  fontSize={14}
                >
                  Tipos de filtros
                </SubtitleSecondary>
                <Paragraph
                  nameColor="black"
                  textAlign="start"
                  opacity={0.5}
                  fontSize={13}
                  style={{ marginBottom: '2vh' }}
                >
                  Escolha um ou mais métodos para filtrar os usúarios
                </Paragraph>
                {filterData.map(filter => (
                  <InputCheckBox
                    key={filter.label}
                    label={
                      <Paragraph
                        nameColor="black"
                        textAlign="start"
                        style={{ fontWeight: 500 }}
                      >
                        {filter.label}
                      </Paragraph>
                    }
                    name={filter.value}
                    onChange={handleFilter}
                    defaultChecked={false}
                    style={{ textAlign: 'start' }}
                    disabled={filters.ALL === true && filter.value !== 'ALL'}
                  />
                ))}
              </SectionsItem>
              <SectionsItem>
                <SubtitleSecondary
                  textAlign="start"
                  nameColor="black"
                  fontSize={14}
                >
                  Canais de comunicação
                </SubtitleSecondary>
                <Paragraph
                  nameColor="black"
                  textAlign="start"
                  opacity={0.5}
                  fontSize={13}
                  style={{ marginBottom: '2vh' }}
                >
                  Escolha um ou mais meios de comunicação
                </Paragraph>
                {communicationChannelsData.map(filter => (
                  <InputCheckBox
                    key={filter.label}
                    label={
                      <Paragraph
                        nameColor="black"
                        textAlign="start"
                        style={{ fontWeight: 500 }}
                      >
                        {filter.label}
                      </Paragraph>
                    }
                    name={filter.value}
                    onChange={handleCommunication}
                    defaultChecked={filter.value === 'whatsapp'}
                    style={{ textAlign: 'start' }}
                  />
                ))}
              </SectionsItem>
              <SectionsItem
                style={{
                  opacity: 0.5,
                  pointerEvents: 'none',
                }}
              >
                <SubtitleSecondary
                  textAlign="start"
                  nameColor="black"
                  fontSize={14}
                >
                  Enviar arquivo
                </SubtitleSecondary>
                <UserContainer>
                  <InputRadio
                    defaultValue={uploadFile}
                    onChange={handleUploadFile}
                    options={uploadFileData}
                    row={true}
                  />
                </UserContainer>
              </SectionsItem>
              <SectionsItem
                style={{
                  opacity: 0.5,
                  pointerEvents: 'none',
                }}
              >
                <SubtitleSecondary
                  textAlign="start"
                  nameColor="black"
                  fontSize={14}
                >
                  Quando
                </SubtitleSecondary>
                <UserContainer>
                  <InputRadio
                    defaultValue={sendFile}
                    onChange={handleSendFile}
                    options={sendFileData}
                    row={true}
                  />
                </UserContainer>
              </SectionsItem>
            </SectionsGroup>
          </Sections>
          <Sections>
            <SectionsGroup>
              <InputContainer>
                <h4>Tipo de filtragem para usuário</h4>
                <InputSelect
                  placeholder="Selecione o parametro de filtragem"
                  name="filter-select"
                  options={selectFilterData}
                  onChange={handleFilterType}
                  disabled={filters.ALL}
                  style={{
                    cursor: `${filters.ALL ? 'not-allowed' : 'pointer'}`,
                    opacity: `${filters.ALL ? 0.5 : 1}`,
                  }}
                />
              </InputContainer>
              {filterType === 'NAME' && !filters.ALL && (
                <InputText
                  name="name"
                  placeholder="Insira o nome"
                  onChange={handleFilterTypeValue}
                />
              )}
              {filterType === 'CPF' && !filters.ALL && (
                <InputMask
                  name="cpf"
                  placeholder="Insira o CPF"
                  mask="999.999.999-99"
                  onChange={handleFilterTypeValue}
                />
              )}
              {filterType === 'CNPJ' && !filters.ALL && (
                <InputMask
                  name="cnpj"
                  placeholder="Insira o CNPJ"
                  mask="99.999.999/9999-99"
                  onChange={handleFilterTypeValue}
                />
              )}
              {filterType === 'PHONE' && !filters.ALL && (
                <InputMask
                  name="phone"
                  placeholder="Insira o telefone"
                  mask="(99) 99999-9999"
                  onChange={handleFilterTypeValue}
                />
              )}
              <SendFileContainer
                style={{
                  opacity: communicationChannel.email ? 1 : 0.5,
                  cursor: communicationChannel.email
                    ? 'pointer'
                    : 'not-allowed',
                }}
              >
                <span>Enviar arquivo</span>
                <input
                  type="file"
                  name="attachment_file"
                  multiple
                  onChange={handleSetFile}
                  disabled={!communicationChannel.email}
                />
                {fileLength === 1 ? (
                  <p>{file?.[0].name}</p>
                ) : (
                  <p>
                    {fileLength === 0
                      ? 'Nenhum arquivo selecionado.'
                      : `${fileLength} arquivos.`}
                  </p>
                )}
              </SendFileContainer>
              {communicationChannel.email && (
                <InputContainer>
                  <h4>Assunto</h4>
                  <InputText
                    name="subject"
                    placeholder="Insira o assunto para o email"
                    onChange={handleSendSubject}
                  />
                </InputContainer>
              )}

              {communicationChannel.push && (
                <InputContainer>
                  <h4>Título da Notificação Push</h4>
                  <InputText
                    name="subject"
                    placeholder="Insira o titulo da notificação"
                    onChange={handlePushTitle}
                  />
                </InputContainer>
              )}

              {/* <InputContainer>
                <h4>Tipo de template</h4>
                <InputSelect
                  disabled
                  placeholder="Selecione o tipo de template"
                  name="template"
                  options={selectTemplateData}
                  style={{ cursor: 'not-allowed', opacity: 0.3 }}
                />
              </InputContainer> */}
              <InputContainer>
                <h4>Mensagem</h4>
                <TextArea spanColor={spanColorState}>
                  <textarea
                    name="textarea"
                    maxLength={65000}
                    onChange={handleSendMessage}
                  />
                  <span>{65000 - sendMessage.length}</span>
                </TextArea>
              </InputContainer>
              <ButtonMain
                type="submit"
                style={{
                  width: 200,
                }}
                loading={isLoading}
              >
                Enviar
              </ButtonMain>
            </SectionsGroup>
          </Sections>
        </FormNotice>
      </Container>
    </div>
  );
};

export default FormNotification;
