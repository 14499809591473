/* eslint-disable react/no-array-index-key */
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Margin } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import { formatText } from '@utils/formatters';

import { Modal } from '..';

import Manual from '../../../assets/Manual do Cliente Rebox.pdf';
import { Desc } from '../plan/Styles';
import {
  Benefit,
  BenefitsTitle,
  BenefitsWrapper,
  Button,
  Container,
  Footer,
  Header,
  Price,
  PriceDesc,
  Title,
  Wrapper,
  WrapperPrice,
  WrapperTitle,
} from './Styles';

interface ProductItem {
  id: string;
  products_id: string;
  type: string;
  description: string;
  differential: boolean;
}

interface Props {
  show: boolean;
  title: string;
  numberOfYearUtilizations: string;
  numberOfMonthUtilizations: string;
  vehicleType: string;
  year: string;
  grace: string;
  price: string;
  items: ProductItem[];
  url: string;
  productTitle?: string;
  onClose: () => void;
}

interface BenefitsProps {
  title: string;
  benefitNumber: string;
  items: ProductItem[];
  forYou?: boolean;
  productTitle?: string;
}

interface ItemProps {
  text: string;
  numberOfYearUtilizations: string;
  forYou?: boolean;
}
declare global {
  interface Window {
    dataLayer: any;
  }
}

const Item: React.FC<ItemProps> = ({ text, numberOfYearUtilizations }) => {
  return (
    <Desc
      style={{
        borderBottom: '1px solid rgba(221,221,221,.63)',
        textAlign: 'left',
      }}
    >
      <FontAwesomeIcon
        icon={faCircleCheck}
        color="#00df1e"
        style={{ marginRight: '0.5rem', fontSize: '1.125rem' }}
      />
      {/* {text} */}
      {formatText.capitalizedFirstLetter(
        text.startsWith(numberOfYearUtilizations)
          ? text.split(numberOfYearUtilizations)?.[1]?.trim() || ''
          : text,
      )}
    </Desc>
  );
};

const Benefits: React.FC<BenefitsProps> = ({
  title,
  benefitNumber,
  items,
  productTitle,
}) => {
  return (
    <BenefitsWrapper>
      {productTitle?.toLowerCase().includes('tracker') && (
        <>
          <span className="benefitSeparator">
            <BenefitsTitle>Rastreador veicular</BenefitsTitle>
          </span>
          <div className="list">
            {items
              .filter(item => item.type === 'FOR_TRACKER')
              .map(item => (
                <Benefit key={item.id}>
                  <Item
                    text={item.description}
                    numberOfYearUtilizations={benefitNumber}
                  />
                </Benefit>
              ))}
          </div>
        </>
      )}
      {items.filter(item => item.type === 'FOR_VEHICLE').length ? (
        <>
          <span className="benefitSeparator">
            <BenefitsTitle>{title}</BenefitsTitle>
          </span>
          <div className="list">
            {items
              .filter(item => item.type === 'FOR_VEHICLE')
              .map(item => (
                <Benefit key={item.id}>
                  <Item
                    text={item.description}
                    numberOfYearUtilizations={benefitNumber}
                  />
                </Benefit>
              ))}
          </div>
        </>
      ) : null}
    </BenefitsWrapper>
  );
};

const PlanModal: React.FC<Props> = ({
  show,
  title,
  numberOfYearUtilizations,
  numberOfMonthUtilizations,
  vehicleType,
  year,
  grace,
  price,
  items,
  url,
  onClose,
}) => {
  const typeVehicle: { [index: string]: string } = {
    car: 'Carro de passeio',
    tricycle: 'Moto',
    suv: 'Utilitário',
  };

  return (
    <Modal show={show} onClose={onClose} width="628px" height="100vh">
      <Container>
        <Title>{title}</Title>
        <Header>
          <span className="headerTitle">REGRAS DE UTILIZAÇÃO:</span>
          {title?.toLowerCase().includes('tracker') ? (
            <div style={{ minWidth: '85%' }}>
              <div className="headerDescWrapper">
                <span className="headerLabel">Taxa de instalação: </span>
                <span className="headerDesc">R$100,00</span>
              </div>
              <div className="headerDescWrapper">
                <span className="headerLabel">Aparelho em comodato: </span>
                <span className="headerDesc">Sim</span>
              </div>
              <div className="headerDescWrapper">
                <span className="headerLabel">1ª mensalidade:</span>
                <span className="headerDesc">
                  {' '}
                  30 dias, após instalação do rastreador
                </span>
              </div>
              <div className="headerDescWrapper">
                <span className="headerLabel">
                  Instalação na residência do cliente
                </span>
              </div>
              {Number(numberOfYearUtilizations) > 0 && (
                <div style={{ justifyContent: 'center' }}>
                  <div className="headerDescWrapper">
                    <span className="headerLabel">
                      Núm. de assistência 24h no ano:{' '}
                    </span>
                    <span className="headerDesc">
                      {numberOfYearUtilizations}
                    </span>
                  </div>
                  <div className="headerDescWrapper">
                    <span className="headerLabel">
                      Núm. de assistência 24h no mês:{' '}
                    </span>
                    <span className="headerDesc">
                      {numberOfMonthUtilizations}
                    </span>
                    <span className="headerLabel">
                      {' '}
                      com intervalo de 15 dias
                    </span>
                  </div>
                  <div className="headerDescWrapper">
                    <span className="headerLabel">Tipo de veículo: </span>
                    <span className="headerDesc">
                      {typeVehicle[vehicleType.toLowerCase()]}
                    </span>
                  </div>
                  <div className="headerDescWrapper">
                    <span className="headerLabel">Ano: </span>
                    <span className="headerDesc">{year}</span>
                  </div>
                  <div className="headerDescWrapper">
                    <span className="headerLabel">
                      Carência(assistência 24h):{' '}
                    </span>
                    <span className="headerDesc">
                      30 dias após instalação do rastreador
                    </span>
                  </div>
                  <div className="headerDescWrapper">
                    <span className="headerLabel">
                      {' '}
                      Proibido remoção intermunicipal até o segundo acionamento.
                    </span>
                  </div>
                  <div className="headerDescWrapper">
                    <span className="headerLabel"> Km do reboque:</span>
                    <span className="headerDesc"> soma-se ida e volta.</span>
                  </div>
                  <div className="headerDescWrapper">
                    <span className="headerLabel">
                      {' '}
                      Não fazemos planos para veículo quebrado
                    </span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div style={{ padding: '0 20px' }}>
              {Number(numberOfYearUtilizations) > 0 && (
                <div className="headerDescWrapper">
                  <span className="headerLabel">Utilizações no ano: </span>
                  <span className="headerDesc">{numberOfYearUtilizations}</span>
                </div>
              )}
              <div className="headerDescWrapper">
                <span className="headerLabel">Utilizações no mês: </span>
                <span className="headerDesc">{numberOfMonthUtilizations}</span>
                <span className="headerLabel"> com intervalo de 15 dias</span>
              </div>
              <div className="headerDescWrapper">
                <span className="headerLabel">Tipo de veículo: </span>
                <span className="headerDesc">
                  {typeVehicle[vehicleType.toLowerCase()]}
                </span>
              </div>
              <div className="headerDescWrapper">
                <span className="headerLabel">Ano: </span>
                <span className="headerDesc">{year}</span>
              </div>
              <div className="headerDescWrapper">
                <span className="headerLabel">Carência: </span>
                <span className="headerDesc">{grace}</span>
              </div>
              <div className="headerDescWrapper">
                <span className="headerLabel">
                  {' '}
                  Proibido remoção intermunicipal até o segundo acionamento.
                </span>
              </div>
            </div>
          )}
        </Header>
        <Wrapper>
          <WrapperTitle>Nunca foi tão barato cuidar do seu carro!</WrapperTitle>
          <WrapperPrice>
            <Price>{price}</Price>
            <PriceDesc>Por mês</PriceDesc>
          </WrapperPrice>
          <Benefits
            items={items}
            productTitle={title}
            benefitNumber={numberOfYearUtilizations.toString()}
            title={typeVehicle[vehicleType.toLowerCase()]}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              marginTop: '1rem',
            }}
          >
            <Link to={Manual} target="_blank">
              Manual do Cliente
            </Link>
            <label htmlFor="test" style={{ display: 'flex', gap: '10px' }}>
              <input type="checkbox" name="test" id="test" />
              Li e concordo com os Termos do Manual do Cliente
            </label>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={(): Window => window.open(url, '_blank') as Window}
            >
              CLIQUE AQUI E CONTRATE AGORA
            </Button>
            <img
              src="https://rebox.com.br/planos/wp-content/uploads/2020/12/egex_meios_de_pagamento_fundo_transparente-300x34.png"
              alt=""
              style={{ marginBottom: '1rem', width: 274 }}
              loading="lazy"
            />
            <img
              width={164}
              src="https://rebox.com.br/planos/wp-content/uploads/2020/12/rebox_site_seguro-300x76.png"
              alt=""
              loading="lazy"
            />
          </div>
          <Footer>
            <span className="footerText">Ficou alguma dúvida?</span>
            <span className="footerText">
              Clique no WhatsApp e fale com algum atendente
            </span>
            <span className="footerText">ou Ligue grátis: 0800 100 1100</span>
          </Footer>
        </Wrapper>
      </Container>
    </Modal>
  );
};

export default PlanModal;
