import logotipo from '@assets/images/logotipo.svg';
import ButtonMain from '@components/buttons/ButtonMain';

import Burger from './burger/Burger';
import { Container, Logo } from './Styles';

const Navbar: React.FC = () => {
  return (
    <Container>
      <Logo src={logotipo} loading="lazy" />
      <Burger />
    </Container>
  );
};

export default Navbar;
