// ./src/config/routes.ts
export default {
  rebox: {
    defaults: {
      source: '/',
      returnBase: '/login',
      mainRedirect: '/painel',
    },
    privates: {
      dashboad: {
        path: '/dashboard',
        routeFragment: '/dashboard',
        next: {},
      },
      tools: {
        path: '/ferramentas',
        routeFragment: '/ferramentas',
        next: {
          products: {
            path: '/ferramentas/produtos',
            routeFragment: '/produtos',
            next: {},
          },
          services: {
            path: '/ferramentas/servicos',
            routeFragment: '/servicos',
            next: {},
          },
          communication: {
            path: '/ferramentas/comunicacao',
            routeFragment: '/comunicacao',
            next: {},
          },
          notifications: {
            path: '/ferramentas/notificacoes',
            routeFragment: '/notificacoes',
            next: {},
          },
        },
      },
      externalServices: {
        path: '/servicos-externos',
        routeFragment: '/servicos-externos',
        next: {
          handChat: {
            path: '/servicos-externos/hand-chat',
            routeFragment: '/servicos-externos/hand-chat',
            next: {},
          },
        },
      },
      financial: {
        path: '/financeiro',
        routeFragment: '/cobrancas',
        next: {
          charges: {
            path: '/financeiro/cobrancas',
            routeFragment: '/cobrancas',
            next: {
              show: {
                path: '/financeiro/cobrancas/:id',
                routeFragment: '/:id',
                next: {},
              },
            },
          },
          commissions: {
            path: '/financeiro/comissoes',
            routeFragment: '/comissoes',
            next: {},
          },
          calledProviders: {
            path: '/financeiro/cobranca-prestadores',
            routeFragment: '/cobranca-prestadores',
            next: {},
          },
        },
      },
      sales: {
        path: '/vendas',
        routeFragment: '/vendas',
        next: {
          contracts: {
            path: '/vendas/contratos',
            routeFragment: '/contratos',
            next: {
              show: {
                path: '/vendas/contratos/:id',
                routeFragment: '/:id',
                next: {},
              },
            },
          },
          new: {
            path: '/vendas/nova',
            routeFragment: '/nova',
            next: {},
          },
        },
      },
      panel: {
        path: '/painel',
        routeFragment: '/painel',
        next: {},
      },
      users: {
        path: '/usuarios',
        routeFragment: '/usuarios',
        next: {
          customers: {
            path: '/usuarios/clientes',
            routeFragment: '/clientes',
            next: {
              show: {
                path: '/usuarios/clientes/:id',
                routeFragment: '/:id',
              },
            },
          },
          affiliates: {
            path: '/usuarios/afiliados',
            routeFragment: '/afiliados',
            next: {
              show: {
                path: '/usuarios/afiliados/:id',
                routeFragment: '/:id',
              },
            },
          },
          providers: {
            path: '/usuarios/prestadores',
            routeFragment: '/prestadores',
            next: {
              new: {
                path: '/usuarios/prestadores/novo',
                routeFragment: '/prestadores',
              },
              show: {
                path: '/usuarios/prestadores/:id',
                routeFragment: '/:id',
              },
            },
          },
          marketplace: {
            path: '/usuarios/marketplace',
            routeFragment: '/marketplace',
            next: {
              show: {
                path: '/usuarios/marketplace/:id',
                routeFragment: '/:id',
              },
            },
          },
          promoters: {
            path: '/usuarios/promoters',
            routeFragment: '/promoters',
            next: {
              show: {
                path: '/usuarios/promoters/:id',
                routeFragment: '/:id',
              },
            },
          },
        },
      },
      admin: {
        path: '/admin',
        routeFragment: '/admin',
        next: {
          users: {
            path: '/usuarios',
            routeFragment: '/usuarios',
            next: {
              customers: {
                path: '/usuarios/clientes',
                routeFragment: '/clientes',
                next: {
                  show: {
                    path: '/usuarios/clientes/:id',
                    routeFragment: '/:id',
                  },
                },
              },
            },
          },
        },
      },
      profile: {
        path: '/perfil',
        routeFragment: '/perfil',
        next: {},
      },
      // contract: {
      //   path: '/contratos',
      //   routeFragment: '/contratos',
      //   next: {
      //     show: {
      //       path: '/contratos/:id',
      //       routeFragment: '/:id',
      //       next: {},
      //     },
      //     new: {
      //       path: '/contratos/novo',
      //       routeFragment: '/novo',
      //       next: {},
      //     },
      //   },
      // },
      called: {
        path: '/chamados',
        routeFragment: '/chamados',
        next: {
          new: {
            path: '/chamados/novo',
            routeFragment: '/novo',
            next: {},
          },
          show: {
            path: '/chamados/:id',
            routeFragment: '/:id',
            next: {},
          },
          drives: {
            path: '/chamados/acionamentos',
            routeFragment: '/acionamentos',
            next: {
              show: {
                path: '/chamados/acionamentos/:id',
                routeFragment: '/:id',
                next: {},
              },
            },
          },
          instal: {
            path: '/chamados/instalacoes',
            routeFragment: '/instalacoes',
            next: {
              show: {
                path: '/chamados/instalacoes/:id',
                routeFragment: '/:id',
                next: {},
              },
            },
          },
        },
      },
      help: {
        path: '/ajuda',
        routeFragment: '/ajuda',
        next: {},
      },
      notification: {
        path: '/notificacoes',
        routeFragment: '/notificacoes',
        next: {},
      },
      setting: {
        path: '/configuracoes',
        routeFragment: '/configuracoes',
        next: {},
      },
      vehicles: {
        path: '/veiculos',
        routeFragment: '/veiculos',
        next: {
          show: {
            path: '/veiculos/:id',
            routeFragment: '/:id',
            next: {},
          },
        },
      },
    },
    publics: {
      landingPage: {
        path: '/',
        routeFragment: '/',
        next: {
          home: {
            path: '/inicio',
            routeFragment: '/inicio',
            next: {},
          },
          promo: {
            path: '/promocao',
            routeFragment: '/promocao',
            next: {},
          },
        },
      },
      signIn: {
        path: '/login',
        routeFragment: '/login',
        next: {},
      },
      recoverPassword: {
        path: '/esqueci-minha-senha',
        routeFragment: '/esqueci-minha-senha',
        next: {},
      },
      about: {
        path: '/quem-somos',
        routeFragment: '/quem-somos',
        next: {},
      },
      plan: {
        path: '/planos',
        routeFragment: '/planos',
        next: {},
      },
      privacyPolicy: {
        path: '/politica-de-privacidade',
        routeFragment: '/politica-de-privacidade',
        next: {},
      },
      clientManual: {
        path: '/manual-do-cliente',
        routeFragment: '/manual-do-cliente',
        next: {},
      },
      register: {
        path: '/cadastro',
        routeFragment: '/cadastro',
        next: {},
      },
      checkout: {
        path: '/checkout',
        routeFragment: '/checkout',
        next: {
          thanks: {
            path: '/checkout/obrigado',
            routeFragment: '/obrigado',
            next: {},
          },
        },
      },
      debts: {
        path: '/boleto',
        routeFragment: '/boleto',
        next: {},
      },
      assistance: {
        path: '/socorro',
        routeFragment: '/socorro',
        next: {},
      },
    },
  },
};
