import React, { useEffect, useState } from 'react';

import LoadingList from '@components/loadings/LoadingList';
import Paragraph from '@components/texts/Paragraph';
import ProviderServices from '@models/ProviderServices';
import { formatText } from '@utils/formatters';

import Item from './Item';

import { Container, Items, Labels, NothingFound, Table } from './styles';

interface IProps {
  providerServices: ProviderServices[] | undefined;
  loading: boolean;
  showTotal: boolean;
  totalValue: number | undefined;
}

const ListProviderServices: React.FC<IProps> = ({
  providerServices,
  loading,
  showTotal,
  totalValue,
}) => {
  const [is24Hour, setIs24Hour] = useState<boolean | undefined>(false);

  useEffect(() => {
    setIs24Hour(
      providerServices?.every(
        providerService => providerService.hour_type === '24_hour',
      ),
    );
  }, []);
  return (
    <Container>
      <Table>
        <Labels>
          <Paragraph nameColor="black" textAlign="start" fontSize={12}>
            Tipo de serviço
          </Paragraph>
          <Paragraph nameColor="black" textAlign="start" fontSize={12}>
            Tipo de horário
          </Paragraph>
          {!is24Hour && (
            <>
              <Paragraph nameColor="black" textAlign="start" fontSize={12}>
                Dia útil
              </Paragraph>
              <Paragraph nameColor="black" textAlign="start" fontSize={12}>
                Sábado
              </Paragraph>
              <Paragraph nameColor="black" textAlign="start" fontSize={12}>
                Domingo
              </Paragraph>
            </>
          )}
          <Paragraph nameColor="black" textAlign="start" fontSize={12}>
            Saida até 40 KM
          </Paragraph>
          <Paragraph nameColor="black" textAlign="start" fontSize={12}>
            Preço por KM excedido
          </Paragraph>
          <Paragraph nameColor="black" textAlign="start" fontSize={12}>
            Preço por hora parada
          </Paragraph>
          <Paragraph nameColor="black" textAlign="start" fontSize={12}>
            Preço por hora trabalhada
          </Paragraph>
        </Labels>
        <Items>
          {loading ? (
            <LoadingList loading={loading} />
          ) : (
            <>
              {providerServices?.length === 0 ? (
                <NothingFound>
                  <Paragraph>Nenhum serviço foi encontrado.</Paragraph>
                </NothingFound>
              ) : (
                <>
                  {providerServices?.map(providerService => (
                    <Item
                      key={providerService.id}
                      providerService={providerService}
                      is24Hour={is24Hour}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </Items>
        {!loading && showTotal && (
          <Paragraph
            nameColor="black"
            textAlign="end"
            opacity={0.6}
            fontSize={12}
            style={{ margin: '1.5vh 0 0.5vh', fontWeight: 500 }}
          >
            {totalValue === 1
              ? '1 encontrado'
              : `${formatText.numberSeparatedByThousand(
                  totalValue || 0,
                )} encontrados`}
          </Paragraph>
        )}
      </Table>
    </Container>
  );
};

export default ListProviderServices;
