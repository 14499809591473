// ./src/pages/privates/Contract/New/StepPayment/index.tsx
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { FormHandles } from '@unform/core';
import axios from 'axios';
import { BsCreditCard } from 'react-icons/bs';
import { FaDollarSign, FaLock, FaRegClock } from 'react-icons/fa';
import { FiPercent } from 'react-icons/fi';
import {
  IoBarcodeOutline,
  IoCalendarOutline,
  IoCardOutline,
  IoCheckmarkCircle,
  IoClose,
  IoCloseCircle,
  IoLockClosed,
  IoWarning,
} from 'react-icons/io5';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import {
  SubtitleSecondary,
  Paragraph,
  ButtonMain,
  ButtonDefault,
  CardSimpleButtonSelectable,
  InputSelect,
  AlertSimpleCustom,
  InputText,
  InputMask,
  TitleMain,
} from '@components/index';
import { InputDate } from '@components/inputs/InputDate';
import {
  ConfigAuth,
  ConfigLabel,
  ConfigRoutes,
  ConfigRules,
  ConfigStorage,
  ConfigStyles,
  ConfigTransition,
  ConfigValues,
} from '@config/index';
import DiscountCoupons from '@models/DiscountCoupons';
import Product from '@models/Product';
import User from '@models/User';
import {
  apiRebox,
  newContractStorageService,
  sessionStorageService,
} from '@services/index';
// import '@services/integrations/iugu/iuguCreditCardTokenService';
import { getValidationErrors } from '@utils/errors';
import { formatMoney, formatText } from '@utils/formatters';
import { hotToast, toastify } from '@utils/notifiers';

import { schema } from './schemaValidation';
import { IFormStepPayment, ISelect } from './typing';

import {
  Container,
  DividingLine,
  SectionsPayment,
  FormPayment,
  PersonalInformation,
  PersonalInformationGroup,
  DueDate,
  DueDateGroup,
  PaymentMethod,
  PaymentMethodOptions,
  PaymentMethodGroup,
  PaymentMethodGroupFields,
  SectionsGroup,
  SectionsItem,
  SectionsItemGroup,
  Discount,
  DiscountButton,
  DiscountGroup,
  DiscountFields,
  DiscountItem,
  DiscountItemGroup,
  DiscountInformation,
  UpsellModal,
  Buttons,
} from './styles';

interface ServerResponse {
  data: any;
}

interface IProps {
  myStep: number;
  currentStep: number;
  changeStep(newStep: number, willChangeHistory?: boolean): void;
  calculatedPriceForFlex: number;
  calculatedPriceForTracker: number;
  carAge: number;
}

const LIMIT_MAX_INSTALLMENTS = 12;

const StepPayment: React.FC<IProps> = ({
  myStep,
  currentStep,
  changeStep,
  calculatedPriceForFlex,
  calculatedPriceForTracker,
  carAge,
}) => {
  Iugu.setAccountID(ConfigAuth.iugu.keys.accountId);
  const { push } = useHistory();

  const formRef = useRef<FormHandles>(null);
  const paymentMethodRef = useRef<HTMLDivElement>(null);

  const paymentSaved = useMemo(
    () => newContractStorageService.getPayment(),
    [],
  );

  const [hotToastId, setHotToastId] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDueDate, setSelectedDueDate] = useState<string>(
    paymentSaved.due_date || ConfigValues.rebox.contract.due_date.five,
  );
  const [selectedFormOfPayment, setSelectedFormOfPayment] = useState<string>(
    paymentSaved.form_of_payment ||
      ConfigValues.rebox.contract.form_of_payment.pix,
  );
  const [brandCreditCard, setBrandCreditCard] = useState<string>('');
  const [alertCreditCard, setAlertCreditCard] = useState<number>(0);
  const [alertValidate, setAlertValidate] = useState<number>(0);
  const [alertCvv, setAlertCvv] = useState<number>(0);

  const [personType, setPersonType] = useState<string>(
    ConfigValues.rebox.user.person_type.physical_person,
  );

  const [ownershipType, setOwnershipType] = useState<string>(
    ConfigValues.rebox.payments.card_holder_types.same_ownership,
  );

  const [isProductFlexible, setIsProductFlexible] = useState(false);

  const [alertCheckCpfCnpj, setAlertCheckCpfCnpj] = useState<number>(0);
  const [product, setProduct] = useState<Product[]>([]);
  const [installmentOptions, setInstallmentOptions] = useState<ISelect[]>([]);

  const [discountCoupon, setDiscountCoupon] = useState<DiscountCoupons>(
    newContractStorageService.getDiscountCoupons(),
  );
  const [showDiscount, setShowDiscount] = useState<boolean>(
    !!newContractStorageService.getDiscountCoupons().id,
  );
  const [alertDiscount, setAlertDiscount] = useState<number>(0);

  const [isOpenUpsellModal, setIsOpenUpsellModal] = useState<boolean>(false);

  const [upsellAccept, setUpsellAccept] = useState<boolean>(false);

  const [
    enabledFieldsDiscountNumber,
    setEnabledFieldsDiscountNumber,
  ] = useState<boolean>(true);
  const [
    enabledFieldsDiscountAmount,
    setEnabledFieldsDiscountAmount,
  ] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const [selectedHour, setSelectedHour] = useState<string>('');

  const [countInstallations, setCountInstallations] = useState<number>(5);

  const [
    countInstallationsPerCycle,
    setCountInstallationsPerCycle,
  ] = useState<number>(3);

  const [whoGaveDiscountId, setWhoGaveDiscountId] = useState<string>('');

  const [lockEndButton, setLockEndButton] = useState<boolean>(false);

  const initialData = useMemo(() => {
    const { code } = newContractStorageService.getDiscountCoupons();
    return {
      discount_coupom_code: code || '',
    };
  }, []);

  const getProduct = useCallback(async () => {
    try {
      const products = newContractStorageService.getProduct();

      const responseData = [];
      for await (const selectedProduct of products) {
        responseData.push(apiRebox.get(`/products/${selectedProduct.id}`));
      }
      if (responseData) {
        const response = await Promise.all(responseData);
        for (const {
          data: { data: productResponse },
        } of response) {
          const uniqueProds = products.map(prod =>
            prod.id === productResponse.id ? productResponse : prod,
          );
          setProduct(uniqueProds);
        }
      }
    } catch (error) {
      console.log(
        `Passo "Pagamento": Houve um error ao buscar o produto selecionado.`,
      );
    }
  }, []);

  const getDiscountCoupon = useCallback(
    async (discountCouponCode: string) => {
      try {
        if (hotToastId) hotToast(hotToastId, 'dismiss');
        setHotToastId(hotToast('Aplicando cupom de desconto', 'loading'));
        const { data: responseDiscountCoupon } = await apiRebox.get(
          `/coupons?code=${discountCouponCode}`,
        );
        const [firstCoupon] = responseDiscountCoupon.data || [''];

        if (firstCoupon && firstCoupon.id) {
          newContractStorageService.updateDiscountCoupons(firstCoupon);
          setDiscountCoupon(firstCoupon);
          setAlertDiscount(1);
        } else {
          setAlertDiscount(2);
        }
      } catch (error) {
        setAlertDiscount(2);
      } finally {
        hotToast(hotToastId, 'dismiss');
        setHotToastId(null);
      }
    },
    [hotToastId],
  );

  const changeDiscountType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    const { discount_type } = ConfigValues.rebox.contract;

    setEnabledFieldsDiscountNumber(false);
    setEnabledFieldsDiscountAmount(false);
    const discountData = {
      discount_amount_installments: 'R$ 0,00',
      number_installments_with_discount: 0,
    };

    if (value === discount_type.undefined) {
      setEnabledFieldsDiscountNumber(true);
      setEnabledFieldsDiscountAmount(true);
    }

    if (value === discount_type.single) {
      discountData.number_installments_with_discount = 1;
      setEnabledFieldsDiscountNumber(true);
    }

    if (value === discount_type.recurrent) {
      discountData.number_installments_with_discount = LIMIT_MAX_INSTALLMENTS;
    }

    formRef.current?.setData(discountData);
  };

  const getCustomer = async (user_id: string): Promise<User> => {
    const {
      data: { data: customerData },
    } = await apiRebox.get(`/users/${user_id}`);
    return customerData;
  };

  // const changeCreditCardNumber = useCallback((numberCard: string) => {
  //   const numberCardUpdated = formatText.removeAllNonDigits(numberCard);
  //   const isNumberCardValid: boolean = Iugu.utils.validateCreditCardNumber(
  //     numberCardUpdated,
  //   );
  //   setAlertCreditCard(isNumberCardValid ? 1 : 2);
  //   const brandCard: string = Iugu.utils.getBrandByCreditCardNumber(
  //     numberCardUpdated,
  //   );
  //   setBrandCreditCard(brandCard);
  // }, []);

  // const changeCreditCardValidate = useCallback((validate: string) => {
  //   const [month, year] = validate.split('/');
  //   const isValidateValid: boolean = Iugu.utils.validateExpiration(month, year);
  //   setAlertValidate(isValidateValid ? 1 : 2);
  // }, []);

  // const changeCreditCardCvv = useCallback(
  //   (cvv: string) => {
  //     const isCvvValid: boolean = Iugu.utils.validateCVV(cvv, brandCreditCard);
  //     setAlertCvv(isCvvValid ? 1 : 2);
  //   },
  //   [brandCreditCard],
  // );

  // const generateInstallmentOptions = useCallback(() => {
  //   // const {
  //   //   promotional_price,
  //   //   current_price,
  //   //   coverage_months_limit,
  //   // } = product[0];
  //   const amount = promotional_price || current_price || 0;

  //   const installments: ISelect[] = [];
  //   for (let index = 0; index < coverage_months_limit; index++) {
  //     const installmentCurrent = index + 1;
  //     let installmentValue = amount * coverage_months_limit;
  //     installmentValue /= installmentCurrent;
  //     installments.push({
  //       label: `${installmentCurrent}x de ${formatMoney.fromNumberToPrice(
  //         installmentValue,
  //       )} sem juros`,
  //       value: `${installmentCurrent}`,
  //     });
  //   }
  //   setInstallmentOptions(installments);
  // }, [product]);

  const handleGetCustomerByCpfCnpj = useCallback(
    async (cpfCnpj: string) => {
      const typeDoc =
        personType === ConfigValues.rebox.user.person_type.physical_person
          ? 'CPF'
          : 'CNPJ';
      if (hotToastId) hotToast(hotToastId, 'dismiss');
      setHotToastId(hotToast(`Verificando ${typeDoc}`, 'loading'));
      try {
        //   const { data: responseCustomer } = await apiRebox.get(
        //     `/users?query=${formatText.removeAllNonDigits(cpfCnpj)}`,
        //   );
        //   const { header } = responseCustomer;
        //   if (header.total > 0) {
        //     hotToast(idHotToast, 'dismiss');
        //     toastify(
        //       `Este ${typeDoc} não pode ser usado. Já pertence a um usuário cadastrado.`,
        //       'error',
        //     );
        //     setAlertCheckCpfCnpj(2);
        //     return;
        //   }
        //   setAlertCheckCpfCnpj(1);

        // Resolvendo um bug provisório (Código comentado acima é o definitivo)
        const {
          admin,
          client,
          provider,
          partner,
          assistant,
        } = ConfigValues.rebox.user.role;
        const { data: responseAdmin } = await apiRebox.get(
          `/users?role=${admin}&query=${formatText.removeAllNonDigits(
            cpfCnpj,
          )}`,
        );
        if (responseAdmin.header.total > 0) {
          hotToast(hotToastId, 'dismiss');
          setHotToastId(null);
          toastify(
            `Este ${typeDoc} não pode ser usado. Já pertence a um usuário cadastrado.`,
            'error',
          );
          setAlertCheckCpfCnpj(2);
          return;
        }

        const { data: responseProvider } = await apiRebox.get(
          `/users?role=${provider}&query=${formatText.removeAllNonDigits(
            cpfCnpj,
          )}`,
        );
        if (responseProvider.header.total > 0) {
          hotToast(hotToastId, 'dismiss');
          setHotToastId(null);
          toastify(
            `Este ${typeDoc} não pode ser usado. Já pertence a um usuário cadastrado.`,
            'error',
          );
          setAlertCheckCpfCnpj(2);
          return;
        }

        const { data: responsePartner } = await apiRebox.get(
          `/users?role=${partner}&query=${formatText.removeAllNonDigits(
            cpfCnpj,
          )}`,
        );

        if (responsePartner.header.total > 0) {
          hotToast(hotToastId, 'dismiss');
          setHotToastId(null);
          toastify(
            `Este ${typeDoc} não pode ser usado. Já pertence a um usuário cadastrado.`,
            'error',
          );
          setAlertCheckCpfCnpj(2);
          return;
        }

        const { data: responseAssistant } = await apiRebox.get(
          `/users?role=${assistant}&query=${formatText.removeAllNonDigits(
            cpfCnpj,
          )}`,
        );

        if (responseAssistant.header.total > 0) {
          hotToast(hotToastId, 'dismiss');
          setHotToastId(null);
          toastify(
            `Este ${typeDoc} não pode ser usado. Já pertence a um usuário cadastrado.`,
            'error',
          );
          setAlertCheckCpfCnpj(2);
          return;
        }

        const { data: responseCustomer } = await apiRebox.get(
          `/users?role=${client}&query=${formatText.removeAllNonDigits(
            cpfCnpj,
          )}`,
        );

        if (responseCustomer.header.total > 0) {
          hotToast(hotToastId, 'dismiss');
          setHotToastId(null);
          toastify(
            `Este ${typeDoc} não pode ser usado. Já pertence a um usuário cadastrado.`,
            'error',
          );
          setAlertCheckCpfCnpj(2);
          return;
        }

        setAlertCheckCpfCnpj(1);
      } catch (error: any) {
        toastify(
          error.response
            ? error.response.data.error
            : `Houve um error ao tentar verificar o ${typeDoc} informado.`,
          'error',
        );
        setAlertCheckCpfCnpj(2);
      } finally {
        hotToast(hotToastId, 'dismiss');
        setHotToastId(null);
      }
    },
    [personType],
  );

  const tokenizeCard = async (data: IFormStepPayment) => {
    const {
      data: { data: tokenizedCard },
    } = await apiRebox.post('/pagarme/tokenize', {
      holder_name: data.name,
      holder_document: data.holder_document
        ? formatText.removeAllNonDigits(data.holder_document)
        : formatText.removeAllNonDigits(data.cpf_cnpj),
      number: data.number_card?.replaceAll(' ', ''),
      exp_month: data.validity?.split('/')[0],
      exp_year: data.validity?.split('/')[1],
      cvv: data.cvv,
    });

    return tokenizedCard.id;
  };

  const sentDataContract = useCallback(
    async (
      data: IFormStepPayment,
      token?: string,
      calculatedPrice?: number,
    ) => {
      setLoading(true);
      const products = newContractStorageService.getProduct();
      const vehicles = newContractStorageService.getVehicle();
      const env = sessionStorage.getItem('@Rebox:User:environment');
      const savedData = localStorage.getItem('selected-date');
      const savedHour = localStorage.getItem('selected-hour');
      const savedClassification = localStorage.getItem(
        'classification_vehicle_type',
      );
      for await (const [index, key] of products.entries()) {
        await apiRebox.post(`/contracts`, {
          users_id: newContractStorageService.getCustomer().id,
          users_person_type: data.person_type,
          users_cpf_cnpj: data.cpf_cnpj,
          products_id: key.id,
          available_uses: null,
          covered_up: null,
          status: ConfigValues.rebox.contract.status.pending,
          renew_in_days: null,
          form_of_payment: selectedFormOfPayment,
          due_date: selectedDueDate,
          cycle: ConfigValues.rebox.contract.cycle.monthly,
          amount: isProductFlexible ? calculatedPrice : null,
          due_date_if_plan_tracker: savedData || null,
          cycle_time_for_installation: savedHour || null,
          installments: LIMIT_MAX_INSTALLMENTS,
          discount_type: whoGaveDiscountId
            ? ConfigValues.rebox.contract.contract_discount_type.percentage
            : ConfigValues.rebox.contract.contract_discount_type.undefined,
          discount_amount_installments:
            savedData && env?.toLowerCase() === 'marketing' ? 5 : null,
          number_installments_with_discount:
            savedData && env?.toLowerCase() === 'marketing' ? 12 : 0,
          who_gave_discount_type:
            whoGaveDiscountId !== ''
              ? ConfigValues.rebox.contract.who_gave_discount_type.partner
              : ConfigValues.rebox.contract.who_gave_discount_type.undefined,
          who_gave_discount_id: whoGaveDiscountId || null,
          who_made_the_sale_id: null,
          insured_vehicles: [
            {
              id: vehicles[index].id,
            },
          ],
          discount_coupons_id: discountCoupon.id || null,
          card_token: token,
          card:
            selectedFormOfPayment ===
            ConfigValues.rebox.contract.form_of_payment.credit_card
              ? {
                  type: selectedFormOfPayment,
                  printed_name: data.name,
                  document: data.holder_document,
                  number: formatText.removeAllNonDigits(data.number_card || ''),
                  validity: data.validity,
                  ccv: data.cvv,
                }
              : null,
          classification_for_flexible_plan:
            savedClassification && JSON.parse(savedClassification),
        });
        toastify('Uhuuu! Sua compra foi efetuada.', 'success');
        newContractStorageService.cleanMany('all');
        localStorage.clear();
        changeStep(currentStep + 1);
        push(ConfigRoutes.rebox.publics.checkout.next.thanks.path);
        hotToast(hotToastId, 'dismiss');
        setHotToastId(null);
        setLoading(prevState => !prevState);
      }
    },
    [
      selectedFormOfPayment,
      selectedDueDate,
      brandCreditCard,
      discountCoupon,
      hotToastId,
      selectedDate,
    ],
  );

  const handleRegisterSale = useCallback(
    async (data: IFormStepPayment) => {
      try {
        setLockEndButton(true);
        if (hotToastId) hotToast(hotToastId, 'dismiss');
        setHotToastId(hotToast('Aguarde um instante', 'loading'));
        setLoading(prevState => !prevState);
        formRef.current?.setErrors({});

        await schema.validate(data, {
          abortEarly: false,
        });

        if (
          selectedFormOfPayment ===
          ConfigValues.rebox.contract.form_of_payment.credit_card
        ) {
          const { id } = newContractStorageService.getCustomer();

          // const user = await getCustomer(id);

          if (!data.number_card)
            throw new Error(
              'Por favor, informe o número do cartão de crédito.',
            );

          if (!data.name)
            throw new Error(
              'Por favor, informe o nome impresso no cartão de crédito.',
            );

          // if (firstName !== customerFirstName) {
          //   throw new Error(
          //     'O nome do titular do cartão precisa ser idêntico ao nome cadastrado.',
          //   );
          // }

          if (!data.validity)
            throw new Error(
              'Por favor, informe a data de válidade do cartão de crédito.',
            );

          if (!data.cvv)
            throw new Error(
              'Por favor, informe o código de segurança do cartão de crédito.',
            );

          if (
            ownershipType ===
              ConfigValues.rebox.payments.card_holder_types
                .different_ownership &&
            !data.holder_document
          )
            throw new Error(
              'Por favor, informe o documento do dono do cartão de crédito.',
            );

          const card_token = await tokenizeCard(data);

          if (!card_token) {
            throw new Error('Não foi possível validar o cartão.');
          }

          await sentDataContract(data, card_token, calculatedPriceForFlex);
        } else {
          // Boleto ou Pix
          const isTrackerPlan = localStorage.getItem('is_tracker_plan');
          if (isTrackerPlan === 'true') {
            const savedData = localStorage.getItem('selected-date');
            if (!savedData) {
              throw new Error('Por favor, insira uma data antes de finalizar');
            }
          }

          await sentDataContract(
            data,
            undefined,
            isTrackerPlan === 'true'
              ? calculatedPriceForTracker
              : calculatedPriceForFlex,
          );
        }
      } catch (error: any) {
        setLoading(false);
        setLockEndButton(false);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);

          const { number_installments, person_type, cpf_cnpj } = errors;

          if (number_installments) toastify(number_installments, 'error');
          if (person_type) toastify(person_type, 'error');
          if (cpf_cnpj) toastify(cpf_cnpj, 'error');
        } else if (error.response) {
          console.log(error.response);
          toastify(error.response.data.error, 'error');
        } else {
          console.log(error);
          toastify(
            typeof error.message === 'string'
              ? error.message
              : 'Sinto muito, houve um erro ao finalizar a compra.',
            'error',
          );
        }
      }
    },
    [selectedFormOfPayment, selectedDueDate, brandCreditCard, hotToastId],
  );

  // useEffect(() => {
  //   generateInstallmentOptions();
  // }, [product]);

  // useEffect(() => {
  //   paymentMethodRef.current?.scrollIntoView();
  // }, [selectedDueDate]);

  const verifyProductsId = () => {
    const products = newContractStorageService.getProduct();
    const productsIdForUpsell = [
      '767e6f03-e998-49db-a72b-105c9bbf1153',
      '3da14632-6725-4d7c-ac80-0d2e58c2e42f',
    ];
    const mappedIds = products.map(prod => prod.id);
    if (
      mappedIds.includes(productsIdForUpsell[0]) ||
      mappedIds.includes(productsIdForUpsell[1])
    ) {
      return true;
    }
    return false;
  };

  const isProductTracker = () => {
    const productTracker: boolean = product.some(prod =>
      prod.name?.includes('tracker'),
    );
    return productTracker;
  };

  const handleSelectedDate = (date: Date | null) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    getProduct();
    const { code } = newContractStorageService.getDiscountCoupons();
    if (code) getDiscountCoupon(code);

    const userLogged = sessionStorageService.getUser();
    const customerSale = newContractStorageService.getCustomer();
    if (userLogged && customerSale && userLogged.id === customerSale.id) {
      formRef.current?.setData({
        cpf_cnpj: userLogged.cpf || userLogged.cnpj || '',
      });
      setPersonType(userLogged.person_type || '');
    }
    const savedWhoGaveDiscountId = sessionStorage.getItem(
      ConfigStorage.REBOX_USER_WHO_GAVE_DISCOUNT_ID,
    );
    if (savedWhoGaveDiscountId) setWhoGaveDiscountId(savedWhoGaveDiscountId);
  }, []);

  useEffect(() => {
    if (selectedDate) {
      const dueDateSelected = selectedDate?.toISOString().split('T')[0];
      localStorage.setItem('selected-hour', selectedHour);
      localStorage.setItem('selected-date', dueDateSelected);
    }
  }, [selectedDate, selectedHour]);

  useEffect(() => {
    setTimeout(() => {
      if (hotToastId) hotToast(hotToastId, 'dismiss');
      hotToast(hotToastId, 'dismiss');
    }, 5000);
  }, [hotToastId]);

  useEffect(() => {
    const isProductFlexibleSaved = localStorage.getItem('is_flexible_product');
    if (isProductFlexibleSaved)
      setIsProductFlexible(JSON.parse(isProductFlexibleSaved));
  }, []);

  const thirtyCupom = sessionStorage.getItem('@Rebox:User:environment');

  return (
    <Container>
      {isProductFlexible && (
        <>
          <SubtitleSecondary textAlign="start" nameColor="black">
            Resumo do Plano
          </SubtitleSecondary>
          <Paragraph
            textAlign="start"
            nameColor="black"
            opacity={0.8}
            fontWeight={500}
          >
            Visualize o resumo do plano
          </Paragraph>
          <SectionsPayment>
            <PersonalInformation>
              <SubtitleSecondary textAlign="start" fontSize={16}>
                {`Valor do plano (mensal): ${
                  isProductTracker()
                    ? formatMoney.fromNumberToPrice(calculatedPriceForTracker)
                    : formatMoney.fromNumberToPrice(calculatedPriceForFlex)
                }`}
              </SubtitleSecondary>
              <SubtitleSecondary textAlign="start" fontSize={16}>
                {`Idade do veículo: ${carAge} anos`}
              </SubtitleSecondary>
            </PersonalInformation>
          </SectionsPayment>
          <SubtitleSecondary textAlign="start" nameColor="black">
            Pagamento
          </SubtitleSecondary>

          <Paragraph
            textAlign="start"
            nameColor="black"
            opacity={0.8}
            fontWeight={500}
          >
            Visualize o resumo de pagamento
          </Paragraph>
        </>
      )}

      <SectionsPayment>
        <FormPayment
          ref={formRef}
          onSubmit={handleRegisterSale}
          initialData={initialData}
          className="form-123"
        >
          <PersonalInformation>
            <SubtitleSecondary textAlign="start" fontSize={16}>
              Informe mais alguns dados
            </SubtitleSecondary>
            <PersonalInformationGroup>
              <SectionsGroup>
                <SectionsItem>
                  <SubtitleSecondary
                    textAlign="start"
                    nameColor="black"
                    fontSize={14}
                  >
                    Gerar contrato para
                  </SubtitleSecondary>
                  <Paragraph
                    nameColor="black"
                    textAlign="start"
                    opacity={0.5}
                    fontSize={13}
                    style={{ marginBottom: '2vh' }}
                  >
                    Define o documento exigido para registro
                  </Paragraph>
                  <InputSelect
                    name="person_type"
                    options={ConfigLabel.rebox.others.user.person_type}
                    placeholder="Selecione"
                    selectedDefault={personType}
                    tabIndex={3}
                    onChange={event => setPersonType(event.target.value)}
                  />
                </SectionsItem>

                <SectionsItem>
                  <SubtitleSecondary
                    textAlign="start"
                    nameColor="black"
                    fontSize={14}
                  >
                    CPF/CNPJ
                  </SubtitleSecondary>
                  <Paragraph
                    nameColor="black"
                    textAlign="start"
                    opacity={0.5}
                    fontSize={13}
                    style={{ marginBottom: '2vh' }}
                  >
                    Informe o número do documento do cliente
                  </Paragraph>
                  <SectionsItemGroup>
                    <InputMask
                      autoFocus={true}
                      name="cpf_cnpj"
                      mask={
                        personType ===
                        ConfigValues.rebox.user.person_type.physical_person
                          ? '999.999.999-99'
                          : '99.999.999/9999-99'
                      }
                      placeholder="Número do documento"
                      tabIndex={4}
                      onChange={event => {
                        const cpfCnpj = formatText.removeAllNonDigits(
                          event.target.value,
                        );
                        if (cpfCnpj.length === 11 || cpfCnpj.length === 14) {
                          handleGetCustomerByCpfCnpj(cpfCnpj);
                        } else if (alertCheckCpfCnpj !== 0) {
                          setAlertCheckCpfCnpj(0);
                        }
                      }}
                    />
                    {alertCheckCpfCnpj === 1 && (
                      <IoCheckmarkCircle
                        color={ConfigStyles.rebox.colors.greenEmerald.main}
                        size={20}
                      />
                    )}
                    {alertCheckCpfCnpj === 2 && (
                      <IoCloseCircle
                        color={ConfigStyles.rebox.colors.red.main}
                        size={20}
                      />
                    )}
                  </SectionsItemGroup>
                </SectionsItem>
              </SectionsGroup>
            </PersonalInformationGroup>
          </PersonalInformation>
          {/* <AlertSimpleCustom
            text={`Estamos considerando por padrão o ciclo de cobrança "mensal".`}
            type="info"
          /> */}

          {isProductTracker() ? (
            <PaymentMethod ref={paymentMethodRef}>
              <SubtitleSecondary textAlign="start" fontSize={16}>
                Forma de pagamento
              </SubtitleSecondary>

              <PaymentMethodOptions>
                {[
                  ConfigValues.rebox.contract.form_of_payment.pix,
                  // ConfigValues.rebox.contract.form_of_payment.credit_card,
                  ConfigValues.rebox.contract.form_of_payment.boleto,
                ].map(item => (
                  <>
                    <CardSimpleButtonSelectable
                      key={item}
                      icon={{
                        element:
                          ConfigValues.rebox.contract.form_of_payment.boleto ===
                          item
                            ? IoBarcodeOutline
                            : ConfigValues.rebox.contract.form_of_payment
                                .pix === item
                            ? FaDollarSign
                            : BsCreditCard,
                        colorDefault:
                          ConfigStyles.rebox.colors.black.opacity.average,
                        colorActive: ConfigStyles.rebox.colors.white.main,
                        opacity: 1,
                        size: 22,
                      }}
                      label={{
                        text:
                          ConfigTransition.rebox_contracts_form_of_payment[
                            item.toLowerCase()
                          ],
                        colorDefault:
                          ConfigStyles.rebox.colors.black.opacity.average,
                        colorActive: ConfigStyles.rebox.colors.white.main,
                        size: ConfigStyles.rebox.fonts.size.paragraph.large,
                      }}
                      width={{ size: '240px', maxSize: '100%' }}
                      onClick={() => setSelectedFormOfPayment(item)}
                      isSelected={selectedFormOfPayment === item}
                      positionContent="flex-start"
                      style={{ marginRight: '2vh' }}
                    />
                  </>
                ))}
              </PaymentMethodOptions>

              <PaymentMethodGroup>
                {selectedFormOfPayment ===
                  ConfigValues.rebox.contract.form_of_payment.boleto && (
                  <>
                    <Paragraph
                      nameColor="black"
                      textAlign="start"
                      fontWeight={600}
                    >
                      Dados do boleto
                    </Paragraph>
                    <Paragraph
                      nameColor="black"
                      textAlign="start"
                      opacity={0.8}
                      style={{ margin: '1.5vh 0 0' }}
                    >
                      {/* Esta compra irá gerar um contrato, que será ativado somente
                    após o pagamento do 1º boleto. A compensação de pagamento
                    ocorre em até{' '}
                    {ConfigRules.rebox.charge.maximumDaysForCompensation} dias
                    úteis. */}
                      Um contrato será gerado, e 30 dias após a instalação do
                      rastreador será gerada a cobrança.
                    </Paragraph>
                    <Paragraph
                      nameColor="orange"
                      textAlign="start"
                      fontSize={ConfigStyles.rebox.fonts.size.paragraph.large}
                      fontWeight={600}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor:
                          ConfigStyles.rebox.colors.orange.opacity.veryLow,
                        padding: '2vh 2vw',
                        marginTop: '1.5vh',
                      }}
                    >
                      <IoWarning
                        color={ConfigStyles.rebox.colors.orange.main}
                        size={16}
                        style={{ marginRight: 8 }}
                      />
                      O local de instalação será o endereço indicado na
                      contratação desse plano!
                    </Paragraph>
                    {/* <AlertSimpleCustom
                    text="Nossas cobranças são recorrentes. Iremos enviar o boleto no valor da parcela que escolher abaixo, sempre no dia do vencimento."
                    type="warning"
                  /> */}

                    <DueDate>
                      <Paragraph
                        nameColor="black"
                        textAlign="start"
                        fontWeight={600}
                      >
                        Selecione a data de instalação
                      </Paragraph>
                      <InputDate
                        handleSelectedDate={handleSelectedDate}
                        name="due_date_if_plan_tracker"
                        value={selectedDate}
                      />
                    </DueDate>
                  </>
                )}

                {selectedFormOfPayment ===
                  ConfigValues.rebox.contract.form_of_payment.credit_card && (
                  <>
                    <Paragraph
                      nameColor="black"
                      textAlign="start"
                      fontWeight={600}
                      opacity={1}
                      style={{ margin: '1.5vh 0 2vh' }}
                    >
                      Um contrato será gerado, e 30 dias após a instalação do
                      rastreador será gerada a cobrança.
                    </Paragraph>

                    <Paragraph
                      nameColor="greenEmerald"
                      textAlign="start"
                      fontSize={ConfigStyles.rebox.fonts.size.paragraph.large}
                      fontWeight={600}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor:
                          ConfigStyles.rebox.colors.greenEmerald.opacity
                            .veryLow,
                        padding: '2vh 2vw',
                        marginTop: '1.5vh',
                      }}
                    >
                      <FaLock
                        color={ConfigStyles.rebox.colors.greenEmerald.main}
                        size={16}
                        style={{ marginRight: 8 }}
                      />
                      Você está em um ambiente seguro!
                    </Paragraph>

                    <SectionsGroup>
                      <SectionsItem>
                        <SubtitleSecondary
                          textAlign="start"
                          nameColor="black"
                          fontSize={14}
                        >
                          Titularidade do cartão
                        </SubtitleSecondary>
                        <Paragraph
                          nameColor="black"
                          textAlign="start"
                          opacity={0.5}
                          fontSize={13}
                          style={{ marginBottom: '2vh' }}
                        >
                          Informe se você é o titular do cartão
                        </Paragraph>
                        <InputSelect
                          name="ownership_type"
                          options={ConfigLabel.rebox.others.payment.holder_type}
                          placeholder="Selecione"
                          selectedDefault={
                            ConfigValues.rebox.payments.card_holder_types
                              .same_ownership
                          }
                          tabIndex={3}
                          onChange={event =>
                            setOwnershipType(event.target.value)
                          }
                        />
                      </SectionsItem>

                      {ownershipType ===
                        ConfigValues.rebox.payments.card_holder_types
                          .different_ownership && (
                        <SectionsItem>
                          <SubtitleSecondary
                            textAlign="start"
                            nameColor="black"
                            fontSize={14}
                          >
                            CPF/CNPJ
                          </SubtitleSecondary>
                          <Paragraph
                            nameColor="black"
                            textAlign="start"
                            opacity={0.5}
                            fontSize={13}
                            style={{ marginBottom: '2vh' }}
                          >
                            Informe o número de documento do dono do cartão.
                          </Paragraph>
                          <SectionsItemGroup>
                            <InputMask
                              autoFocus={true}
                              name="document"
                              mask={
                                personType ===
                                ConfigValues.rebox.user.person_type
                                  .physical_person
                                  ? '999.999.999-99'
                                  : '99.999.999/9999-99'
                              }
                              placeholder="Número do documento"
                              tabIndex={4}
                            />
                          </SectionsItemGroup>
                        </SectionsItem>
                      )}
                    </SectionsGroup>

                    <SectionsGroup>
                      <SectionsItem>
                        <SubtitleSecondary
                          textAlign="start"
                          nameColor="black"
                          fontSize={14}
                        >
                          Número do cartão
                        </SubtitleSecondary>
                        <Paragraph
                          nameColor="black"
                          textAlign="start"
                          opacity={0.7}
                          fontSize={13}
                          style={{ marginBottom: '2vh' }}
                        >
                          Informe o número impresso em seu cartão de crédito
                        </Paragraph>

                        <SectionsItemGroup>
                          <InputMask
                            name="number_card"
                            placeholder="XXXX XXXX XXXX XXXX"
                            alertVisible
                            mask="9999 9999 9999 9999"
                            onChange={event => {
                              const cardNumber = formatText.removeAllNonDigits(
                                event.target.value,
                              );
                              if (cardNumber.length === 16) {
                                // changeCreditCardNumber(cardNumber);
                              } else {
                                setAlertCreditCard(0);
                              }
                            }}
                          />
                          {alertCreditCard === 1 && (
                            <IoCheckmarkCircle
                              color={
                                ConfigStyles.rebox.colors.greenEmerald.main
                              }
                              size={20}
                            />
                          )}
                          {alertCreditCard === 2 && (
                            <IoCloseCircle
                              color={ConfigStyles.rebox.colors.red.main}
                              size={20}
                            />
                          )}
                        </SectionsItemGroup>
                      </SectionsItem>

                      <SectionsItem>
                        <SubtitleSecondary
                          textAlign="start"
                          nameColor="black"
                          fontSize={14}
                        >
                          Nome no cartão
                        </SubtitleSecondary>
                        <Paragraph
                          nameColor="black"
                          textAlign="start"
                          opacity={0.7}
                          fontSize={13}
                          style={{ marginBottom: '2vh' }}
                        >
                          Informe o nome conforme impresso no cartão de crédito
                        </Paragraph>
                        <InputText
                          name="name"
                          placeholder="Nome impresso no cartão"
                          style={{ textTransform: 'uppercase' }}
                          alertVisible
                        />
                      </SectionsItem>
                    </SectionsGroup>

                    <SectionsGroup>
                      <SectionsItem>
                        <SubtitleSecondary
                          textAlign="start"
                          nameColor="black"
                          fontSize={14}
                        >
                          Data de validade
                        </SubtitleSecondary>
                        <Paragraph
                          nameColor="black"
                          textAlign="start"
                          opacity={0.7}
                          fontSize={13}
                          style={{ marginBottom: '2vh' }}
                        >
                          Informe o mês e o ano de validade
                        </Paragraph>
                        <SectionsItemGroup>
                          <InputMask
                            name="validity"
                            alertVisible
                            placeholder="MM/AAAA"
                            mask="99/2099"
                            onChange={event => {
                              const validity = formatText.removeAllNonDigits(
                                event.target.value,
                              );
                              if (validity.length === 4) {
                                // changeCreditCardValidate(event.target.value);
                              } else {
                                setAlertValidate(0);
                              }
                            }}
                          />
                          {alertValidate === 1 && (
                            <IoCheckmarkCircle
                              color={
                                ConfigStyles.rebox.colors.greenEmerald.main
                              }
                              size={20}
                            />
                          )}
                          {alertValidate === 2 && (
                            <IoCloseCircle
                              color={ConfigStyles.rebox.colors.red.main}
                              size={20}
                            />
                          )}
                        </SectionsItemGroup>
                      </SectionsItem>

                      <SectionsItem>
                        <SubtitleSecondary
                          textAlign="start"
                          nameColor="black"
                          fontSize={14}
                        >
                          CVV
                        </SubtitleSecondary>
                        <Paragraph
                          nameColor="black"
                          textAlign="start"
                          opacity={0.7}
                          fontSize={13}
                          style={{ marginBottom: '2vh' }}
                        >
                          Informe o código de segurança do seu cartão de crédito
                        </Paragraph>
                        <SectionsItemGroup>
                          <InputText
                            name="cvv"
                            placeholder="CVV"
                            alertVisible
                            maxLength={4}
                            onChange={event => {
                              const creditCardCvv = formatText.removeAllNonDigits(
                                event.target.value,
                              );
                              if (
                                creditCardCvv.length > 2 &&
                                creditCardCvv.length < 5
                              ) {
                                // changeCreditCardCvv(creditCardCvv);
                              } else {
                                setAlertCvv(0);
                              }
                            }}
                          />
                          {alertCvv === 1 && (
                            <IoCheckmarkCircle
                              color={
                                ConfigStyles.rebox.colors.greenEmerald.main
                              }
                              size={20}
                            />
                          )}
                          {alertCvv === 2 && (
                            <IoCloseCircle
                              color={ConfigStyles.rebox.colors.red.main}
                              size={20}
                            />
                          )}
                        </SectionsItemGroup>
                      </SectionsItem>
                    </SectionsGroup>

                    {/* <DividingLine /> */}

                    {/* <AlertSimpleCustom
                    text={`Nossas cobranças são recorrentes. Iremos cobrar apenas o valor da parcela que escolher abaixo, sempre no dia do vencimento, sem comprometer o limite do seu cartão de crédito.`}
                    type="warning"
                  /> */}
                  </>
                )}

                {selectedFormOfPayment ===
                  ConfigValues.rebox.contract.form_of_payment.pix && (
                  <>
                    <Paragraph
                      nameColor="black"
                      textAlign="start"
                      fontWeight={600}
                    >
                      Dados do boleto
                    </Paragraph>
                    <Paragraph
                      nameColor="black"
                      textAlign="start"
                      opacity={0.8}
                      style={{ margin: '1.5vh 0 0' }}
                    >
                      {/* Esta compra irá gerar um contrato, que será ativado somente
                    após o pagamento do 1º boleto. A compensação de pagamento
                    ocorre em até{' '}
                    {ConfigRules.rebox.charge.maximumDaysForCompensation} dias
                    úteis. */}
                      Um contrato será gerado, e 30 dias após a instalação do
                      rastreador será gerada a cobrança.
                    </Paragraph>
                    {/* <AlertSimpleCustom
                    text="Nossas cobranças são recorrentes. Iremos enviar o boleto no valor da parcela que escolher abaixo, sempre no dia do vencimento."
                    type="warning"
                  /> */}
                    <Paragraph
                      nameColor="orange"
                      textAlign="start"
                      fontSize={ConfigStyles.rebox.fonts.size.paragraph.large}
                      fontWeight={600}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor:
                          ConfigStyles.rebox.colors.orange.opacity.veryLow,
                        padding: '2vh 2vw',
                        marginTop: '1.5vh',
                      }}
                    >
                      <IoWarning
                        color={ConfigStyles.rebox.colors.orange.main}
                        size={16}
                        style={{ marginRight: 8 }}
                      />
                      O local de instalação será o endereço indicado na
                      contratação desse plano!
                    </Paragraph>

                    <DueDate>
                      <Paragraph
                        nameColor="black"
                        textAlign="start"
                        fontWeight={600}
                      >
                        Selecione a data de instalação
                      </Paragraph>
                      <InputDate
                        handleSelectedDate={handleSelectedDate}
                        value={selectedDate}
                        name="due_date_if_plan_tracker"
                      />
                    </DueDate>
                  </>
                )}

                {selectedDate && (
                  <SectionsGroup>
                    <SectionsItem
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <SubtitleSecondary
                        textAlign="start"
                        nameColor="black"
                        fontSize={14}
                      >
                        Selecione o horário de instalação
                      </SubtitleSecondary>

                      <div
                        style={{
                          display: 'flex',
                          gap: '0.5em',
                          width: '100%',
                        }}
                      >
                        {ConfigValues.rebox.product.time_installation.cycle_time.array.map(
                          cycle_time => (
                            <DueDate key={cycle_time}>
                              <CardSimpleButtonSelectable
                                style={{
                                  border: '1px solid rgba(0,0,0, .1)',
                                  width: '8em',
                                }}
                                key={0}
                                label={{
                                  text: cycle_time,
                                  colorDefault:
                                    ConfigStyles.rebox.colors.black.opacity
                                      .average,
                                  colorActive:
                                    ConfigStyles.rebox.colors.white.main,
                                  size:
                                    ConfigStyles.rebox.fonts.size.paragraph
                                      .large,
                                }}
                                positionContent="center"
                                isSelected={selectedHour === cycle_time}
                                onClick={() => setSelectedHour(cycle_time)}
                              />
                            </DueDate>
                          ),
                        )}
                      </div>
                    </SectionsItem>
                  </SectionsGroup>
                )}
              </PaymentMethodGroup>
            </PaymentMethod>
          ) : (
            <PaymentMethod ref={paymentMethodRef}>
              <SubtitleSecondary textAlign="start" fontSize={16}>
                Forma de pagamento
              </SubtitleSecondary>
              {thirtyCupom ===
              ConfigValues.rebox.user.environment.thirty_days_cupom ? (
                <PaymentMethodOptions>
                  {[
                    ConfigValues.rebox.contract.form_of_payment.pix,
                    ConfigValues.rebox.contract.form_of_payment.boleto,
                  ].map(item => (
                    <>
                      <CardSimpleButtonSelectable
                        key={item}
                        icon={{
                          element:
                            ConfigValues.rebox.contract.form_of_payment
                              .boleto === item
                              ? IoBarcodeOutline
                              : ConfigValues.rebox.contract.form_of_payment
                                  .pix === item
                              ? FaDollarSign
                              : BsCreditCard,
                          colorDefault:
                            ConfigStyles.rebox.colors.black.opacity.average,
                          colorActive: ConfigStyles.rebox.colors.white.main,
                          opacity: 1,
                          size: 22,
                        }}
                        label={{
                          text:
                            ConfigTransition.rebox_contracts_form_of_payment[
                              item.toLowerCase()
                            ],
                          colorDefault:
                            ConfigStyles.rebox.colors.black.opacity.average,
                          colorActive: ConfigStyles.rebox.colors.white.main,
                          size: ConfigStyles.rebox.fonts.size.paragraph.large,
                        }}
                        width={{ size: '240px', maxSize: '100%' }}
                        onClick={() => setSelectedFormOfPayment(item)}
                        isSelected={selectedFormOfPayment === item}
                        positionContent="flex-start"
                        style={{ marginRight: '2vh' }}
                      />
                    </>
                  ))}
                </PaymentMethodOptions>
              ) : (
                <PaymentMethodOptions>
                  {[
                    ConfigValues.rebox.contract.form_of_payment.pix,
                    ConfigValues.rebox.contract.form_of_payment.credit_card,
                    ConfigValues.rebox.contract.form_of_payment.boleto,
                  ].map(item => (
                    <>
                      <CardSimpleButtonSelectable
                        key={item}
                        icon={{
                          element:
                            ConfigValues.rebox.contract.form_of_payment
                              .boleto === item
                              ? IoBarcodeOutline
                              : ConfigValues.rebox.contract.form_of_payment
                                  .pix === item
                              ? FaDollarSign
                              : BsCreditCard,
                          colorDefault:
                            ConfigStyles.rebox.colors.black.opacity.average,
                          colorActive: ConfigStyles.rebox.colors.white.main,
                          opacity: 1,
                          size: 22,
                        }}
                        label={{
                          text:
                            ConfigTransition.rebox_contracts_form_of_payment[
                              item.toLowerCase()
                            ],
                          colorDefault:
                            ConfigStyles.rebox.colors.black.opacity.average,
                          colorActive: ConfigStyles.rebox.colors.white.main,
                          size: ConfigStyles.rebox.fonts.size.paragraph.large,
                        }}
                        width={{ size: '240px', maxSize: '100%' }}
                        onClick={() => setSelectedFormOfPayment(item)}
                        isSelected={selectedFormOfPayment === item}
                        positionContent="flex-start"
                        style={{ marginRight: '2vh' }}
                      />
                    </>
                  ))}
                </PaymentMethodOptions>
              )}

              <PaymentMethodGroup>
                {selectedFormOfPayment ===
                  ConfigValues.rebox.contract.form_of_payment.boleto && (
                  <>
                    <Paragraph
                      nameColor="black"
                      textAlign="start"
                      fontWeight={600}
                    >
                      Dados do boleto
                    </Paragraph>
                    <Paragraph
                      nameColor="black"
                      textAlign="start"
                      opacity={0.8}
                      style={{ margin: '1.5vh 0 0' }}
                    >
                      {/* Esta compra irá gerar um contrato, que será ativado somente
                      após o pagamento do 1º boleto. A compensação de pagamento
                      ocorre em até{' '}
                      {ConfigRules.rebox.charge.maximumDaysForCompensation} dias
                      úteis. */}
                      Um contrato será gerado, e ativado após pagamento do 1º
                      boleto.
                    </Paragraph>
                    {/* <AlertSimpleCustom
                      text="Nossas cobranças são recorrentes. Iremos enviar o boleto no valor da parcela que escolher abaixo, sempre no dia do vencimento."
                      type="warning"
                    /> */}

                    <DueDate>
                      <Paragraph
                        nameColor="black"
                        textAlign="start"
                        fontWeight={600}
                      >
                        Selecione o vencimento
                      </Paragraph>
                      <DueDateGroup>
                        {ConfigValues.rebox.contract.due_date.array.map(
                          item => (
                            <CardSimpleButtonSelectable
                              style={{ border: '1px solid rgba(0,0,0, .1)' }}
                              key={item}
                              icon={{
                                element: IoCalendarOutline,
                                colorDefault:
                                  ConfigStyles.rebox.colors.black.opacity
                                    .average,
                                colorActive:
                                  ConfigStyles.rebox.colors.white.main,
                                opacity: 1,
                                size: 22,
                              }}
                              label={{
                                text: item,
                                colorDefault:
                                  ConfigStyles.rebox.colors.black.opacity
                                    .average,
                                colorActive:
                                  ConfigStyles.rebox.colors.white.main,
                                size:
                                  ConfigStyles.rebox.fonts.size.paragraph.large,
                              }}
                              positionContent="center"
                              isSelected={selectedDueDate === item}
                              onClick={() => setSelectedDueDate(item)}
                            />
                          ),
                        )}
                      </DueDateGroup>
                    </DueDate>
                  </>
                )}

                {selectedFormOfPayment ===
                  ConfigValues.rebox.contract.form_of_payment.credit_card && (
                  <>
                    <Paragraph
                      nameColor="black"
                      textAlign="start"
                      fontWeight={600}
                      opacity={1}
                      style={{ margin: '1.5vh 0 2vh' }}
                    >
                      Um contrato será gerado e o 1º pagamento realizado
                      automaticamente.
                    </Paragraph>

                    <Paragraph
                      nameColor="greenEmerald"
                      textAlign="start"
                      fontSize={ConfigStyles.rebox.fonts.size.paragraph.large}
                      fontWeight={600}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor:
                          ConfigStyles.rebox.colors.greenEmerald.opacity
                            .veryLow,
                        padding: '2vh 2vw',
                        marginTop: '1.5vh',
                      }}
                    >
                      <FaLock
                        color={ConfigStyles.rebox.colors.greenEmerald.main}
                        size={16}
                        style={{ marginRight: 8 }}
                      />
                      Você está em um ambiente seguro!
                    </Paragraph>

                    <SectionsGroup>
                      <SectionsItem>
                        <SubtitleSecondary
                          textAlign="start"
                          nameColor="black"
                          fontSize={14}
                        >
                          Titularidade do cartão
                        </SubtitleSecondary>
                        <Paragraph
                          nameColor="black"
                          textAlign="start"
                          opacity={0.5}
                          fontSize={13}
                          style={{ marginBottom: '2vh' }}
                        >
                          Informe se você é o titular do cartão
                        </Paragraph>
                        <InputSelect
                          name="ownership_type"
                          options={ConfigLabel.rebox.others.payment.holder_type}
                          placeholder="Selecione"
                          selectedDefault={
                            ConfigValues.rebox.payments.card_holder_types
                              .same_ownership
                          }
                          tabIndex={3}
                          onChange={event =>
                            setOwnershipType(event.target.value)
                          }
                        />
                      </SectionsItem>

                      {ownershipType ===
                        ConfigValues.rebox.payments.card_holder_types
                          .different_ownership && (
                        <SectionsItem>
                          <SubtitleSecondary
                            textAlign="start"
                            nameColor="black"
                            fontSize={14}
                          >
                            CPF/CNPJ
                          </SubtitleSecondary>
                          <Paragraph
                            nameColor="black"
                            textAlign="start"
                            opacity={0.5}
                            fontSize={13}
                            style={{ marginBottom: '2vh' }}
                          >
                            Informe o número de documento do dono do cartão.
                          </Paragraph>
                          <SectionsItemGroup>
                            <InputMask
                              autoFocus={true}
                              name="document"
                              mask={
                                personType ===
                                ConfigValues.rebox.user.person_type
                                  .physical_person
                                  ? '999.999.999-99'
                                  : '99.999.999/9999-99'
                              }
                              placeholder="Número do documento"
                              tabIndex={4}
                            />
                          </SectionsItemGroup>
                        </SectionsItem>
                      )}
                    </SectionsGroup>

                    <SectionsGroup>
                      <SectionsItem>
                        <SubtitleSecondary
                          textAlign="start"
                          nameColor="black"
                          fontSize={14}
                        >
                          Número do cartão
                        </SubtitleSecondary>
                        <Paragraph
                          nameColor="black"
                          textAlign="start"
                          opacity={0.7}
                          fontSize={13}
                          style={{ marginBottom: '2vh' }}
                        >
                          Informe o número impresso em seu cartão de crédito
                        </Paragraph>

                        <SectionsItemGroup>
                          <InputMask
                            name="number_card"
                            placeholder="XXXX XXXX XXXX XXXX"
                            alertVisible
                            mask="9999 9999 9999 9999"
                            onChange={event => {
                              const cardNumber = formatText.removeAllNonDigits(
                                event.target.value,
                              );
                              if (cardNumber.length === 16) {
                                // changeCreditCardNumber(cardNumber);
                              } else {
                                setAlertCreditCard(0);
                              }
                            }}
                          />
                          {alertCreditCard === 1 && (
                            <IoCheckmarkCircle
                              color={
                                ConfigStyles.rebox.colors.greenEmerald.main
                              }
                              size={20}
                            />
                          )}
                          {alertCreditCard === 2 && (
                            <IoCloseCircle
                              color={ConfigStyles.rebox.colors.red.main}
                              size={20}
                            />
                          )}
                        </SectionsItemGroup>
                      </SectionsItem>

                      <SectionsItem>
                        <SubtitleSecondary
                          textAlign="start"
                          nameColor="black"
                          fontSize={14}
                        >
                          Nome no cartão
                        </SubtitleSecondary>
                        <Paragraph
                          nameColor="black"
                          textAlign="start"
                          opacity={0.7}
                          fontSize={13}
                          style={{ marginBottom: '2vh' }}
                        >
                          Informe o nome conforme impresso no cartão de crédito
                        </Paragraph>
                        <InputText
                          name="name"
                          placeholder="Nome impresso no cartão"
                          style={{ textTransform: 'uppercase' }}
                          alertVisible
                        />
                      </SectionsItem>
                    </SectionsGroup>

                    <SectionsGroup>
                      <SectionsItem>
                        <SubtitleSecondary
                          textAlign="start"
                          nameColor="black"
                          fontSize={14}
                        >
                          Data de validade
                        </SubtitleSecondary>
                        <Paragraph
                          nameColor="black"
                          textAlign="start"
                          opacity={0.7}
                          fontSize={13}
                          style={{ marginBottom: '2vh' }}
                        >
                          Informe o mês e o ano de validade
                        </Paragraph>
                        <SectionsItemGroup>
                          <InputMask
                            name="validity"
                            alertVisible
                            placeholder="MM/AAAA"
                            mask="99/2099"
                            onChange={event => {
                              const validity = formatText.removeAllNonDigits(
                                event.target.value,
                              );
                              if (validity.length === 4) {
                                // changeCreditCardValidate(event.target.value);
                              } else {
                                setAlertValidate(0);
                              }
                            }}
                          />
                          {alertValidate === 1 && (
                            <IoCheckmarkCircle
                              color={
                                ConfigStyles.rebox.colors.greenEmerald.main
                              }
                              size={20}
                            />
                          )}
                          {alertValidate === 2 && (
                            <IoCloseCircle
                              color={ConfigStyles.rebox.colors.red.main}
                              size={20}
                            />
                          )}
                        </SectionsItemGroup>
                      </SectionsItem>

                      <SectionsItem>
                        <SubtitleSecondary
                          textAlign="start"
                          nameColor="black"
                          fontSize={14}
                        >
                          CVV
                        </SubtitleSecondary>
                        <Paragraph
                          nameColor="black"
                          textAlign="start"
                          opacity={0.7}
                          fontSize={13}
                          style={{ marginBottom: '2vh' }}
                        >
                          Informe o código de segurança do seu cartão de crédito
                        </Paragraph>
                        <SectionsItemGroup>
                          <InputText
                            name="cvv"
                            placeholder="CVV"
                            alertVisible
                            maxLength={4}
                            onChange={event => {
                              const creditCardCvv = formatText.removeAllNonDigits(
                                event.target.value,
                              );
                              if (
                                creditCardCvv.length > 2 &&
                                creditCardCvv.length < 5
                              ) {
                                // changeCreditCardCvv(creditCardCvv);
                              } else {
                                setAlertCvv(0);
                              }
                            }}
                          />
                          {alertCvv === 1 && (
                            <IoCheckmarkCircle
                              color={
                                ConfigStyles.rebox.colors.greenEmerald.main
                              }
                              size={20}
                            />
                          )}
                          {alertCvv === 2 && (
                            <IoCloseCircle
                              color={ConfigStyles.rebox.colors.red.main}
                              size={20}
                            />
                          )}
                        </SectionsItemGroup>
                      </SectionsItem>
                    </SectionsGroup>

                    {/* <DividingLine /> */}

                    {/* <AlertSimpleCustom
                      text={`Nossas cobranças são recorrentes. Iremos cobrar apenas o valor da parcela que escolher abaixo, sempre no dia do vencimento, sem comprometer o limite do seu cartão de crédito.`}
                      type="warning"
                    /> */}
                  </>
                )}

                {selectedFormOfPayment ===
                  ConfigValues.rebox.contract.form_of_payment.pix && (
                  <>
                    <Paragraph
                      nameColor="black"
                      textAlign="start"
                      fontWeight={600}
                    >
                      Dados do boleto
                    </Paragraph>
                    <Paragraph
                      nameColor="black"
                      textAlign="start"
                      opacity={0.8}
                      style={{ margin: '1.5vh 0 0' }}
                    >
                      {/* Esta compra irá gerar um contrato, que será ativado somente
                      após o pagamento do 1º boleto. A compensação de pagamento
                      ocorre em até{' '}
                      {ConfigRules.rebox.charge.maximumDaysForCompensation} dias
                      úteis. */}
                      Um contrato será gerado, e ativado após pagamento do 1º
                      boleto.
                    </Paragraph>
                    {/* <AlertSimpleCustom
                      text="Nossas cobranças são recorrentes. Iremos enviar o boleto no valor da parcela que escolher abaixo, sempre no dia do vencimento."
                      type="warning"
                    /> */}

                    <DueDate>
                      <Paragraph
                        nameColor="black"
                        textAlign="start"
                        fontWeight={600}
                      >
                        Selecione o vencimento
                      </Paragraph>
                      <DueDateGroup>
                        {ConfigValues.rebox.contract.due_date.array.map(
                          item => (
                            <CardSimpleButtonSelectable
                              style={{ border: '1px solid rgba(0,0,0, .1)' }}
                              key={item}
                              icon={{
                                element: IoCalendarOutline,
                                colorDefault:
                                  ConfigStyles.rebox.colors.black.opacity
                                    .average,
                                colorActive:
                                  ConfigStyles.rebox.colors.white.main,
                                opacity: 1,
                                size: 22,
                              }}
                              label={{
                                text: item,
                                colorDefault:
                                  ConfigStyles.rebox.colors.black.opacity
                                    .average,
                                colorActive:
                                  ConfigStyles.rebox.colors.white.main,
                                size:
                                  ConfigStyles.rebox.fonts.size.paragraph.large,
                              }}
                              positionContent="center"
                              isSelected={selectedDueDate === item}
                              onClick={() => setSelectedDueDate(item)}
                            />
                          ),
                        )}
                      </DueDateGroup>
                    </DueDate>
                  </>
                )}

                <SectionsGroup style={{ display: 'none' }}>
                  <SectionsItem>
                    <SubtitleSecondary
                      textAlign="start"
                      nameColor="black"
                      fontSize={14}
                    >
                      {/* Parcelamento */}
                      Mensalidade
                    </SubtitleSecondary>
                    <Paragraph
                      nameColor="black"
                      textAlign="start"
                      opacity={0.7}
                      fontSize={13}
                      style={{ marginBottom: '2vh' }}
                    >
                      Se desejar, você pode antecipar as cobranças
                    </Paragraph>
                    <InputSelect
                      name="number_installments"
                      options={installmentOptions}
                      placeholder="Selecione"
                      selectedDefault={`${LIMIT_MAX_INSTALLMENTS}`}
                      tabIndex={1}
                      disabled={true}
                      isDisable={true}
                    />
                  </SectionsItem>
                </SectionsGroup>
              </PaymentMethodGroup>
            </PaymentMethod>
          )}
          <Discount>
            <DiscountButton tabIndex={2}>
              <FiPercent
                size={20}
                color={ConfigStyles.rebox.colors.blue.main}
              />
              <SubtitleSecondary textAlign="start" fontSize={16}>
                Cupom de desconto
              </SubtitleSecondary>
            </DiscountButton>
            <DiscountGroup>
              {/* <Paragraph nameColor="black" textAlign="start" fontWeight={500}>
                  Dados de desconto
                </Paragraph>
                <Paragraph
                  nameColor="black"
                  textAlign="start"
                  opacity={0.8}
                  style={{ margin: '1.5vh 0' }}
                >
                  {`Apenas se definir um desconto, que invalida a aplicação automática
                do mesmo segundo critérios pré-definidos.`}
                </Paragraph> */}
              <DiscountFields>
                <DiscountItem>
                  <SubtitleSecondary
                    textAlign="start"
                    nameColor="black"
                    fontSize={14}
                  >
                    Código do cupom
                  </SubtitleSecondary>
                  <Paragraph
                    nameColor="black"
                    textAlign="start"
                    opacity={0.5}
                    fontSize={13}
                    style={{ marginBottom: '2vh' }}
                  >
                    Caso você possua um cupom de desconto
                  </Paragraph>

                  <DiscountItemGroup>
                    <InputText
                      name="discount_coupom_code"
                      placeholder="Digite aqui"
                      onChange={event => {
                        const code = event.target.value;
                        if (code.length > 4) {
                          getDiscountCoupon(code);
                        } else {
                          setAlertDiscount(2);
                        }
                      }}
                    />
                    {alertDiscount === 1 && (
                      <IoCheckmarkCircle
                        color={ConfigStyles.rebox.colors.greenEmerald.main}
                        size={20}
                      />
                    )}
                    {alertDiscount === 2 && (
                      <IoCloseCircle
                        color={ConfigStyles.rebox.colors.red.main}
                        size={20}
                      />
                    )}
                  </DiscountItemGroup>
                </DiscountItem>
              </DiscountFields>

              {discountCoupon.id && alertDiscount === 1 && (
                <DiscountInformation>
                  <SubtitleSecondary textAlign="start" fontSize={16}>
                    Uhuuuuuuuu! Aproveite seu desconto.
                  </SubtitleSecondary>

                  {discountCoupon.type ===
                    ConfigValues.rebox.discount_coupons.type
                      .first_free_tuition && (
                    <>
                      <Paragraph
                        nameColor="black"
                        textAlign="start"
                        fontWeight={600}
                        fontSize={16}
                      >
                        Sua 1ª mensalidade será gratuita!
                      </Paragraph>
                    </>
                  )}
                </DiscountInformation>
              )}
            </DiscountGroup>
          </Discount>
        </FormPayment>
      </SectionsPayment>
      <Buttons>
        <ButtonMain
          loading={loading}
          onClick={() => {
            if (verifyProductsId()) {
              setIsOpenUpsellModal(true);
            } else {
              formRef.current?.submitForm();
            }
          }}
          style={{
            maxWidth: 200,
            pointerEvents:
              alertCheckCpfCnpj === 2 || lockEndButton || loading
                ? 'none'
                : 'auto',
            opacity:
              alertCheckCpfCnpj === 2 || lockEndButton || loading ? 0.5 : 1,
          }}
          disabled={alertCheckCpfCnpj === 2 || lockEndButton}
          isDisable={alertCheckCpfCnpj === 2 || lockEndButton}
        >
          Finalizar
        </ButtonMain>
        <ButtonDefault
          onClick={() => changeStep(currentStep - 1)}
          style={{ maxWidth: 200 }}
          disabled={currentStep <= 1}
          isDisable={currentStep <= 1}
        >
          Voltar
        </ButtonDefault>
      </Buttons>
      <UpsellModal
        isOpen={isOpenUpsellModal}
        ariaHideApp={false}
        onRequestClose={() => setIsOpenUpsellModal(prev => !prev)}
        contentLabel="UpsellModal"
      >
        <ButtonDefault
          iconLeft={IoClose}
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            maxWidth: 50,
            padding: 0,
          }}
          onClick={() => setIsOpenUpsellModal(prev => !prev)}
        />
        <TitleMain
          style={{ marginTop: '2rem' }}
        >{`Deseja mudar para o plano de ${formatMoney.fromNumberToPrice(
          24.9,
        )}?`}</TitleMain>
        <SubtitleSecondary
          nameColor="gray"
          fontSize={15}
          opacity={0.7}
        >{`Pague mais ${formatMoney.fromNumberToPrice(
          5,
        )} por mês e tenha mais 2 acionamentos no ano!`}</SubtitleSecondary>
        <Buttons>
          <ButtonMain
            onClick={() => {
              setIsOpenUpsellModal(prev => !prev);
              setUpsellAccept(true);
              formRef.current?.submitForm();
            }}
          >
            Aceitar
          </ButtonMain>
          <ButtonDefault
            onClick={() => {
              setUpsellAccept(false);
              setIsOpenUpsellModal(prev => !prev);
              formRef.current?.submitForm();
            }}
          >
            Recusar
          </ButtonDefault>
        </Buttons>
      </UpsellModal>
    </Container>
  );
};

export default StepPayment;
