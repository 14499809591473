import React, { useState } from 'react';

import { IoClose } from 'react-icons/io5';

import ButtonDefault from '@components/buttons/ButtonDefault';
import ButtonMain from '@components/buttons/ButtonMain';
import { ICustomerNotes } from '@components/forms/FormCustomerNotes/typing';
import InputText from '@components/inputs/InputText';
import Paragraph from '@components/texts/Paragraph';
import SubtitleSecondary from '@components/texts/SubtitleSecondary';
import { apiRebox } from '@services/index';
import { toastify } from '@utils/notifiers';

import { Container, FormEditCustomerNote, ModalClose } from './styles';

interface IProps {
  isOpen: boolean;
  change(): void;
  note: ICustomerNotes;
  changeRefresh(status: boolean): void;
}

const ModalEditUserNote: React.FC<IProps> = ({
  isOpen,
  change,
  note,
  changeRefresh,
}) => {
  const [noteText, setNoteText] = useState<string>('');

  const handleEditNote = async () => {
    try {
      const { data: responseNote } = await apiRebox.put(
        `/users/note/${note.id}`,
        {
          who_created_id: note.who_created_id,
          note: noteText,
          users_id: note.users_id,
        },
      );
      changeRefresh(true);
      toastify(responseNote.header.message, 'success');
      change();
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <Container>
      <ModalClose
        isOpen={isOpen}
        onRequestClose={change}
        contentLabel="EditNoteText"
      >
        <ButtonDefault
          iconLeft={IoClose}
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            maxWidth: 50,
            padding: 0,
          }}
          onClick={change}
        />
        <FormEditCustomerNote ref={() => []} onSubmit={() => []}>
          <SubtitleSecondary>Editar a anotação</SubtitleSecondary>
          <Paragraph
            nameColor="black"
            opacity={0.5}
            style={{ marginBottom: '2vh' }}
          >
            Adicione uma nota sobre o usuário
          </Paragraph>
          <InputText
            name="noteText"
            placeholder="Insira o novo texto"
            onChange={({ target }) => {
              setNoteText(target.value);
            }}
          />
          <ButtonMain style={{ marginTop: '2vh' }} onClick={handleEditNote}>
            Salvar
          </ButtonMain>
        </FormEditCustomerNote>
      </ModalClose>
    </Container>
  );
};

export default ModalEditUserNote;
