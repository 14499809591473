// src/pages/privates/Tools/index.tsx
import React, { useState, useCallback, useRef } from 'react';

import { FormHandles } from '@unform/core';
import { IoMdDownload } from 'react-icons/io';
import { IoArrowBack, IoClose, IoOptions } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';

import {
  HeaderNavigationPrivate,
  MenuSideBarPrivate,
  SubtitleSecondary,
  CardHorizontalButtonNavigate,
  LoadingList,
  ButtonDefault,
  ButtonOutline,
  Paragraph,
  InputSelect,
  InputMask,
  ButtonMain,
} from '@components/index';
import { ConfigLabel, ConfigStorage, ConfigStyles } from '@config/index';
import { IFilterRescuesFormData } from '@pages/private/Financial/Commision/typing';
import { apiRebox } from '@services/index';
import { formatDate } from '@utils/formatters';
import { generateDate } from '@utils/generators';

import {
  Container,
  ContainerGroup,
  Content,
  FilterButtons,
  FilterFieldGroup,
  FilterFieldSet,
  FormFilter,
  ModalFilter,
  Options,
  OptionsGroup,
} from './styles';

const ExternalServices: React.FC = () => {
  const { goBack } = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenModalFilter, setIsOpenModalFilter] = useState<boolean>(false);
  const [isDisabledFilter, setIsDisabledFilter] = useState<boolean>(false);
  const formFilterRef = useRef<FormHandles>(null);
  const [filter, setFilter] = useState<IFilterRescuesFormData>(
    JSON.parse(
      sessionStorage.getItem(ConfigStorage.REBOX_FILTERS_HAND_CHAT) || '{}',
    ),
  );

  const cleanFilter = useCallback(() => {
    formFilterRef.current?.reset();
    sessionStorage.removeItem(ConfigStorage.REBOX_FILTERS_HAND_CHAT);
    setFilter({} as IFilterRescuesFormData);
    setIsOpenModalFilter(false);
  }, []);

  const handleFilterRescues = useCallback((data: IFilterRescuesFormData) => {
    const allDataFilter: IFilterRescuesFormData = {
      cpfcnpj: data.cpfcnpj === 'UNDEFINED' ? '' : data.cpfcnpj,
      name: data.name === 'UNDEFINED' ? '' : data.name,
      tel: data.tel === 'UNDEFINED' ? '' : data.tel,
      code: data.code === 'UNDEFINED' ? '' : data.code,
      alphabetic: data.alphabetic === 'UNDEFINED' ? '' : data.alphabetic,
      defined_period:
        data.defined_period === 'UNDEFINED' ? '' : data.defined_period,
      period_start: data.period_start
        ? `${formatDate.removeMask(data.period_start)} 00:00`
        : '',
      period_end: data.period_end
        ? `${formatDate.removeMask(data.period_end)} 23:59`
        : '',
      status: data.status === 'UNDEFINED' ? '' : data.status,
    };

    sessionStorage.setItem(
      ConfigStorage.REBOX_FILTERS_HAND_CHAT,
      JSON.stringify(allDataFilter),
    );
    setFilter(allDataFilter);
    setIsOpenModalFilter(false);
  }, []);

  const handleGoBack = () => {
    goBack();
  };

  return (
    <Container>
      <HeaderNavigationPrivate />
      <ContainerGroup>
        <MenuSideBarPrivate />
        <Content>
          <SubtitleSecondary textAlign="start">Hand-Chat</SubtitleSecondary>
          <Options>
            <OptionsGroup>
              <ButtonDefault iconLeft={IoArrowBack} onClick={handleGoBack} />
              <ButtonOutline
                style={{ marginLeft: 10 }}
                iconLeft={IoOptions}
                onClick={() => setIsOpenModalFilter(prevState => !prevState)}
              />
            </OptionsGroup>
          </Options>

          <ModalFilter
            isOpen={isOpenModalFilter}
            onRequestClose={() => setIsOpenModalFilter(prevState => !prevState)}
            contentLabel="FilterCustomer"
          >
            <ButtonDefault
              iconLeft={IoClose}
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
                maxWidth: 50,
                padding: 0,
              }}
              onClick={() => setIsOpenModalFilter(prevState => !prevState)}
            />
            <FormFilter
              ref={formFilterRef}
              onSubmit={handleFilterRescues}
              initialData={{
                ...filter,
                period_start: filter.period_start
                  ? formatDate.addMask(filter.period_start.split(' ')[0])
                  : '',
                period_end: filter.period_end
                  ? formatDate.addMask(filter.period_end.split(' ')[0])
                  : '',
              }}
            >
              <FilterFieldGroup>
                <FilterFieldSet>
                  <Paragraph
                    nameColor="black"
                    textAlign="start"
                    style={{ marginBottom: '1vh', fontWeight: 500 }}
                  >
                    Data inicial
                  </Paragraph>
                  <InputMask
                    name="period_start"
                    mask="99/99/9999"
                    placeholder="Período inicial"
                    isDisable={isDisabledFilter}
                    disabled={isDisabledFilter}
                  />
                </FilterFieldSet>
                <FilterFieldSet>
                  <Paragraph
                    nameColor="black"
                    textAlign="start"
                    style={{ marginBottom: '1vh', fontWeight: 500 }}
                  >
                    Data final
                  </Paragraph>
                  <InputMask
                    name="period_end"
                    mask="99/99/9999"
                    placeholder="Período final"
                    isDisable={isDisabledFilter}
                    disabled={isDisabledFilter}
                  />
                </FilterFieldSet>
              </FilterFieldGroup>
              <FilterButtons>
                <ButtonMain type="submit">Filtrar</ButtonMain>
                <ButtonDefault type="reset" onClick={cleanFilter}>
                  Resetar
                </ButtonDefault>
              </FilterButtons>
            </FormFilter>
          </ModalFilter>

          <CardHorizontalButtonNavigate
            icon={{
              element: IoMdDownload,
              size: 20,
              opacity: 1,
              colorDefault: ConfigStyles.rebox.colors.black.opacity.average,
              colorHover: ConfigStyles.rebox.colors.white.main,
            }}
            label={{
              text: 'Download de planilha',
              colorDefault: ConfigStyles.rebox.colors.black.opacity.average,
              colorHover: ConfigStyles.rebox.colors.blue.main,
            }}
            message={{
              text: 'Baixar planilha de usuários com a tag lead no hand-chat.',
              colorDefault: ConfigStyles.rebox.colors.black.opacity.average,
              colorHover: ConfigStyles.rebox.colors.blue.main,
            }}
            marginBottom="2vh"
            positionContent="center"
            onClick={async () => {
              setIsLoading(true);
              apiRebox
                .get('/tools/zapinho', {
                  responseType: 'blob',
                  params: {
                    ...filter,
                  },
                })
                .then(response => {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data]),
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute(
                    'download',
                    `leads-${generateDate.now().replace(' ', '-')}.xlsx`,
                  );
                  setIsLoading(false);
                  link.click();
                });
            }}
          />
          {isLoading && <LoadingList loading={isLoading} />}
        </Content>
      </ContainerGroup>
    </Container>
  );
};

export default ExternalServices;
