export const filterData = [
  {
    label: 'Todos',
    value: 'ALL',
  },
  {
    label: 'Aguardando',
    value: 'WAITING',
  },
  {
    label: 'Em dia',
    value: 'IN_DAY',
  },
  {
    label: 'Atrasado',
    value: 'OVERDUE',
  },
  {
    label: 'Cancelado',
    value: 'STOP',
  },
  {
    label: 'Lead',
    value: 'LEAD',
  },
];

export const communicationChannelsData = [
  {
    label: 'Whatsapp',
    value: 'whatsapp',
  },
  {
    label: 'E-mail',
    value: 'email',
  },
  {
    label: 'Push (App)',
    value: 'push',
  },
];

export const uploadFileData = [
  {
    label: 'Sim',
    value: 'yes',
  },
  {
    label: 'Não',
    value: 'no',
  },
];

export const sendFileData = [
  {
    label: 'Enviar antes',
    value: 'SEND_BEFORE',
  },
  {
    label: 'Enviar depois',
    value: 'SEND_AFTER',
  },
];

export const userTypeData = [
  {
    label: 'Cliente',
    value: 'client',
  },
  {
    label: 'Afiliado',
    value: 'partner',
  },
  {
    label: 'Prestador',
    value: 'provider',
  },
];

export const selectTemplateData = [
  {
    label: 'Bem-Vindo',
    value: 'WELCOME',
  },
  {
    label: 'Bem-Vindo',
    value: 'WELCOME',
  },
  {
    label: 'Bem-Vindo',
    value: 'WELCOME',
  },
];

export const selectFilterData = [
  {
    label: 'Nome',
    value: 'NAME',
  },
  {
    label: 'CPF',
    value: 'CPF',
  },
  {
    label: 'CNPJ',
    value: 'CNPJ',
  },
  {
    label: 'Telefone',
    value: 'PHONE',
  },
];
// current_payments_status: {
//   in_day: 'IN_DAY',
//   waiting: 'WAITING',
//   overdue: 'OVERDUE',
//   stop: 'STOP',
//   not_pay_first_installment: 'NOT_PAY_FIRST_INSTALLMENT',
//   paid_first_installment: 'PAID_FIRST_INSTALLMENT',
//   triggered_assistance: 'TRIGGERED_ASSISTANCE',
//   lead: 'LEAD',
// },
