import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Description } from './styles';

interface ItemProps {
  text: string;
  numberOfYearUtilizations: number;
}

const Item: React.FC<ItemProps> = ({ text, numberOfYearUtilizations }) => {
  return (
    <Description style={{ display: 'flex', justifyContent: 'center' }}>
      <FontAwesomeIcon
        icon={faCircleCheck}
        color="#00df1e"
        style={{ marginRight: '0.5rem', fontSize: '1.125rem' }}
      />
      {`${text}`}
    </Description>
  );
};

export default Item;
