import React, { useCallback, useRef, useState, useEffect } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { ToastContainer } from 'react-toastify';
import * as Yup from 'yup';

import { ImageDuplicateBillet } from '@assets/images';
import {
  ButtonMain,
  InputMask,
  HeaderNavigationPublic,
  FooterPublic,
  MenuSideBarPublic,
} from '@components/index';
import { ConfigValues } from '@config/index';
import Payment from '@models/Payment';
import { apiRebox } from '@services/index';
import { getValidationErrors } from '@utils/errors';
import { formatCPF } from '@utils/formatters';
import { toastify } from '@utils/notifiers';

import { schema } from './schemaValidation';

import {
  Container,
  Content,
  Information,
  InformationText,
  InformationTextTitle,
  InformationTextSubtitle,
} from './styles';

interface ISearchFormData {
  cpf: string;
}

interface IAsaasInvoice extends Payment {
  asaas_payment: any;
}

const ConsultDebts: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const handleOpenSidebar = useCallback(() => {
    setSidebarIsOpen(!sidebarIsOpen);
  }, [sidebarIsOpen]);

  const handleSubmitUser = useCallback(async (data: ISearchFormData) => {
    try {
      setLoading(prevState => !prevState);

      formRef.current?.setErrors({});

      await schema.validate(data, {
        abortEarly: false,
      });

      toastify('Por favor aguarde, estamos realizando a consulta.', 'info');

      const {
        data: { data: response_users },
      } = await apiRebox.get(`/users?cpf=${formatCPF.removeMask(data.cpf)}`);

      if (response_users.length > 0) {
        const [user] = response_users;

        const {
          data: { data: response_contracts },
        } = await apiRebox.get(`/contracts?users_id=${user.id}`);

        if (response_contracts.length > 0) {
          for await (const contract of response_contracts) {
            const {
              data: { data: response_payments },
            } = await apiRebox.get(
              `/payments?what_is_being_paid=contracts&paying_item_with_id=${contract.id}`,
            );

            const payments_updated: IAsaasInvoice[] = [];

            for await (const payment of response_payments) {
              if (
                payment.status === ConfigValues.rebox.payments.status.overdue ||
                payment.status === ConfigValues.rebox.payments.status.pending
              ) {
                const { data: asaas_payment } = await apiRebox.get(
                  `/asaas/payment/${payment.gateway_id}`,
                );

                payments_updated.push({
                  ...payment,
                  asaas_payment: asaas_payment.data,
                });
              }
            }

            // Capturando o link sempre da primeira fatura a vencer/vencida
            setInvoiceUrl(payments_updated[0]?.asaas_payment.invoiceUrl);
          }
        } else {
          toastify(
            'Este CPF não possui nenhum contrato efetuado, por favor tente mais tarde.',
            'success',
          );
        }
      } else {
        toastify(
          'Nenhum cliente encontrado com este CPF, por favor tente mais tarde.',
          'info',
        );
      }
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);

        formRef.current?.setErrors(errors);

        const { cpf } = errors;

        if (cpf) toastify(cpf, 'error');
      } else if (error.response) {
        toastify(
          error.response.data.error ||
            'Sinto muito, não foi possível localizar os dados',
          'error',
        );
      }
    } finally {
      setLoading(prevState => !prevState);
    }
  }, []);

  // Responsável por fazer o redirect para o link do boleto para o usuário
  useEffect(() => {
    if (invoiceUrl.length > 0) {
      window.location.href = invoiceUrl;
    }
  }, [invoiceUrl]);

  return (
    <Container sidebarIsOpen={sidebarIsOpen}>
      <MenuSideBarPublic
        handleOpenSidebar={handleOpenSidebar}
        sidebarIsOpen={sidebarIsOpen}
      />
      <HeaderNavigationPublic handleOpenSidebar={handleOpenSidebar} />
      <Content>
        <Information>
          <InformationText>
            <InformationTextTitle>
              Consulte seus pagamentos pendentes
            </InformationTextTitle>

            <InformationTextSubtitle>
              Informe abaixo o seu CPF e gere a 2ª via dos seus boletos.
            </InformationTextSubtitle>

            <Form ref={formRef} onSubmit={handleSubmitUser}>
              <InputMask
                name="cpf"
                mask="999.999.999-99"
                placeholder="Digite o seu CPF"
              />
              <ButtonMain loading={loading}>Buscar</ButtonMain>
            </Form>
          </InformationText>
          <ImageDuplicateBillet />
        </Information>
      </Content>
      <FooterPublic />
      <ToastContainer />
    </Container>
  );
};

export default ConsultDebts;
