import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Divider } from '../../components/newComponents';
import { useScroll } from '../../hooks/useScroll';
import {
  Body,
  BodyWrapper,
  Container,
  Desc,
  Header,
  InfoDesc,
  InfoImage,
  InfoTextWrapper,
  InfoTitle,
  InfoWrapper,
  Separator,
  SubTitle,
  Title,
} from './Styles';

interface InfoProps {
  title: string;
  desc: string;
  desc2?: string;
  imgUrl: string;
}

export const InfoItem: React.FC<InfoProps> = ({
  title,
  desc,
  desc2,
  imgUrl,
}) => {
  return (
    <InfoWrapper>
      <InfoImage src={imgUrl} loading="lazy" />
      <InfoTextWrapper>
        <InfoTitle>{title}</InfoTitle>
        <InfoDesc style={{ marginBottom: '1rem' }}>{desc}</InfoDesc>
        {desc2 && <InfoDesc>{desc2}</InfoDesc>}
      </InfoTextWrapper>
    </InfoWrapper>
  );
};

const MyDivider: React.FC = () => {
  const theme = useTheme();

  return (
    <div
      style={{
        textAlign: 'center',
        width: '280px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <FontAwesomeIcon
        color={theme.colors.white.main}
        icon={faCaretDown}
        style={{ marginRight: '10px' }}
        fontSize="20px"
      />
      <Separator />
    </div>
  );
};

const HowItWorks: React.FC = () => {
  const location = useLocation();
  useScroll(location);

  return (
    <Container id="comoFunciona">
      <Header>
        <Title>Como funciona?</Title>
        <MyDivider />
        <SubTitle>Não vendemos seguros para carros!</SubTitle>
        <Desc>
          Nossos planos dão direito a assistência veicular 24 horas em todo o
          Brasil!
        </Desc>
      </Header>
      <Body>
        <BodyWrapper>
          <InfoItem
            imgUrl="https://rebox.com.br/planos/wp-content/uploads/2021/01/escolha.svg"
            title="1) Escolha a categoria do seu veículo"
            desc="Com nosso plano, você poderá solicitar 04 (quatro) assistências 24h por ano. Elas podem ser do mesmo serviço ou variadas."
            desc2="Temos planos para carros de passeio, motos, SUVs, picapes pequenas com atendimento em todo o Brasil com a maior e mais qualificada rede de prestadores."
          />
          <InfoItem
            imgUrl="https://rebox.com.br/planos/wp-content/uploads/2021/01/cadastro.svg"
            title="2) Complete seu cadastro"
            desc="SEM BUROCRACIA e SEM PEGADINHAS, você contrata seu plano em apenas 2 minutos."
          />
          <InfoItem
            imgUrl="https://rebox.com.br/planos/wp-content/uploads/2021/01/forma-de-pagamento.svg"
            title="3) Escolha a forma de pagamento"
            desc="Você pode pagar através do pix, cartão de crédito ou boleto, em forma de assinatura mensal. Não comprometemos o limite de seu cartão, só cobramos o valor de sua mensalidade. Já o boleto ou pix, enviamos todo mês para seu e-mail, na data escolhida para pagamento."
          />
          <InfoItem
            imgUrl="https://rebox.com.br/planos/wp-content/uploads/2021/01/relogio.svg"
            title="4) Carência para usar o serviço"
            desc="Após a confirmação do pagamento da primeira mensalidade, você aguardará 30 (trinta) dias de carência para começar a usar os serviços de assistência 24h."
          />
          <InfoItem
            imgUrl="https://rebox.com.br/planos/wp-content/uploads/2021/01/ligacao-de-emergencia.svg"
            title="5) E pronto! Agora é só usar."
            desc="Após a carência, você poderá solicitar os serviços quando precisar, através de um dos nossos canais: whatsapp, aplicativo ou site."
          />
        </BodyWrapper>
        <br />
      </Body>
      <Divider />
    </Container>
  );
};

export default HowItWorks;
