import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

import { Divider, Navbar } from '../../components/newComponents';
import {
  Button,
  Container,
  InnerWrapper,
  SubTitle,
  Title,
  IconWrapper,
  Wrapper,
} from './Styles';

const Header: React.FC = () => {
  const { push } = useHistory();

  const scrollToSection = (sectionId: string): void => {
    const targetUrl = `${window.location.origin}${window.location.pathname}#${sectionId}`;

    if (window.location.href === targetUrl) {
      // Se já estiver na rota com o hash, apenas rola até a seção
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // Se estiver em outra rota, navega para a rota com o hash
      window.location.href = targetUrl;
    }
  };

  return (
    <Wrapper>
      <Navbar />
      <InnerWrapper>
        <div />
        <div style={{ marginBottom: '4rem' }}>
          <Container>
            <Title>Faça como Stênio Garcia</Title>
            <SubTitle>Contrate um plano da Rebox e fique tranquilo!</SubTitle>
            <Button onClick={(): void => scrollToSection('planos')}>
              Conheça agora
            </Button>
          </Container>
          <IconWrapper>
            <FontAwesomeIcon
              onClick={(): void => scrollToSection('quemSomos')}
              icon={faArrowDown}
              color="white"
              fontSize="32px"
            />
          </IconWrapper>
        </div>
      </InnerWrapper>
      <Divider />
    </Wrapper>
  );
};

export default Header;
