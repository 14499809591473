import { useState } from 'react';

import {
  faAngleRight,
  faCaretUp,
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';
import { useSpring } from 'react-spring';
import useMeasure from 'react-use-measure';
import { useTheme } from 'styled-components';

import Manual from '../../assets/Manual do Cliente Rebox.pdf';
import {
  GeneralCondition,
  PrivacyPolicy,
  UsageRules,
} from '../../components/newComponents';
import questions from '../../constants/faqQuestions.json';
import { useScroll } from '../../hooks/useScroll';
import {
  CollapsedDiv,
  Container,
  FaqWrapper,
  Header,
  Options,
  OptionText,
  OptionWrapper,
  Question,
  QuestionDesc,
  QuestionDescWrapper,
  QuestionTitle,
  QuestionTitleWrapper,
  Separator,
  Title,
} from './Styles';

interface OptionProps {
  text: string;
  handleClick: () => void;
}

const MyDivider: React.FC = () => {
  const theme = useTheme();

  return (
    <div
      style={{
        textAlign: 'center',
        width: '228px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <FontAwesomeIcon
        color={theme.colors.blue.main}
        icon={faCaretUp}
        style={{ marginRight: '10px' }}
        fontSize="20px"
      />
      <Separator />
    </div>
  );
};

const Option: React.FC<OptionProps> = ({ text, handleClick }) => {
  const theme = useTheme();

  return (
    <>
      <OptionWrapper
        onClick={(): void => {
          handleClick();
        }}
      >
        <FontAwesomeIcon
          className="icon"
          icon={faAngleRight}
          color={theme.colors.gray.opacity.average}
        />

        <OptionText>
          {text === 'Manual do Cliente' ? (
            <Link to={Manual} target="_blank">
              {text}
            </Link>
          ) : (
            text
          )}
        </OptionText>
      </OptionWrapper>
    </>
  );
};

const QuestionItem: React.FC<{ type: string; title: string; desc: string }> = ({
  type,
  title,
  desc,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [ref, bounds] = useMeasure();

  const collapseAnimation = useSpring({
    height: open ? bounds.height : 0,
  });

  return type === 'faq' ? (
    <CollapsedDiv style={{ maxWidth: '510px' }}>
      <Question open={open} onClick={(): void => setOpen(prev => !prev)}>
        <QuestionTitleWrapper>
          <QuestionTitle open={open}>{title}</QuestionTitle>
          <FontAwesomeIcon icon={!open ? faPlus : faMinus} />
        </QuestionTitleWrapper>
        <QuestionDescWrapper style={collapseAnimation}>
          <QuestionDesc ref={ref} dangerouslySetInnerHTML={{ __html: desc }} />
        </QuestionDescWrapper>
      </Question>
    </CollapsedDiv>
  ) : type === 'privacyPolicy' ? (
    <CollapsedDiv />
  ) : (
    <CollapsedDiv />
  );
};

const Faq: React.FC = () => {
  const [idx, setIdx] = useState<number>(-1);
  const options = [
    {
      key: 'id-1',
      component: (
        <Option
          text="Perguntas Frequentes"
          handleClick={(): void => setIdx(idx === 0 ? -1 : 0)}
        />
      ),
    },
    {
      key: 'id-2',
      component: (
        <Option
          text="Política de Privacidade"
          handleClick={(): void => setIdx(idx === 1 ? -1 : 1)}
        />
      ),
    },
    {
      key: 'id-3',
      component: <Option text="Manual do Cliente" handleClick={() => []} />,
    },
    // {
    //   key: 'id-4',
    //   component: <Option text="Regras de Uso" handleClick={(): void => setIdx(3)} />,
    // },
  ];
  const location = useLocation();
  useScroll(location);

  return (
    <Container id="faq">
      <Header>
        <Title>Dúvidas Frequentes</Title>
        <MyDivider />
      </Header>
      <Options>
        {options.map((option, i) => (
          <div key={option.key} className={idx === i ? 'active' : ''}>
            {option.component}
          </div>
        ))}
      </Options>
      {idx === 0 ? (
        <FaqWrapper>
          {questions.map(question => (
            <QuestionItem
              type="faq"
              key={question.id}
              title={question.title}
              desc={question.desc}
            />
          ))}
        </FaqWrapper>
      ) : idx === 1 ? (
        <CollapsedDiv>
          <PrivacyPolicy />
        </CollapsedDiv>
      ) : idx === 2 ? (
        <CollapsedDiv>
          <a href={Manual}>-</a>
        </CollapsedDiv>
      ) : // ) : (
      //   idx === 3 && (
      //     <CollapsedDiv>
      //       <UsageRules />
      //     </CollapsedDiv>
      //   )
      null}
    </Container>
  );
};

export default Faq;
