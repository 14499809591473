import { useCallback, useState } from 'react';

import {
  FooterPublic,
  FormCalledNew,
  HeaderNavigationPublic,
} from '@components/index';

import { Container } from './styles';

const Assistance: React.FC = () => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const handleOpenSidebar = useCallback(() => {
    setSidebarIsOpen(!sidebarIsOpen);
  }, [sidebarIsOpen]);

  return (
    <Container>
      <HeaderNavigationPublic handleOpenSidebar={handleOpenSidebar} />
      <FormCalledNew />
      <FooterPublic />
    </Container>
  );
};

export default Assistance;
