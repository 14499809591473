// ./src/pages/privates/Contract/New/StepVehicle/index.tsx
import React, { useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { IoCart, IoClose } from 'react-icons/io5';
import * as Yup from 'yup';

import {
  SubtitleSecondary,
  Paragraph,
  ButtonMain,
  ButtonDefault,
  InputMask,
  AlertSimpleCustom,
} from '@components/index';
import { ConfigStyles, ConfigTransition } from '@config/index';
import Product from '@models/Product';
import Vehicle from '@models/Vehicle';
import { newContractStorageService, apiRebox } from '@services/index';
import { getValidationErrors } from '@utils/errors';
import { formatMoney, formatText } from '@utils/formatters';
import { hotToast, toastify } from '@utils/notifiers';

import FormVehicleNew from './FormVehicleNew';

import {
  Container,
  Tabs,
  TabLabels,
  TabLabelsButton,
  TabLabelsDivisor,
  TabItems,
  TabItemsGroup,
  FormValidate,
  Resume,
  SectionsGroup,
  SectionsField,
  DividingLine,
  Buttons,
  SectionsProductTitle,
  SectionsProduct,
  SectionsProductGroup,
  SectionsProductItem,
} from './styles';

interface IProps {
  myStep: number;
  currentStep: number;
  changeStep(newStep: number, willChangeHistory?: boolean): void;
  calculatedPriceForFlex: number;
  calculatedPriceForTracker: number;
  handleCalculatePrice: (e: any) => void;
}

interface IProductCart extends Product {
  cart_id?: number;
}

const StepVehicle: React.FC<IProps> = ({
  myStep,
  currentStep,
  changeStep,
  calculatedPriceForFlex,
  calculatedPriceForTracker,
  handleCalculatePrice,
}) => {
  const formRef = useRef<FormHandles>(null);

  const [product, setProduct] = useState<Product[]>(
    newContractStorageService.getCart(),
  );

  const [vehicle, setVehicle] = useState<Vehicle | null>(null);
  const [tab, setTab] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<string>('');
  const [environment, setEnvironment] = useState<any>(
    sessionStorage.getItem('@Rebox:User:environment'),
  );

  return (
    <Container>
      <SectionsProduct>
        <SectionsProductTitle>
          <IoCart
            size={24}
            title="ícone de carrinho de compra"
            color={ConfigStyles.rebox.colors.blue.main}
          />
          <SubtitleSecondary textAlign="start">Seu carrinho</SubtitleSecondary>
        </SectionsProductTitle>
        <SectionsProductGroup>
          {product?.map((productCart: IProductCart, index: number) => (
            <div
              key={productCart.id}
              style={{
                display: 'flex',
                gap: '1rem',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '90%',
                }}
              >
                <SectionsProductItem>
                  <Paragraph
                    nameColor="black"
                    fontSize={ConfigStyles.rebox.fonts.size.paragraph.big}
                    fontWeight={600}
                    textAlign="start"
                  >
                    {`${productCart.name}`.toUpperCase()}
                  </Paragraph>
                  {(calculatedPriceForFlex || calculatedPriceForTracker) > 0 &&
                    (environment.toLowerCase() === 'marketing' &&
                    productCart.name.toLowerCase().includes('tracker') ? (
                      <Paragraph
                        nameColor="black"
                        fontSize={ConfigStyles.rebox.fonts.size.paragraph.big}
                        fontWeight={500}
                        textAlign="end"
                      >
                        {formatMoney.fromNumberToPrice(
                          calculatedPriceForTracker ||
                            productCart.promotional_price - 5 ||
                            productCart.current_price - 5 ||
                            0 - 5,
                        )}{' '}
                        / mês
                      </Paragraph>
                    ) : (
                      <Paragraph
                        nameColor="black"
                        fontSize={ConfigStyles.rebox.fonts.size.paragraph.big}
                        fontWeight={500}
                        textAlign="end"
                      >
                        {formatMoney.fromNumberToPrice(
                          calculatedPriceForFlex ||
                            productCart.promotional_price ||
                            productCart.current_price ||
                            0,
                        )}{' '}
                        / mês
                      </Paragraph>
                    ))}
                </SectionsProductItem>
                <Paragraph
                  nameColor="black"
                  fontSize={ConfigStyles.rebox.fonts.size.paragraph.big}
                  fontWeight={400}
                  textAlign="start"
                >
                  {productCart.description
                    ? formatText.capitalizedFirstLetter(productCart.description)
                    : `Direito a ${productCart.available_uses} utilizações durante um ano.`}
                </Paragraph>
              </div>
              {product?.length > 1 && (
                <ButtonDefault
                  iconLeft={IoClose}
                  iconLeftColor="black"
                  style={{
                    backgroundColor: ConfigStyles.rebox.colors.red.main,
                    width: '5%',
                    padding: 0,
                  }}
                  onClick={() => {
                    const cart = newContractStorageService.getCart();
                    const updatedCart = cart.filter(
                      (_, cartIndex) => cartIndex !== index,
                    );
                    setProduct(updatedCart);
                    newContractStorageService.removeProduct(index);
                    newContractStorageService.removeCart(index);
                    newContractStorageService.removeFormVehicle(index);
                    newContractStorageService.removeBrandFormVehicle(index);
                  }}
                ></ButtonDefault>
              )}
            </div>
          ))}
        </SectionsProductGroup>
      </SectionsProduct>
      <SubtitleSecondary textAlign="start">Dados do veículo</SubtitleSecondary>
      <Paragraph
        textAlign="start"
        nameColor="black"
        opacity={0.8}
        fontWeight={500}
      >
        Agora nos diga. Vamos cuidar juntos de qual veículo?
      </Paragraph>

      <FormVehicleNew
        forNewSale={{
          advanceStep: () => {
            changeStep(currentStep + 1);
            setTab(1);
          },
        }}
        handleCalculatePrice={handleCalculatePrice}
        setProduct={setProduct}
      />

      <Buttons>
        <ButtonDefault
          onClick={() => changeStep(currentStep - 1)}
          style={{ maxWidth: 200 }}
          disabled={currentStep <= 1}
          isDisable={currentStep <= 1}
        >
          Voltar
        </ButtonDefault>
      </Buttons>
    </Container>
  );
};

export default StepVehicle;
