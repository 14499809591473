import { useState } from 'react';

import { Height } from '@mui/icons-material';
import { FaLock } from 'react-icons/fa';

import ButtonMain from '@components/buttons/ButtonMain';
import NavbarModal from '@components/newComponents/navbarModal/navbarModal';
import { ConfigRoutes, ConfigStyles, ConfigValues } from '@config/index';

import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { Container, LockPosition, TextLink, Ul } from './Styles';

interface Props {
  open: boolean;
  onClick: () => void;
}

const Sidebar: React.FC<Props> = ({ open, onClick }) => {
  const { width } = useWindowDimensions();

  const handleClick = (href?: string): void => {
    const targetUrl = `${window.location.origin}${window.location.pathname}${href}`;

    if (window.location.href === targetUrl) {
      // Se já estiver na rota com o hash, apenas rola até a seção
      if (href) {
        const sectionId = href.split('#')[1];
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      }
    } else {
      // Navega para a URL com o hash
      window.location.href = targetUrl;
    }
    if (width <= 980) {
      onClick();
    }
  };

  const [showNavbarModal, setShowNavbarModal] = useState<boolean>(false);

  const handleShowNavbarModal = (): void => {
    setShowNavbarModal(true);
  };

  const handleCloseNavbarModal = (): void => {
    setShowNavbarModal(false);
  };

  return (
    <Container>
      <Ul open={open}>
        <TextLink
          href="/#quemSomos"
          open={open}
          onClick={(e: any) => {
            e.preventDefault();
            handleClick('#quemSomos');
          }}
        >
          Quem somos
        </TextLink>
        <TextLink
          href="/#comoFunciona"
          open={open}
          onClick={(e: any) => {
            e.preventDefault();
            handleClick('#comoFunciona');
          }}
        >
          Como funciona
        </TextLink>
        <TextLink
          href="/#planos"
          open={open}
          onClick={(e: any) => {
            e.preventDefault();
            handleClick('#planos');
          }}
        >
          Planos
        </TextLink>
        <TextLink
          href="/#faq"
          open={open}
          onClick={(e: any) => {
            e.preventDefault();
            handleClick('#faq');
          }}
        >
          Dúvidas frequentes
        </TextLink>
        <TextLink
          href="https://rebox.com.br/boleto"
          open={open}
          onClick={() => handleClick}
        >
          Boleto
        </TextLink>
        <TextLink
          href={ConfigRoutes.rebox.publics.assistance.path}
          open={open}
          onClick={() => handleClick}
          color={ConfigStyles.rebox.colors.red.main}
        >
          Pedir Socorro
        </TextLink>
        <TextLink
          href="https://cliente.rebox.com.br/"
          open={open}
          onClick={() => handleClick}
        >
          Área do cliente
        </TextLink>
      </Ul>
      <LockPosition open={open}>
        <FaLock size={32} color="red" onClick={handleShowNavbarModal}>
          Bloqueio
        </FaLock>
      </LockPosition>
      <NavbarModal show={showNavbarModal} onClose={handleCloseNavbarModal} />
    </Container>
  );
};

export default Sidebar;
