import React from 'react';

import { IoArrowBack } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';

import {
  ButtonDefault,
  FormNotification,
  HeaderNavigationPrivate,
  MenuSideBarPrivate,
  SubtitleSecondary,
} from '@components/index';

import { Options, OptionsGroup } from '../Communication/styles';
import { Container, ContainerGroup, Content } from '../styles';

const Notifications: React.FC = () => {
  const { goBack } = useHistory();

  const handleGoBack = () => {
    goBack();
  };

  return (
    <Container>
      <HeaderNavigationPrivate />
      <ContainerGroup>
        <MenuSideBarPrivate />
        <Content>
          <SubtitleSecondary textAlign="start">Notificação</SubtitleSecondary>
          <Options>
            <OptionsGroup>
              <ButtonDefault iconLeft={IoArrowBack} onClick={handleGoBack} />
            </OptionsGroup>
          </Options>
          <FormNotification />
        </Content>
      </ContainerGroup>
    </Container>
  );
};

export default Notifications;
